import { CHAT_LOADING, GET_CHAT_MESSAGES, INSERT_FILES, INSERT_MESSAGE, UPDATE_CHAT_ROOM_ID } from 'constants/store-actions';
import { Dispatch } from 'redux';
import * as api from 'api';

export const getChatMessages = (data: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: CHAT_LOADING })
        const { data: { response: { ChatList } } } = await api.getChatMessages(data);
        dispatch({ type: GET_CHAT_MESSAGES, payload: ChatList })
    } catch (error) {
        console.log(error);
    }
};

export const sendMessage = (data: any) => async (dispatch: Dispatch) => {
    try {
        const { data: { response } } = await api.sendMessage(data);
        dispatch({ type: INSERT_MESSAGE, payload: response[0] });
        if (response && response.length && data.ChatRoomId) dispatch({ type: UPDATE_CHAT_ROOM_ID, payload: response[0] });
    } catch (error) {
        console.log(error);
    }
};

export const sendFile = (data: any) => async (dispatch: Dispatch) => {
    try {
        const { data: { response } } = await api.sendFile(data);
        dispatch({ type: INSERT_FILES, payload: response.ChatList })
        // if (response && response.length && data.ChatRoomId) dispatch({ type: UPDATE_CHAT_ROOM_ID, payload: response[0] });
    } catch (error) {
        console.log(error);
    }
};