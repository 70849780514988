import BasicCallerTune from "assets/callSound/BasicCallerTune.mp3";
import {
  ComponentRightSide,
  ConnectSignalREnums,
} from "constants/common-constants";
import useVideoContext from "hooks/useVideoContext/useVideoContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { SignalRContext } from "services/signal-r-service";

const CallPopup = ({
  name,
  calling,
  setHandleComponentRightSide,
  setCallingThirdPerson,
}: {
  name?: string;
  calling?: boolean;
  setHandleComponentRightSide?: any;
  setCallingThirdPerson?: any;
}) => {
  const connection = useContext(SignalRContext);
  const navigate = useNavigate();
  const { room } = useVideoContext();

  const handleRoomDisconnect = () => {
    const Profile = JSON.parse(localStorage.getItem("RoomData") || "");
    const SingleUser = {
      UserInCall: Profile.UserInCall,
      dataType: ConnectSignalREnums.CallEnd,
    };
    if (connection) {
      connection.send("DataInSignalR", JSON.stringify(SingleUser));
    }
    setHandleComponentRightSide(ComponentRightSide.RightSide);
    if (localStorage.getItem("guest")) {
      const rememberme = JSON.parse(
        localStorage.getItem("rememberme") || JSON.stringify("")
      );
      if(rememberme){
        const keyToKeep = 'password';
        const keyToKeep2 = 'rememberme';
        for (let key in localStorage) {
          if (localStorage.hasOwnProperty(key) && key !== keyToKeep && key !== keyToKeep2 ) {
            localStorage.removeItem(key);
          }
        }
      }
      else{
        localStorage.clear()
      }
    }
   
    setCallingThirdPerson(false);
    toast("Call Ended");
  };

  return (
    <>
      <div
        style={{
          position: "fixed",
          inset: 0,
          width: "100vw",
          height: "100vh",
          background: "rgba(0,0,0,0.5",
          zIndex: 9999999,
          display: "grid",
          placeItems: "center",
        }}
      >
        <p
          style={{
            width: "350px",
            fontSize: "20px",
            fontWeight: "bold",
            background: "white",
            textAlign: "center",
            padding: "20px 10px",
            borderRadius: 10,
          }}
        >
          {!calling ? `${name} declined the video call.` : "Connecting..."}

          <button
            className="btn btn-theme"
            onClick={handleRoomDisconnect}
            style={{
              background: "red",
              marginLeft: "70px",
              padding: "2px 5px 2px 5px",
            }}
          >
            End Call <i className="fa fa-sign-out"></i>
          </button>
        </p>
      </div>
      {calling ? (
        <audio autoPlay loop className="audio-element">
          <source src={BasicCallerTune}></source>
        </audio>
      ) : null}
    </>
  );
};

export default CallPopup;
