import React, { useCallback, useRef } from 'react';
import VideoOffIcon from 'icons/VideoOffIcon';
import VideoOnIcon from 'icons/VideoOnIcon';
import VideoCallRoundedIcon from '@mui/icons-material/VideoCallRounded';
import DisabledByDefaultRoundedIcon from '@mui/icons-material/DisabledByDefaultRounded';
import useDevices from 'hooks/useDevices/useDevices';
import useLocalVideoToggle from 'hooks/useLocalVideoToggle/useLocalVideoToggle';
import PlayDisabledRoundedIcon from '@mui/icons-material/PlayDisabledRounded';

export default function ToggleVideoButton(props: { disabled?: boolean; className?: string }) {
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const lastClickTimeRef = useRef(0);
  const { hasVideoInputDevices } = useDevices();

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now();
      toggleVideoEnabled();
    }
  }, [toggleVideoEnabled]);

  return (
    <div style={{ marginRight: '30px', textAlign: 'center' }}>
      <button
        // className={props.className}
        style={{ backgroundColor: !hasVideoInputDevices ? '#dbb702' : isVideoEnabled ? 'red' : '#447334', color: 'white', border: 'none', borderRadius: '5px', marginLeft: '10px' }}
        onClick={toggleVideo}
        disabled={!hasVideoInputDevices || props.disabled}
      // startIcon={isVideoEnabled ? <VideoOnIcon /> : <VideoOffIcon />}
      >
        {!hasVideoInputDevices ? <PlayDisabledRoundedIcon /> : isVideoEnabled ? <DisabledByDefaultRoundedIcon /> : <VideoCallRoundedIcon />}
      </button>
      <span style={{ display: 'block' }}>{!hasVideoInputDevices ? 'No Video' : isVideoEnabled ? 'Stop Video' : 'Start Video'}</span>
    </div>
  );
}
