import React from 'react';
import ParticipantTracks from '../ParticipantTracks/ParticipantTracks';
import useMainParticipant from 'hooks/useMainParticipant/useMainParticipant';
import useSelectedParticipant from '../VideoProvider/useSelectedParticipant/useSelectedParticipant';
import useScreenShareParticipant from 'hooks/useScreenShareParticipant/useScreenShareParticipant';
import useVideoContext from 'hooks/useVideoContext/useVideoContext';


export default function MainParticipant() {
  const mainParticipant = useMainParticipant();
  const { room } = useVideoContext();
  const localParticipant = room!.localParticipant;
  const [selectedParticipant] = useSelectedParticipant();
  const screenShareParticipant = useScreenShareParticipant();

  const videoPriority =
  (mainParticipant === selectedParticipant || mainParticipant === screenShareParticipant) &&
  mainParticipant !== localParticipant ? 'high'
  : null;
    //const videoPriority = (mainParticipant === selectedParticipant || mainParticipant === screenShareParticipant)
      // ? 'high'
      // : null;


  return (
    /* audio is disabled for this participant component because this participant's audio 
       is already being rendered in the <ParticipantStrip /> component.  */
    <>
        <ParticipantTracks
          participant={mainParticipant}
          videoOnly
          // enableScreenShare={true}
          enableScreenShare={mainParticipant !== localParticipant}
          videoPriority={videoPriority}
          // isLocalParticipant={false}
          isLocalParticipant={mainParticipant === localParticipant}
        />
         <div
          style={{
          height: "40px",
          width: "100%",
          background: "grey",
          }}
          className="d-flex align-items-center justify-content-center"
          >
          {mainParticipant.identity}: Share Screen
          </div>
    </>

  );
}
