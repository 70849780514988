import * as api from "api";
import { ADD_GUEST_USER, GET_INSERT_PUBLIC_MEETING_USERS, REMOVE_All_PARTICIPANTS,INSERT_PUBLIC_MEETING_USERS,GET_MEETTING_URL,CHECK_MEETTING_URL, REMOVE_GUEST_USER,GET_INSERT_ROOM_MEETING_USERS,GET_All_PARTICIPANTS } from 'constants/store-actions';
import { Dispatch } from 'redux';

export const addGuestUser = (data: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: ADD_GUEST_USER, payload: data })
    } catch (error) {
        console.log(error);
    }
};

export const GetPublicMeetingUsers = () => async (dispatch: Dispatch) => {
    try {
      const data = await api.GetPublicMeetingUsers();
      dispatch({ type: GET_INSERT_PUBLIC_MEETING_USERS, payload: data.data.response });
    } catch (error) {
      console.log(error);
    }
  };

export const InsertPublicMeetingUsers = (formData:any) => async (dispatch:Dispatch) => {
    try{
        const {data:result} = await api.InsertPublicMeetingUsers({PublicGuestId: localStorage.getItem('guest-list-id')})
        // dispatch({
        //   type: INSERT_PUBLIC_MEETING_USERS,
        //   payload: result.response,
        // });
    }catch (error) {
        console.log(error);
      }
}

export const DeletePublicMeetingUsers = (formData: any) => async (dispatch: Dispatch) => {
    try {
        const { data: result } = await api.DeletePublicMeetingUsers({...formData,});
    } catch (error) {
        console.log(error)
    }
}
export const DeletePublicMeetingForAllUsers = (formData: any) => async (dispatch: Dispatch) => {
    try {
        const { data: result } = await api.DeletePublicMeetingForAllUsers({...formData,});
    } catch (error) {
        console.log(error)
    }
}

export const removeGuestUser = (data: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: REMOVE_GUEST_USER, payload: data })
    } catch (error) {
        console.log(error);
    }
};

/*-----room-user-------------*/


export const GetRooms = () => async (dispatch: Dispatch) => {
    try {
      const data = await api.GetRooms();
      dispatch({ type: GET_INSERT_ROOM_MEETING_USERS, payload: data.data });
    } catch (error) {
      console.log(error);
    }
  };

  /*-----below actions added by vidushi ----*/
  export const AllUsers = (data: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: GET_All_PARTICIPANTS, payload: data })
    } catch (error) {
        console.log(error);
    }
};

export const RemoveAllParticipants= () => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: REMOVE_All_PARTICIPANTS })
    } catch (error) {
        console.log(error);
    }
};

export const SendInvitation = (formData: any) => async (dispatch: Dispatch) => {
    try {
        const { data: result } = await api.sendMeetingInvitation(formData);
        dispatch({ type: GET_MEETTING_URL, payload: result.response.url })
    } catch (error) {
        console.log(error)
    }
}

export const CheckMeetingValid = (formData: any) => async (dispatch: Dispatch) => {
    try {
        const { data: result } = await api.CheckMeetingValid(formData);
        dispatch({ type: CHECK_MEETTING_URL, payload: result.response.url })
    } catch (error) {
        console.log(error)
    }
}