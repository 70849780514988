import { ADD_GUEST_USER, GET_INSERT_PUBLIC_MEETING_USERS, INSERT_PUBLIC_MEETING_USERS,GET_MEETTING_URL,CHECK_MEETTING_URL, REMOVE_GUEST_USER,GET_INSERT_ROOM_MEETING_USERS,GET_All_PARTICIPANTS,REMOVE_All_PARTICIPANTS } from "constants/store-actions";

const initialState = {
  guestUser: [],
  publicOngoingCallUser:[],
  allPublicOngoingCallUser:[],
  allmemberInRoom:[],
  meettingurl:{},
  validMeetingurl:''
};

const guestUserReducer = (
  state = initialState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case ADD_GUEST_USER:
      return { ...state, guestUser: [...state.guestUser, action.payload] };

    case REMOVE_GUEST_USER:
      const user = action.payload;
      const newdata = state.guestUser.filter(
        (item: any) => item?.name !== user.name
      );
      // console.log("I am In Reducer", newdata);
      return { ...state, guestUser: newdata };

    case INSERT_PUBLIC_MEETING_USERS:
      // console.log("I am In Public user");
      return {
        ...state,
        publicOngoingCallUser: action.payload
      };
      case GET_INSERT_PUBLIC_MEETING_USERS : 
      return {
        ...state,
        allPublicOngoingCallUser : action.payload
      }
      case GET_All_PARTICIPANTS : 
      return {
        ...state,
        allmemberInRoom : action.payload
      }
      case GET_MEETTING_URL : 
      return {
        ...state,
        meettingurl : action.payload
      }
      case CHECK_MEETTING_URL : 
      return {
        ...state,
        validMeetingurl : action.payload
      }
      case REMOVE_All_PARTICIPANTS : 
      return {
        ...state,
        allmemberInRoom :[]
      }
    default:
      return state;
  }
};

export default guestUserReducer;
