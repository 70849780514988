/*--Raise Hand added by Vidushi----*/
import React, { useContext, useState } from "react";
import BackHandIcon from '@mui/icons-material/BackHand';

export default function RaiseHand({ handleRaiseHand , handleDownHand }: any) {
  const [isRaised, setIsRaised] = useState(false);

  const toggleRaiseHand = () => {
    setIsRaised((prevState) => !prevState);
  // Pass the updated value to the parent component
  };
  const handleRaiseHandAction = () => {
    if (isRaised) {
      handleDownHand();
   // Trigger the action only when isRaised is true
    }
    else{
      handleRaiseHand();
    }
  };

  return (
    <div title="Raise Hand">
      <div style={{ marginRight: '30px', textAlign: 'center' }}>
        <button
          style={{
            backgroundColor: isRaised ?  "red" :"green" ,
            color: "white",
            border: "none",
            borderRadius: "5px",
          }}
          onClick={() => {
            toggleRaiseHand();
            handleRaiseHandAction();
          }}        
        >
          <BackHandIcon />
        </button>
        <span style={{ display: 'block', marginLeft: '7px' }}>
          {isRaised ?  "Down Hand": "Hand Raised" }
        </span>
      </div>
    </div>
  );
}
