import React, { useEffect, useState } from "react";
import IncomingCallTune from "assets/callSound/IncomingCall.mp3";
import Silence from "assets/callSound/silence.mp3";
import useAudio from "hooks/useAudio/useAudio";
import VideocamRoundedIcon from "@mui/icons-material/VideocamRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { chownSync } from "fs";

const CallNotification = ({
  name,
  startVideoCall,
}: {
  name: string;
  startVideoCall: (value: boolean) => void;
}) => {
    const [user, setUser] = useState("")
 
  useEffect(() => {
    const roomData:any = JSON.parse(
        localStorage.getItem("RoomData") ?? JSON.stringify("")
      );
      setUser(roomData.Name)
  }, []);
  return (
    <>
      <div className="incoming-video-call" style={{ zIndex: 100000 }}>
        <div className="incoming-call-info">
          <div className="incoming-call-photo">{user.split(" ").map((n:any)=>n[0]).join("").toUpperCase()}</div>
          <div className="incoming-call-title">
            <div className="incoming-call-name">{user}</div>
            <div className="incoming-call-alert">Incoming call...</div>
          </div>
        </div>
        <div className="incoming-call-icons">
          <button
            onClick={() => startVideoCall(true)}
            className="incoming-call-accept"
          >
            <VideocamRoundedIcon />
          </button>
          <button
            onClick={() => startVideoCall(false)}
            className="incoming-call-decline"
          >
            <ClearRoundedIcon />
          </button>
        </div>
        <audio autoPlay loop className="audio-element">
          <source src={IncomingCallTune}></source>
        </audio>
      </div>
    </>
  );
};

export default CallNotification;
