import coaching from "assets/images/executive-coaching.jpg"

export const Help = () => {
    return (
        <div>
            <div className="page-area">
                <div className="container-fluid">
                    <div className="page-section">
                        <div className="title-top">
                            <div className="page-title">
                                <strong>Help</strong>
                            </div>
                        </div>
                        <div className="content-help">
                            <div className="content-help-text">If you need any help please contact:</div>
                            <div className="content-help-title">Mr. James Pfautz</div>
                            <div className="content-help-text">202.2750866</div>
                            <div className="content-help-link"><a href="">jpfautz@thesmallworldgroup.com</a></div>
                        </div>

                        <div className="help-page">
                            <div className="search-right-side">
                                <div id="accordion" className="data-list-group">


                                    <div className="card data-card-yellow">
                                        <div className="card-header" id="headingTwo">
                                            <div className="data-title collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">Click here to open Coaching</div>
                                        </div>
                                        <div id="collapseTwo" className="collapse-data collapse show" aria-labelledby="headingTwo" data-parent="#accordion">

                                            <div className="my-coach-content">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                        <div className="my-coach-group">
                                                            <div className="my-coach-title">What is Coaching:</div>
                                                            <div className="my-coach-desc">To understand what an executive coach is, we first must take a look at the definition of coaching. According to the International Coach Federation (ICF), coaching is defined as a partnership with clients in a thought-provoking and creative process that inspires them to maximize their personal and professional potential. Coaching is a client-driven process that’s distinct from other service professions, such as counseling, mentoring, consulting and training.</div>
                                                        </div>
                                                        <div className="my-coach-group">
                                                            <div className="my-coach-title">What Is an Executive Coach?</div>
                                                            <div className="my-coach-desc">Coaching is probably one of the most-if not the most-individually tailored practices in talent development as it involves a close and confidential relationship between the coach and the person being coached. Meeting one-on-one with senior managers or leaders within an organization (such as a director, vice president, president, or member of the C-suite), the executive coach provides a safe, structured, and trustworthy environment in which to offer support for the individual.</div>
                                                        </div>
                                                        <div className="my-coach-group">
                                                            <div className="my-coach-title">How a Coach Helps</div>
                                                            <div className="my-coach-desc">
                                                                The coach helps the leader understand their current competencies, see how they’re perceived by others, and focus on identifying and clarifying current goals as well as the appropriate action steps to reach those goals.<br /><br />
                                                                <b>
                                                                    Please contact James Pfautz ASAP so we can get coaching today!<br />
                                                                    <a href="javascript:void(0)">jpfautz@thesmallworldgroup.com</a>
                                                                </b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                        <div className="my-coach-group my-coach-group-img">
                                                            <div className="my-coach-img"><img src={coaching} /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
