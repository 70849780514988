import React, { useContext, useEffect, useRef, useState } from "react";
import { GalleryView } from "../GalleryView/GalleryView";
import { MobileGalleryView } from "../MobileGalleryView/MobileGalleryView";
import MainParticipant from "../MainParticipant/MainParticipant";
import {
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
  styled,
} from "@material-ui/core";
import { Participant, Room as IRoom } from "twilio-video";
import { ParticipantAudioTracks } from "../ParticipantAudioTracks/ParticipantAudioTracks";
import ParticipantList from "../ParticipantList/ParticipantList";
import { useAppState } from "state";
import useScreenShareParticipant from "hooks/useScreenShareParticipant/useScreenShareParticipant";
import useVideoContext from "hooks/useVideoContext/useVideoContext";
import useRoomState from "hooks/useRoomState/useRoomState";
import useHeight from "hooks/useHeight/useHeight";
import PreJoinScreens from "../PreJoinScreens/PreJoinScreens";
import IncomingCallList from "components/Dashboard/PopUps/IncomingCallList";
import GuestChatBox from "components/GuestMeeting/GuestChatBox";
import { SignalRContext, getConnection } from "services/signal-r-service";
import { SignalREventsEnums } from "constants/signalR-events";
import { INSERT_GUEST_CHAT_MESSAGES } from "constants/store-actions";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import ChatBox from "components/Dashboard/ChatBox/ChatBox";
import { DeletePublicMeetingUsers, DeletePublicMeetingForAllUsers,InsertPublicMeetingUsers, RemoveUsersInCall,DeleteOnlineUser, UpdateOnlineUsers,GetOnlineUsers,GetUsersInCall, GetPublicMeetingUsers } from "actions";
import { Console } from "console";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ComponentRightSide } from "constants/common-constants";

const Container = styled("div")({
  display: "grid",
  gridTemplateRows: "1fr auto",
});

const useStyles = makeStyles((theme: Theme) => {
  const totalMobileSidebarHeight = `${theme.sidebarMobileHeight +
    theme.sidebarMobilePadding * 2 +
    theme.participantBorderWidth
    }px`;
  return {
    container: {
      position: "relative",
      height: "100%",
      display: "grid",
      width: "100%",
      gridTemplateColumns: `1fr ${theme.sidebarWidth}px`,
      gridTemplateRows: "100%",
      [theme.breakpoints.down("sm")]: {
        gridTemplateColumns: `100%`,
        gridTemplateRows: `calc(100% - ${totalMobileSidebarHeight}) ${totalMobileSidebarHeight}`,
      },
    },
    rightDrawerOpen: {
      gridTemplateColumns: `1fr ${theme.sidebarWidth}px ${theme.rightDrawerWidth}px`,
    },
  };
});

/**
 * This hook turns on speaker view when screensharing is active, regardless of if the
 * user was already using speaker view or gallery view. Once screensharing has ended, the user's
 * view will return to whatever they were using prior to screenshare starting.
 */

export function useSetSpeakerViewOnScreenShare(
  screenShareParticipant: Participant | undefined,
  room: IRoom | null,
  setIsGalleryViewActive: React.Dispatch<React.SetStateAction<boolean>>,
  isGalleryViewActive: boolean
) {
  const isGalleryViewActiveRef = useRef(isGalleryViewActive);

  // Save the user's view setting whenever they change to speaker view or gallery view:
  useEffect(() => {
    isGalleryViewActiveRef.current = isGalleryViewActive;
  }, [isGalleryViewActive]);

  useEffect(() => {
    if (
      screenShareParticipant &&
      screenShareParticipant !== room!.localParticipant
    ) {
      // When screensharing starts, save the user's previous view setting (speaker or gallery):
      const prevIsGalleryViewActive = isGalleryViewActiveRef.current;
      // Turn off gallery view so that the user can see the screen that is being shared:
      setIsGalleryViewActive(false);
      return () => {
        // If the user was using gallery view prior to screensharing, turn gallery view back on
        // once screensharing stops:
        if (prevIsGalleryViewActive) {
          setIsGalleryViewActive(prevIsGalleryViewActive);
        }
      };
    }
  }, [screenShareParticipant, setIsGalleryViewActive, room]);
}

export default function Room({
  toggleVideoChat,
  toggleIncomingCallPopup,
  handleIncomingCallpopup,
}: {
  toggleVideoChat: boolean;
  toggleIncomingCallPopup?: boolean;
  handleIncomingCallpopup?: any;

}) {
  const connection = useContext(SignalRContext);
  const [handleComponentRightSide, setHandleComponentRightSide] = useState(ComponentRightSide.RightSide);
  const roomState = useRoomState();
  const dispatch: any = useDispatch();
  const [callApi, setCallApi] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const publicLink = searchParams.get("public");
  const Id = searchParams.get("id");
  const navigate = useNavigate();

  const insertMessage = (data: any) => {
    dispatch({ type: INSERT_GUEST_CHAT_MESSAGES, payload: data });
  };
  // useEffect(() => {
  //   if(publicLink){
  //     const publicUser = JSON.parse(localStorage.getItem('User') ?? JSON.stringify(''))
  //     const payload = {
  //      FName : publicUser.Name,
  //      LName: '',
  //      Email : publicUser.Email,
  //      ProfilePicture: '',
  //      PublicGuestId: publicUser.Id
  //     }
  //     dispatch(InsertPublicMeetingUsers(payload))
  //     console.log(payload,'payloadpayloadpayloadpayload')
  //     console.log(publicUser,'lastlondsdsdg')
  //   }
  // },[publicLink])
  const Local_callEnd = JSON.parse(
    localStorage.getItem("callEnd") ?? JSON.stringify("")
  );

  useEffect(() => {

    if (Local_callEnd == true )  {
     
        room?.disconnect();   
       
    }
  }, [Local_callEnd]);



  useEffect(() => {
    if (connection) {
      connection.on("DataInSignalR", async (data: any) => {
        if (data) {
          let result;
          result = JSON.parse(data);
          if (result && result.length) {
            if (result[0].dataType == SignalREventsEnums.UserInChat) {
              console.log("---- GUEST USER IN CHAT -----");
              if (publicLink || Id) {
                insertMessage(result[0])
              }
            }
          
          }
          else{
            if (result.dataType == SignalREventsEnums.EndEntireCall) {
              if(publicLink || Id){ 
              
                room?.disconnect();
                setHandleComponentRightSide(ComponentRightSide.RightSide);
                console.log("------ENTIRE-CALL-END-----");
                const Local_GuestChatRoom = JSON.parse(
                  localStorage.getItem("guestUser") ?? JSON.stringify("")
                ); 
    
                const Local_RoomData = JSON.parse(
                  localStorage.getItem("RoomData") ?? JSON.stringify("")
                );
                const Local_profile = JSON.parse(
                  localStorage.getItem("profile") ?? JSON.stringify("")
                );
                const deletedData = {
                  UserId: Local_profile.UserId
                 }
                 console.log("------Remove-ENTIRE-CALL-END-----");
                dispatch(RemoveUsersInCall(deletedData))
                dispatch(GetUsersInCall())
                dispatch(GetOnlineUsers())
                if (result.Roomid == Local_RoomData?.CurrentroomName || result.RoomName == room?.name ) {    
                  setHandleComponentRightSide(ComponentRightSide.RightSide); 
                  room?.disconnect();
                    
                  localStorage.removeItem("RoomData"); 
                  localStorage.setItem("callEnd",'true'); 
                  
                  const Local_profile = JSON.parse(
                    localStorage.getItem("profile") ?? JSON.stringify("")
                  );
                  const payloadOnlineUser =
                  {
                    UserId: Local_profile.UserId,
                  }  
                dispatch(DeleteOnlineUser(payloadOnlineUser));
                  const payload = {
                    PublicGuestId: Local_profile.UserId
                  }
                  dispatch(
                    DeletePublicMeetingUsers(payload))
                    toast.dismiss();                        
                    toast(`Call Ended by ${result.Name}`);                    
                    navigate("/");                                        
                }
                const roomNameLatest = JSON.parse(
                  localStorage.getItem("roomNameLatest") ?? JSON.stringify("")
                );
                if (result.Roomid == Local_GuestChatRoom.roomId ) {
                  setHandleComponentRightSide(ComponentRightSide.RightSide);
                  room?.disconnect();                 
                  localStorage.removeItem("guestUser");     
                  navigate("/");   
                  toast.dismiss();                        
                  // toast(`Call Ended by ${result.Name}`);                          
                } 
              }
            }
          }
        }
      });
    }
  }, [connection]);

  // Here we would like the height of the main container to be the height of the viewport.
  // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
  // not the viewport. This looks bad when the mobile browsers location bar is open.
  // We will dynamically set the height with 'window.innerHeight', which means that this
  // will look good on mobile browsers even after the location bar opens or closes.
  const height = useHeight();
  const classes = useStyles();
  const { room } = useVideoContext();
  const { isGalleryViewActive, setIsGalleryViewActive } = useAppState();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const screenShareParticipant = useScreenShareParticipant();
  // Here we switch to speaker view when a participant starts sharing their screen, but
  // the user is still free to switch back to gallery view.
  useSetSpeakerViewOnScreenShare(
    screenShareParticipant,
    room,
    setIsGalleryViewActive,
    isGalleryViewActive
  );
  useEffect(() => {
    dispatch(GetUsersInCall())
    dispatch(GetOnlineUsers())
  }, [callApi])

  useEffect(() => {
    const handleBeforeUnload = (e: any) => {
      e.preventDefault();
      e.returnValue = ""; // Prevents the default dialog prompt in most browsers

      const hasTriggered = JSON.parse(localStorage.getItem('beforeunloadTriggered') ?? 'false');
      if (hasTriggered) {
        return;
      }
      localStorage.setItem('beforeunloadTriggered', 'true');

      const Profile = JSON.parse(localStorage.getItem("profile") ?? JSON.stringify(""));
      const deletedData = {
        UserId: Profile.UserId,
        Status:1,
      }
      console.log("------Remove-ENTIRE-CALL-END-----");
      const payloadOnlineUser =
      {
        UserId: Profile.UserId,
      }
      const payload = {
        PublicGuestId: Profile.UserId
      };
      dispatch(DeleteOnlineUser(payloadOnlineUser));
      dispatch(DeletePublicMeetingUsers(payload));
      dispatch(RemoveUsersInCall(deletedData));
      dispatch(UpdateOnlineUsers(deletedData))
     
      setCallApi(!callApi);
      dispatch(GetUsersInCall());
      dispatch(GetOnlineUsers())
    };

    const handleUnload = () => {
      const Profile = JSON.parse(localStorage.getItem("profile") ?? JSON.stringify(""));
      const payloadOnlineUser =
      {
        UserId: Profile.UserId,
      }
      const payload = {
        PublicGuestId: Profile.UserId
      };
      dispatch(DeletePublicMeetingUsers(payload));
      // const payloadOnlineUser =
      // {
      //   UserId: Profile.UserId,
      // }
      dispatch(DeleteOnlineUser(payloadOnlineUser));
      const deletedData = {
        UserId: Profile.UserId
      };    
      dispatch(RemoveUsersInCall(deletedData));
      setCallApi(!callApi);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('unload', handleUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('unload', handleUnload);
    };
  }, []);
 


  useEffect(() => {
    
    if (Local_callEnd == true )  {
        room?.disconnect();      
    }
  }, [Local_callEnd]);




 



  return (
    <Container style={{ width: "100%", height: `calc( ${height} - 150px )` }}>
      {roomState === "disconnected" ? (
        <PreJoinScreens />
      ) : (
        <div className="d-flex">
          <div className={classes.container} style={{ background: "black" }}>
            {/* 
        This ParticipantAudioTracks component will render the audio track for all participants in the room.
        It is in a separate component so that the audio tracks will always be rendered, and that they will never be 
        unnecessarily unmounted/mounted as the user switches between Gallery View and speaker View.
      */}
            <ParticipantAudioTracks />

            {isGalleryViewActive ? (
              isMobile ? (
                <MobileGalleryView />
              ) : (
                <GalleryView isGalleryViewActive={true} />
              )
            ) : (
              <GalleryView isGalleryViewActive={false} />
            )}
          </div>
          {toggleVideoChat && (!publicLink && !Id) ? <ChatBox width={"350px"} /> : null}
          {toggleVideoChat && (publicLink || Id) ? <GuestChatBox width={"350px"} /> : null}
          {toggleIncomingCallPopup ? <IncomingCallList handleIncomingCallpopup={handleIncomingCallpopup} /> : null}
        </div>
      )}
    </Container>
  );
}
