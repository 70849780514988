
const SignupConformationModal = ({ handleCloseModal }: any) => {
    return (
        <>
            <div
                role="dialog"
                tabIndex={-1}
                className="modal d-block in modal-theme fade show"
                aria-modal="true"
            >
                <div role="document" className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button className="close"
                                type="button"
                                onClick={handleCloseModal}
                                style={{ background: "#003964", border: 0 }}
                            >
                                ×
                            </button>
                            <h4 className="modal-title">
                                Signup Confirmation
                            </h4>
                        </div>
                        <div className="modal-body">
                            <div className="content">
                                {" "}
                                Keep an eye out on your inbox - we just emailed you a link to
                                set your account password. If you don't receive anything,
                                check your spam folder or wait for some time.{" "}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button style={{ background: "#003964" }}
                                className="btn btn-theme"
                                data-dismiss="modal"
                                type="button"
                                onClick={handleCloseModal}
                            >
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SignupConformationModal;