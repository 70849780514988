import logo from "assets/images/small-world-solutions-logo-blue.png";
import logo2 from "assets/images/small-world-solutions-logo-blue.png";
import homeIcon from "assets/images/icon-menu/home-icon.png";
import helpIcon from "assets/images/icon-menu/help-icon.png";
import helpIcon2 from "assets/images/icon-menu/help-icon.png";
import defaultUser from "assets/images/default-user.png";
import homeIconNew from "assets/images/icon-menu/home-icon-new.png";
import library from "assets/images/icon-menu/library.png";
import Breakout from "assets/images/Breakout.png";
import breakout_rooms from "assets/images/breakout_rooms.png";
import monitorIcon from "assets/images/icon-menu/measure-monitor-maximize-icon.png";
import monitorIcon2 from "assets/images/icon-menu/measure-monitor-maximize-icon.png";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState, useContext, useRef } from "react";
import { useDispatch } from "react-redux";
import { getUserDetails } from "actions/account";
import LinkIcon from "@mui/icons-material/Link";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { toastSuccess } from "services";
import { v4 as uuid } from "uuid";
import PublicOngoingCall from "layouts/PublicOngoingCall/PublicOngoingCall";
import BreakoutRoom from "features/Modal/BreakoutRoom";
import { any } from "joi";
import { breakoutRoom } from "actions/room";
import { SignalRContext } from "services/signal-r-service";
import { ConnectSignalREnums } from "constants/common-constants";
import BreakoutMode from "features/Modal/BreakoutMode";
import { SET_MODAL, SET_MANUAL, SET_AUTOMATICALLY, SET_TIMER } from "constants/store-actions";
import BreakoutAutomatically from "features/Modal/BreakoutAutomatically";
import {
  DeleteOnlineUser
} from "actions";


const Header = () => {
  const connection = useContext(SignalRContext);
  // interface User1 {
  // name?: any;
  // check?:any
  //   // Add any other properties of the user object here
  // }
  const navigate = useNavigate();
  const { timer } = useSelector((state: any) => state.room);
  const [searchParams, setSearchParams] = useSearchParams();
  const [show, setShow] = useState(false);
  const [remainingTime, setRemainingTime] = useState(15);
  const shouldSkipUnload = useRef(false);
  // const [showModal, setShowModal] = useState(false);
  // const [selectedUser, setselectedUser] = useState<User1[]>([]);

  const publicLink = searchParams.get("public");
  const id = searchParams.get("id");
  const profile = JSON.parse(
    localStorage.getItem("profile") || JSON.stringify("")
  );
  const guestLink = `https://app.theauthenticconsortium.com/guest-meeting/get-together?public=true&email=${profile.Email}`;
  const dispatch: any = useDispatch();
  const User = useSelector((state: any) => state.account);
  const { allmemberInRoom } = useSelector((state: any) => state.guest);
  const { showModal } = useSelector((state: any) => state.room);
  const { showManual, showAutomatic } = useSelector((state: any) => state.room);
  const [leaveRoomArray, setLeaveRoomArray] = useState<any[]>([]);
  const [leaveRoomShow, setLeaveRoomShow] = useState(false);
  const [showAdminLeaveRoom, setIsShowAdminLeaveRoom] = useState(false);
  const [showLocalLeaveRoom, setIsShowLocalLeaveRoom] = useState(false);
  const handleSignOut = () => {
    const profile = JSON.parse(localStorage.getItem('profile') ?? JSON.stringify(''));
    const payload =
    {
      UserId: profile.UserId,
    }
   
    dispatch(DeleteOnlineUser(payload));
    const rememberme = JSON.parse(
      localStorage.getItem("rememberme") || JSON.stringify("")
    );
    if(rememberme){
      const keyToKeep = 'password';
      const keyToKeep2 = 'rememberme';
      for (let key in localStorage) {
        if (localStorage.hasOwnProperty(key) && key !== keyToKeep && key !== keyToKeep2 ) {
          localStorage.removeItem(key);
        }
      }
    }
    else{
      localStorage.clear()
    }
    navigate("/")
   
    // window.location.reload();
  };
  const Local_Timer = localStorage.getItem("Timer");

  const genLink = () => {
    return `https://app.theauthenticconsortium.com/guest-meeting/${uuid()}?public=true`;
  };
  const handleCopyGuestLink = () => {
    navigator.clipboard.writeText(guestLink.split("&")[0]);
    toastSuccess("Meeting Link Copied Successfully.");
  };

  const handleCopyPublicLink = async () => {
    const Profile = JSON.parse(localStorage.getItem('profile') ?? JSON.stringify(''));
    const payloadOnlineUser = {
      UserId: Profile.UserId,
    };
  
    try {
      await dispatch(DeleteOnlineUser(payloadOnlineUser));
    } catch (error) {
      console.log("DeleteOnlineUser action failed:", error);
    }
  
    shouldSkipUnload.current = true;
    window.open(guestLink, "_self");
  };

  useEffect(() => {
    if (publicLink || id) {
      setShow(true);
    } else {
      setShow(false);
    }
    dispatch(getUserDetails());
  }, []);

  const { breakroom } = useSelector((state: any) => state.room);
  const Admin = JSON.parse(
    localStorage.getItem("RoomData") || JSON.stringify("")
  );
  const ProfileData = JSON.parse(
    localStorage.getItem("profile") || JSON.stringify("")
  );
  const AdminName = Admin.Name?.split(" ")[0];
  const Admin_id = Admin.loginUserId?.split("-")[0];

  const handleCloseModal = () => {
    // setShowModal(false);
    dispatch({ type: SET_MODAL, payload: false });
  };
  const handleOpenModal = () => {
    localStorage.setItem('joinRoom', 'false')
    dispatch({ type: SET_MODAL, payload: true });

  }
  const handleCloseModalManually = () => {
    dispatch({ type: SET_MANUAL, payload: false });
  };


  const handleCloseModalAutomatically = () => {
    dispatch({ type: SET_AUTOMATICALLY, payload: false });
  };

  const localJoinRoom = localStorage.getItem("joinRoom")
  const LeaveRoom = (SID: any) => {
    dispatch({ type: SET_TIMER, payload: 0 });
    localStorage.removeItem('joinRoom')
    setIsShowLocalLeaveRoom(false)
    setIsShowAdminLeaveRoom(false)
    const localLeaveRoom = JSON.parse(
      localStorage.getItem("leaveRoom") ?? JSON.stringify("")
    );
    let deletedElementArray: any[] = [];
    const updatedArray = breakroom.map((subArray: any) => {
      if (subArray.length === 1) {
        // Add the element to the last subarray instead of removing it
        deletedElementArray.push({ ...subArray[0] });
        return subArray;
      } else {
        const filteredArray = subArray.filter((obj: any) => {
          if (obj.SID === SID) {
            deletedElementArray.push({ ...obj });
            return false;
          }
          return true;
        });
        return filteredArray;
      }
    });
    let updatedTArray1;
    if (localLeaveRoom) {
      const lastSubArrayIndex = updatedArray.length - 1;
      const lastSubArray = updatedArray[lastSubArrayIndex];
      updatedArray[lastSubArrayIndex] = [
        ...lastSubArray,
        ...deletedElementArray,
      ];
      updatedTArray1 = updatedArray;
    } else {
      const updatedTArray2 = [...leaveRoomArray, ...deletedElementArray];
      updatedTArray1 = [...updatedArray, updatedTArray2];
    }

    setLeaveRoomArray(updatedTArray1[updatedTArray1.length - 1]);
    dispatch(breakoutRoom(updatedTArray1));

    if (connection) {
      connection.send(
        "DataInSignalR",
        JSON.stringify({
          rooms: updatedTArray1,
          enteringMainRoom: true,
          dataType: ConnectSignalREnums.UserInBreakoutCall,
        })
      );
    }
  };



  const flattenedArray = breakroom?.flatMap((room: any) => room);

  const nestedArray = [flattenedArray];
  useEffect(() => {
    if (breakroom && breakroom?.length) {
      setTimeout(() => {
        localStorage.removeItem('joinRoom')
        dispatch(breakoutRoom(nestedArray));
        if (connection) {
          connection.send(
            "DataInSignalR",
            JSON.stringify({
              rooms: nestedArray,
              showLeaveRoomOption: true,
              enteringMainRoom: false,
              dataType: ConnectSignalREnums.UserInBreakoutCall,
            })
          );
        } else {
          console.log("=not connected=");
        }
      }, timer * 60000);
    }
  }, [timer])


  useEffect(() => {
    const timeInSeconds = timer * 60 ;
    let remainingTime1 = timeInSeconds;

    const interval = setInterval(() => {
      remainingTime1 -= 1;

      setRemainingTime(remainingTime1);

      if (remainingTime1 == 0) {
        clearInterval(interval);
        dispatch({ type: SET_TIMER, payload: 0 });

      }
    }, 1000);

    return () => clearInterval(interval);
  }, [timer]);

  const formatTime = (time: any) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };



  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (!shouldSkipUnload.current) {
        const hasVisitedBefore = JSON.parse(localStorage.getItem('visitedBefore') || 'false');
        
        if (hasVisitedBefore) {
          e.preventDefault();
          e.returnValue = '';
  
          const Profile = JSON.parse(localStorage.getItem("profile") || JSON.stringify(""));
          const deletedData = {
            UserId: Profile.UserId,
          };
  
          dispatch(DeleteOnlineUser(deletedData));
        } else {
          localStorage.setItem('visitedBefore', 'true');
        }
      }
    };
  
    window.addEventListener('beforeunload', handleBeforeUnload);
  
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
// console.log("=====ljjg",localJoinRoom)

  return (
    <div>
      {/* ------BreakoutMode,BreakoutRoom,BreakoutAutomatically modal added by vidushi---- */}
      {showModal ? (
        <BreakoutMode
          handleCloseModal={handleCloseModal}
          setIsShowAdminLeaveRoom={setIsShowAdminLeaveRoom}
          setIsShowLocalLeaveRoom={setIsShowLocalLeaveRoom}
          setLeaveRoomShow={setLeaveRoomShow}
          handleCloseModalManually={handleCloseModalManually}
          handleCloseModalAutomatically={handleCloseModalAutomatically}
          showManual={showManual}
          showAutomatic={showAutomatic}
        />
      ) : null}
      {showManual ? (
        <BreakoutRoom
          handleCloseModalManually={handleCloseModalManually}
          setIsShowAdminLeaveRoom={setIsShowAdminLeaveRoom}
          setIsShowLocalLeaveRoom={setIsShowLocalLeaveRoom}
          setLeaveRoomShow={setLeaveRoomShow}
          timer={timer}
        />
      ) : null}
      {showAutomatic ? (
        <BreakoutAutomatically
          handleCloseModalAutomatically={handleCloseModalAutomatically}
          setIsShowAdminLeaveRoom={setIsShowAdminLeaveRoom}
          setIsShowLocalLeaveRoom={setIsShowLocalLeaveRoom}
          setLeaveRoomShow={setLeaveRoomShow}
          timer={timer}
        />
      ) : null}

      <div className="sub-menu web-menu-view" id="menu-theme-main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-xs-6">
              <NavLink className="navbar-brand" to="/home">
                <img src={logo} alt="logo" />
              </NavLink>
            </div>

            <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12 d-flex align-items-center">
               {/* ------BreakoutRoom added by vidushi---- */}
              {allmemberInRoom.length >= 2 && Admin_id == ProfileData.UserId ? (
                <button
                  onClick={() => handleOpenModal()}
                  style={{
                    margin: "0px 12px ", borderColor: "#003964"
                    , paddingTop: '0px', paddingBottom: '0px'
                  }}

                  className="btn"
                >

                  <div><img
                    src={breakout_rooms}
                    style={{ width: "40px" }}
                    alt="user-image"
                  />
                  </div>
                  <p style={{ margin: '0px', padding: '0px', color: '#fff', fontSize: '10px', lineHeight: 'inherit' }}>
                    Breakout Room
                  </p>


                </button>
              ) : null}

              {allmemberInRoom.length && breakroom?.length ? (
                localJoinRoom ? (
                  <button
                    onClick={() => LeaveRoom(allmemberInRoom[0].SID)}
                    style={{
                      margin: "0px 12px", backgroundColor: "#e0b200",
                      borderColor: "#e0b200"
                    }}
                    className="btn btn-primary btn-sm"
                  >
                    Leave-Room
                  </button>

                ) : null
              ) : null}

              {/* { breakroom?.length && showAdminLeaveRoom ? (
                <button
                  onClick={() => LeaveRoom(allmemberInRoom[0].SID)}
                  style={{ margin: "0px 12px" }}
                  className="btn btn-primary btn-sm"
                >
                  Leave-Room-admin
                </button>
              ) : null}

               {breakroom?.length && showLocalLeaveRoom ? (
                <button
                  onClick={() => LeaveRoom(allmemberInRoom[0].SID)}
                  style={{ margin: "0px 12px" }}
                  className="btn btn-primary btn-sm"
                >
                  Leave-Room-local
                </button>
              ) : null} */}


              <ul className="sub-menu-left">
                <li style={{ display: "flex" }}>
                  <div
                    style={{
                      marginLeft: "74px",
                      background: "white",
                      paddingRight: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    <div
                      onClick={handleCopyPublicLink}
                      className="btn btn-light btn-sm cafe cafe-get-together"
                      style={{ fontSize: '14px !important', padding: '5px 3px' }}
                    >
                      Click Here To Enter The Public Meeting
                    </div>
                    <i title="copy link">
                      <ContentCopyIcon
                        style={{
                          marginLeft: "5px",
                          fontSize: "18px",
                          cursor: "pointer",
                          marginTop: "0px",
                        }}
                        onClick={handleCopyGuestLink}
                      />
                    </i>
                  </div>
                  <div>
                    <PublicOngoingCall />
                  </div>
                </li>
                {/* <li>
                  <NavLink to={"/help"}>
                    <i className="icon-theme">
                      <img src={helpIcon} />
                    </i>
                    <div className="icon-name">Help</div>
                  </NavLink>
                </li> */}
              </ul>


            </div>

            <div className="col-xl-4 col-lg-4 col-md-8 col-sm-8 col-xs-6">

              <div className="right-navbar">
                {/* ------BreakoutRoom Timer added by vidushi---- */}
                {allmemberInRoom.length && breakroom?.length && remainingTime > 0 ? (
                  <p
                    style={{
                      width: 'auto',
                      marginRight: '30%',
                      marginBottom: '0px',
                      alignSelf: 'center',
                      fontSize: '20px',
                      color: '#fff',
                      fontWeight: 'bold'
                    }}>
                    {formatTime(remainingTime)}
                  </p>
                ) : null}
                <div className="navbar navbar-expand-lg navbar-theme">
                  <div className="navbar-collapse">
                    <ul className="navbar-nav mr-auto">
                      <li className="nav-item dropdown dropdown-header-users">
                        <a
                          className="nav-link dropdown-toggle"
                          href="#"
                          id="navbarDropdownMenuLink"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <div className="user-image">
                            <img
                              src={
                                User.userProfilePic
                                  ? `https://api.smallworldmeet.com/${User.userProfilePic}`
                                  : defaultUser
                              }
                            ></img>
                          </div>
                          <div className="user-name">
                            {profile.FirstName + " " + profile?.LastName ?? ""}
                          </div>
                        </a>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdownMenuLink"
                        >
                          <NavLink to="/my-account">My Account</NavLink>
                          <NavLink to="/change-password">
                            Change Password
                          </NavLink>
                          <NavLink to="/" onClick={handleSignOut}>
                            Sign Out
                          </NavLink>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="sub-menu mob-menu-view" id="menu-theme-main1">
        <div className="container-fluid">
          <div className="row">
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav mr-auto sub-menu-left">
                  <li className="nav-item">
                    <a href="javascript:void(0)">
                      <i className="icon-theme">
                        <img src={homeIconNew} />
                      </i>
                      <div className="icon-name">Home</div>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="javascript:void(0)">
                      <i className="icon-theme">
                        <img src={library} />
                      </i>
                      <div className="icon-name">Library</div>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="javascript:void(0)">
                      <i className="icon-theme">
                        <img src={monitorIcon} />
                      </i>
                      <div className="icon-name">DEIA Dashboard</div>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="javascript:void(0)">
                      <i className="icon-theme">
                        <img src={monitorIcon2} />
                      </i>
                      <div className="icon-name">
                        Personal Influence Monitoring
                      </div>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="javascript:void(0)">
                      <i className="icon-theme">
                        <img src={helpIcon2} />
                      </i>
                      <div className="icon-name">Help</div>
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
            <a className="navbar-brand" href="javascript:void(0)">
              <img src={logo2} />
            </a>

            <div className="col-xl-4 col-lg-4 col-md-8 col-sm-8 col-xs-6">
              <div className="right-navbar">
                <div className="navbar navbar-expand-lg navbar-theme">
                  <div className="navbar-collapse">
                    <ul className="navbar-nav mr-auto">
                      <li className="nav-item dropdown dropdown-header-users">
                        <a
                          aria-expanded="false"
                          aria-haspopup="true"
                          className="nav-link dropdown-toggle"
                          data-toggle="dropdown"
                          href="#"
                          id="navbarDropdownMenuLink"
                        >
                          <div>
                            <img id="imgPrev" src={defaultUser} />
                          </div>
                          <div className="user-name"></div>
                        </a>
                        <div
                          aria-labelledby="navbarDropdownMenuLink"
                          className="dropdown-menu"
                        >
                          <a href="/account/profile">My Account</a>
                          <a href="/change-password">Change Password</a>
                          <a href="javascript:void(0)">Sign Out</a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
