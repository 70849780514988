import { useEffect, useState } from "react";
import { getUserDetails, uploadProfileImage } from "actions/account";
import { useSelector } from "react-redux";
import { useAppDispatch } from "hooks";
import GernalDetails from "./GernalDetails/GernalDetails";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import DefaultUserImage from 'assets/images/default-user.png';


const MyAccount = () => {

  const dispatch = useAppDispatch();
  const Account = useSelector((state: any) => state.account);
  const [show, setShow] = useState(false)

  const handleImageUpload = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const formdata: any = new FormData();
      formdata.append("formFile", file);
      dispatch(uploadProfileImage(formdata))
    }
  };

  useEffect(() => {
    dispatch(getUserDetails());
  }, [Account.userProfilePic]);

  
  return (
    <>
      <div className="page-area">
        <div className="container-fluid">
          <div className="page-section">
            <div className="title-top">
              <div className="page-title">
                <strong>My Account</strong>
              </div>
            </div>
            <div className="user-details">
              <div className="user-header">
                <div className="user-container">
                  <div className="header-content">
                    <div className="user-image">
                      <div className="upload-user-photo-group">
                        <div className="upload-photo-filter">
                        </div>
                        <div className="upload-user-photo">
                          <img src={Account.userProfilePic ? `https://api.smallworldmeet.com/${Account.userProfilePic}` : DefaultUserImage}
                            id="imgPrev"></img>
                        </div>
                        <div className="fileupload" data-provides="fileupload">
                          <input type="hidden"></input>
                          <span className="btn upload-image btn-file">
                            <span className="fa fa-upload">
                              <strong> Upload</strong>
                            </span>
                            <input type="file" id="file" onChange={handleImageUpload}
                            ></input>
                          </span>
                        </div>
                      </div>
                      <div className="image-shadow"></div>
                    </div>
                    <div className="user-name">
                      <span>{Account.account?.FullName}</span>
                    </div>
                    {Account.account?.Email ? <div className="user-email">
                      <MailOutlineIcon fontSize="small" /> {Account.account?.Email}
                    </div> : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="user-details user-profile">
              <div className="user-body">
                <div className="body-content">
                  <GernalDetails setShow={setShow} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyAccount;