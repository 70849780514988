// @ts-nocheck
import { sendMessage, sendFile } from "actions/chat";
import React, { useContext } from "react";
import useVideoContext from "hooks/useVideoContext/useVideoContext";
import { useEffect, useRef, useState } from "react";
// import {ConnectSignalREnums} from ''
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import ChatMessageComponent from "./ChatMessageComponent";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import VideoFileIcon from "@mui/icons-material/VideoFile";
import { SignalRContext } from "services/signal-r-service";
import { useSearchParams } from "react-router-dom";



const ChatBox = ({ width }: { width?: string }) => {
  const { chatMessages, loading } = useSelector((state: any) => state.chat);
  const { room } = useVideoContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const publicLink = searchParams.get("public");
  const messageEnd = useRef<any>();
  const [files, setFiles] = useState([]);
  const [message, setMessage] = useState("");
  const inputElement = React.useRef();
  const dispatch: any = useDispatch();
  const connection = useContext(SignalRContext);
  const [url, setUrl] = useState([]);

  const handleClick = () => {
    inputElement.current.click();
  };

  const { allmemberInRoom } = useSelector((state: any) => state.guest);

  const handleMessageFiled = (value: string) => {
    setMessage(value);
  };

  const handleAttachment = (event: any) => {
    setFiles(event.target.files);
    handlePreviewFile(event.target.files);
  };

  const handlePreviewFile = (fileslist) => {
    const fileUrl = [];
    if (fileslist && fileslist.length > 0) {
      for (const file of fileslist) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          if (file.type.indexOf("image") > -1) {
            fileUrl.push({
              img: e.target.result,
              type: "img",
              name: file.name,
            });
            setUrl(fileUrl);
          } else if (file.type.indexOf("video") > -1) {
            fileUrl.push({
              img: e.target.result,
              type: "video",
              name: file.name,
            });
            setUrl(fileUrl);
          } else {
            fileUrl.push({
              img: e.target.result,
              type: "file",
              name: file.name,
            });
            setUrl(fileUrl);
          }
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleSend = (e: any) => {
    e.preventDefault();
    // if (publicLink) {
    //   const SingleUser = {
    //     Value:"test",
    //     LastMessage: message,
    //     ispublic: true,
    //     dataType:ConnectSignalREnums.GuestInChat
    //   };
    //   console.log(connection)
    //   if(connection) {
    //     connection.send("DataInSignalR", JSON.stringify(SingleUser));
    //   }
    //   return;
    // }
    const chatUser = JSON.parse(
      localStorage.getItem("chatUser") ?? JSON.stringify("")
    );
    let chatRoomId = chatUser.ChatRoomId;
    if (room)
      chatRoomId = JSON.parse(
        localStorage.getItem("RoomData") ?? JSON.stringify("")
      ).ChatRoomId;

    if (files && files.length) {
      const formData = new FormData();

      for (let file of files) formData.append("formFile", file);
      formData.append("ChatRoomId", chatRoomId);
      formData.append(
        "Reciver_Id",
        chatUser.Key ??
        JSON.parse(
          localStorage.getItem("RoomData") ?? JSON.stringify("")
        ).loginUserId.split("-")[0]
      );
      formData.append("FileName", "");
      formData.append("FileUrl", "");
      formData.append("IsFile", true);
      setFiles([]);
      setUrl([]);
      // dispatch(sendFile(formData));
      if (allmemberInRoom.length) {
        formData.append("Room", room?.name);
        dispatch(sendFile(formData));
      } else {
        dispatch(sendFile(formData));
      }
    } else {
      if (message) {
        const payload = {
          ChatRoomId: chatRoomId,
          IsUrl: 0,
          Message: message,
          Reciver_Id:
            chatUser.Key ??
            JSON.parse(
              localStorage.getItem("RoomData") ?? JSON.stringify("")
            ).loginUserId?.split("-")[0],
        };
        const groupPayload = {
          ChatRoomId: chatRoomId,
          IsUrl: 0,
          Message: message,
          Reciver_Id:
            chatUser.Key ??
            JSON.parse(
              localStorage.getItem("RoomData") ?? JSON.stringify("")
            ).loginUserId?.split("-")[0],
          Room: room?.name
        };
        setMessage("");

        if (allmemberInRoom.length) {
          dispatch(sendMessage(groupPayload));
        }
        else {
          dispatch(sendMessage(payload));
        }
      }
    }
  };

  const handelClose = (index) => {
    let filterValue = [...url];
    filterValue.splice(index, 1);
    setUrl(filterValue);
  };

  useEffect(() => {
    if (chatMessages && chatMessages.length) {
      messageEnd.current.scrollIntoView({ behavior: "smooth" });
      const scroll =
        messageEnd.current.scrollHeight - messageEnd.current.clientHeight;
      messageEnd.current.scrollTop = messageEnd.current.scrollHeight;
    }
  }, [chatMessages, messageEnd]);

  return (
    <div
      className="feedback-group testbg image hide-bg chat-group is-loaded"
      style={{ height: `calc( 100vh - 155px )`, width: width ? width : "100% " }}
    >
      <div
        className="feedback-content"
        ref={messageEnd}
        style={{ height: "calc(100vh - 180px)", paddingBottom: "50px" }}
      >
        {loading ? (
          <div className="message-line" style={{ opacity: 1 }}>
            <div className="join-user-message">
              <b>Loading... </b>
            </div>
            <div className="message-attachment">
              <div className="document-in" />
              <div />
            </div>
          </div>
        ) : (
          <ChatMessageComponent list={chatMessages} />
        )}
        <div style={{ float: "left", clear: "both" }} ref={messageEnd}></div>
      </div>

      {url && url.length ? (
        <div className="feedback-content-attachments">
          {url.map((item: any, index: any) => {
            return (
              <div key={index} className="feedback-content-attachment-file">
                {item.type == "img" ? (
                  <div className="feedback-content-thumbnail-img">
                    <img id="imgPrev" alt="img" src={item.img} />
                  </div>
                ) : null}

                {item.type == "file" ? (
                  <div className="feedback-content-attach-icon">
                    <UploadFileIcon style={{ fontSize: "40px" }} />
                  </div>
                ) : null}

                {item.type == "video" ? (
                  <div className="feedback-content-attach-icon">
                    <VideoFileIcon style={{ fontSize: "40px" }} />
                  </div>
                ) : null}

                <div className="feedback-content-attachment-name">
                  {item.name}
                </div>
                <div
                  className="feedback-content-attachment-icon"
                  onClick={() => handelClose(index)}
                >
                  <i className="fa fa-times" />
                </div>
              </div>
            );
          })}
        </div>
      ) : null}
      
      <form noValidate className="ng-untouched ng-pristine ng-valid">
        <div className="send-message">
          <input
            autoComplete="off"
            onChange={(e) => handleMessageFiled(e.target.value)}
            className="form-control ng-untouched ng-pristine ng-valid"
            name="chatBox"
            placeholder="Type a message"
            type="text"
            pattern="/.*\S.*/"
            value={message}
          />
          <div className="action-message-button">
            <button
              className="fa fa-paperclip"
              mat-raised-button=""
              type="button"
              onClick={handleClick}
            />
            <input
              style={{ display: "none" }}
              ref={inputElement}
              id="upload-files"
              onChange={handleAttachment}
              type="file"
              multiple
            />
            <button
              className="fa fa-paper-plane"
              onClick={handleSend}
              style={{ background: "#003964" }}
              aria-hidden="true"
              type="submit"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default ChatBox;
