import React from 'react';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

export default function ExitCallButton({ handleDisconnect }: any) {

  return (
    <div style={{ marginRight: '30px', textAlign: 'center' }}>
      <button
        style={{
          backgroundColor: "red",
          color: "white",
          border: "none",
          borderRadius: "5px",
          marginLeft: "10px",
        }}
        onClick={handleDisconnect}
        data-cy-disconnect
      >
        <ExitToAppIcon />
      </button>
      <span style={{ display: 'block', marginLeft: '7px' }}>Just Me Leave</span>

    </div>
  );
}
