/*--Breakout MODE added by Vidushi----*/
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SignalRContext } from "services/signal-r-service";
import {
    Box,
    Button
} from "@mui/material";
import { ConnectSignalREnums } from "constants/common-constants";
import { SET_AUTOMATICALLY, SET_MANUAL, SET_MODAL } from "constants/store-actions";

const BreakoutMode = ({ handleCloseModal,showAutomatic,showManual}: any) => {
    const dispatch: any = useDispatch();

    const handleOpenModalAutomatically = () => {
        dispatch({ type: SET_AUTOMATICALLY, payload: true });

    }
    const handleOpenModalManually = () => {
        dispatch({ type: SET_MANUAL, payload: true });
    }

    return (
        <>  
            <div
                role="dialog"
                tabIndex={-1}
                className="modal d-block in modal-theme fade show"
                aria-modal="true"
            >
                <div role="document" className="modal-dialog modal-md">
                    <div
                        className="modal-content"
                        style={{ maxWidth: "500px", minWidth: "350px" }}
                    >
                        <div className="modal-header">
                            <button
                                className="close"
                                type="button"
                                onClick={handleCloseModal}
                                style={{ background: "#003964", border: 0 }}
                            >
                                ×
                            </button>
                            <h4 className="modal-title">Choose The Mode</h4>
                        </div>
                        <div className="modal-body">
                            <div className="content shadow p-3 mb-5 bg-body rounded">
                                <Box sx={{ minWidth: 120, zIndex: "99999999" }}>
                                    <Button
                                        onClick={handleOpenModalAutomatically}
                                        style={{
                                            margin: "0px 12px ", backgroundColor: "#e0b200",
                                            borderColor: "#e0b200"
                                        }}
                                        className="btn btn-primary btn-sm"
                                    >
                                        Automatic Mode
                                    </Button>
                                    <Button
                                        onClick={handleOpenModalManually}
                                        style={{
                                            margin: "0px 12px ", backgroundColor: "#e0b200",
                                            borderColor: "#e0b200"
                                        }}
                                        className="btn btn-primary btn-sm"
                                    >
                                        Manual Mode
                                    </Button>
                                </Box>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BreakoutMode;
