/* -----BreakoutAutomatically done By vidushi--------- */
import React from "react";
import { useEffect, useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SignalRContext } from "services/signal-r-service";
import { breakoutRoom } from "actions/room";
import useVideoContext from "hooks/useVideoContext/useVideoContext";
import { ConnectSignalREnums } from "constants/common-constants";
import { any, number } from "joi";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { Alert, Box, Button, Grid } from "@mui/material";
import { AllUsers } from "actions";
import { SET_TIMER } from "constants/store-actions";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const BreakoutAutomatically = ({ handleCloseModalAutomatically, setLeaveRoomShow, timer}: any) => {
  const connection = useContext(SignalRContext);
  const dispatch: any = useDispatch();
  const { allmemberInRoom } = useSelector((state: any) => state.guest);
  const [final, setFinal] = useState([]);
  const [personName, setPersonName] = React.useState([]);
  const [join, setJoin] = useState(false);
  const [divided, setDivided] = useState([]);
  const [joinIndex, setJoinIndex] = useState(0);
  const [age, setAge] = React.useState("");
  const [timers, setTimers] = React.useState(15);
  const [allUser, setAllUser] = useState(
    allmemberInRoom.map((item: any) => {
      return { ...item, checked: false, disabled: false };
    })
  );
  // const { timer } = useSelector((state: any) => state.room);
  const [showJoin, setShowJoin] = useState(false);
  const Admin = JSON.parse(
    localStorage.getItem("RoomData") || JSON.stringify("")
  );
  const ProfileData = JSON.parse(
    localStorage.getItem("profile") || JSON.stringify("")
  );
  const AdminName = Admin.Name?.split(" ")[0];
  const Admin_id = Admin.loginUserId?.split("-")[0];
  let RoomsNumber = (allmemberInRoom.length) / 2;
  RoomsNumber = Math.ceil(RoomsNumber);

  //   const handleChange2 = (event: any, index: number) => {
  //     const {
  //       target: { value },
  //     } = event;
  //     setPersonName(value);
  //     const updatedUser = allUser.map((user: any) => {
  //       if (value.find((u: any) => u.SID === user.SID)) {
  //         return {
  //           ...user,
  //           disabled: true,
  //           checked: true,
  //         };
  //       }

  //       return user;
  //     });

  //     setAllUser(updatedUser);

  //     setFinal((prevState) => {
  //       const newState: any = [...prevState];
  //       newState[index] = value;
  //       return newState;
  //     });
  //   };
  const handleChange1 = (event: any) => {
    setAge(event.target.value);
    const arr: any = [];
    for (let i = 0; i < event.target.value; i++) {
      arr.push({
        [`room${i}`]: [],
      });
    }
    setFinal(arr);
  };
  const handleChangeTimer = (event: any) => {
    setTimers(event.target.value)
  }

  const handleBreakout = () => {
    dispatch({ type: SET_TIMER, payload: timers });
    setLeaveRoomShow(true);
    const divideUsersIntoRooms = (allMembersInRoom: any, final: any) => {
      const roomCount = final.length;
      const dividedRooms: any = [];

      for (let i = 0; i < roomCount; i++) {
        dividedRooms.push([]);
      }

      allMembersInRoom.forEach((user: any, index: any) => {
        const roomIndex = index % roomCount;
        const roomArray = dividedRooms[roomIndex];
        roomArray.push(user);
      });

      return dividedRooms;

    };

    const dividedRooms = divideUsersIntoRooms(allmemberInRoom, final);
    //   setDivided(dividedRooms);
   

    localStorage.setItem("screen", "true");
    dispatch(breakoutRoom(dividedRooms));
    if (connection) {
      connection.send(
        "DataInSignalR",
        JSON.stringify({
          rooms: dividedRooms,
          showLeaveRoomOption: true,
          enteringMainRoom: false,
          Timer:timers,
          dataType: ConnectSignalREnums.UserInBreakoutCall,
        })
      );
    } else {
      console.log("=not connected=");
    }
    handleCloseModalAutomatically();

  }

  const admin = allmemberInRoom.filter((item: any) => item.name == AdminName);
  //     setLeaveRoomShow(true);
  //     if (!showJoin) {
  //       localStorage.removeItem("joinRoom");
  //     } else {
  //       localStorage.setItem("joinRoom", "true");
  //     }
  //     const secondRoom = allUser.filter((item: any) => !item.checked);
  //     let SingleUser;
  //     if (secondRoom.length === 0) {
  //       SingleUser = [...final];
  //     } else {
  //       const filteredsecond = secondRoom.filter(
  //         (item: any) => item?.SID !== admin[0]?.SID
  //       );
  //       if (join == true) {
  //         if (filteredsecond.length !== 0) {
  //           SingleUser = [...final, filteredsecond];
  //         } else {
  //           SingleUser = [...final];
  //         }
  //       } else {
  //         SingleUser = [...final, secondRoom];
  //       }
  //     }
  //     localStorage.setItem("screen", "true");
  //     dispatch(breakoutRoom(SingleUser));
  //     if (connection) {
  //       connection.send(
  //         "DataInSignalR",
  //         JSON.stringify({
  //           rooms: SingleUser,
  //           showLeaveRoomOption: true,
  //           enteringMainRoom: false,
  //           dataType: ConnectSignalREnums.UserInBreakoutCall,
  //         })
  //       );
  //     } else {
  //       console.log("========not connected==========");
  //     }
  //     handleCloseModalAutomatically();
  //   };

  const handleJoin = (index: number) => {
    // localStorage.setItem("joinRoom",'true')
    setShowJoin(true);
    if (connection) {
      connection.send(
        "DataInSignalR",
        JSON.stringify({ isjoin: true, dataType: ConnectSignalREnums.joinCall })
      );
    } else {
      console.log("=not connected=");
    }
    setJoin(!join);
    setJoinIndex(index);
    setFinal((prevState) => {
      const newState: any = [...prevState];
      newState[index].push(...admin);
      return newState;
    });
  };
  const handleUnJoin = (index: number) => {
    // localStorage.setItem("joinRoom",'false')
    setShowJoin(false);
    if (connection) {
      connection.send(
        "DataInSignalR",
        JSON.stringify({
          isjoin: false,
          dataType: ConnectSignalREnums.joinCall,
        })
      );
    } else {
      console.log("==not connected==");
    }
    setJoin(!join);
    setJoinIndex(0);
    setFinal((prevState) => {
      const newState: any = [...prevState];
      newState[index].pop(...admin);
      return newState;
    });
  };

  const filteredUser = allUser.filter(
    (item: any) => item?.SID !== admin[0]?.SID
  );

  return (
    <>

      <div
        role="dialog"
        tabIndex={-1}
        className="modal d-block in modal-theme fade show"
        aria-modal="true"
      >
        <div role="document" className="modal-dialog modal-md">
          <div
            className="modal-content"
            style={{ maxWidth: "500px", minWidth: "350px" }}
          >
            <div className="modal-header">
              <button
                className="close"
                type="button"
                onClick={handleCloseModalAutomatically}
                style={{ background: "#003964", border: 0 }}
              >
                ×
              </button>
              <h4 className="modal-title">Automatic Breakout Room</h4>
            </div>
            <div className="modal-body">
              <div className="content shadow p-3 mb-5 bg-body rounded">
                {" "}
                <Box sx={{ minWidth: 120, zIndex: "99999999" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Timer</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={timers}
                          label="Age"
                          onChange={handleChangeTimer}
                        >
                          <MenuItem value={5}>5 minutes</MenuItem>
                          <MenuItem value={10}>10 minutes</MenuItem>
                          <MenuItem value={15}>15 minutes</MenuItem>
                          <MenuItem value={20}>20 minutes</MenuItem>
                          <MenuItem value={25}>25 minutes</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Room</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={age}
                          label="Age"
                          onChange={handleChange1}
                        >
                          {Array(RoomsNumber)
                            .fill(null)
                            .map((_, i) => (
                              <MenuItem value={i + 1}>{i + 1}</MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>

              </div>

            </div>
            <div className="modal-footer">

              {final.length > 0 ? (
                <button
                  onClick={handleBreakout}
                  style={{ margin: "0px 12px" }}
                  className="btn btn-primary btn-sm"
                >
                  Break-out
                </button>
              ) :
                <button
                  onClick={handleBreakout}
                  style={{ margin: "0px 12px" }}
                  disabled
                  className="btn btn-primary btn-sm"
                >
                  Break-out
                </button>
              }

              {/* <button onClick={handleBreakout} style={{ margin: "0px 12px" }} className="btn btn-primary btn-sm">break out</button>  */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BreakoutAutomatically;
