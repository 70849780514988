// @ts-nocheck
import { sendMessage, sendFile } from "actions/chat";
import React, { useContext } from "react";
import { SignalREventsEnums } from "constants/signalR-events";
import useVideoContext from "hooks/useVideoContext/useVideoContext";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import ChatMessageComponent from "./ChatMessageComponent";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import VideoFileIcon from "@mui/icons-material/VideoFile";
import { SignalRContext } from "services/signal-r-service";
import { useSearchParams } from "react-router-dom";
import { ConnectSignalREnums } from "constants/common-constants";
import GuestChatMessageComponent from "./GuestChatMessageComponent";
import { insertGuestChat,UploadGuestChatFile } from "actions";


const GuestChatBox = ({ width }: { width?: string }) => {
  const { chatMessages, loading } = useSelector((state: any) => state.chat);
  const { room } = useVideoContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const publicLink = searchParams.get("public");
  const messageEnd = useRef<any>();
  const [files, setFiles] = useState([]);
  const [message, setMessage] = useState("");
  const inputElement = React.useRef();
  const dispatch: any = useDispatch();
  const [url, setUrl] = useState([]);

  const handleClick = () => {
    inputElement.current.click();
  };

  const handleMessageFiled = (value: string) => {
    setMessage(value);
  };

  const handleAttachment = (event: any) => {
    setFiles(event.target.files);
    handlePreviewFile(event.target.files);
  };

  const handlePreviewFile = (fileslist) => {
    const fileUrl = [];
    if (fileslist && fileslist.length > 0) {
      for (const file of fileslist) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          if (file.type.indexOf("image") > -1) {
            fileUrl.push({
              img: e.target.result,
              type: "img",
              name: file.name,
            });
            setUrl(fileUrl);
          } else if (file.type.indexOf("video") > -1) {
            fileUrl.push({
              img: e.target.result,
              type: "video",
              name: file.name,
            });
            setUrl(fileUrl);
          } else {
            fileUrl.push({
              img: e.target.result,
              type: "file",
              name: file.name,
            });
            setUrl(fileUrl);
          }
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleSend = (e: any) => {
    e.preventDefault();
    const receiver = JSON.parse(
      localStorage.getItem("chatUser") ?? JSON.stringify("")
    );
    let chatRoomId = receiver.ChatRoomId;
    const User = JSON.parse(localStorage.getItem("User") ?? JSON.stringify(""));
    const RoomData = JSON.parse(localStorage.getItem("GuestChatRoom")?? JSON.stringify(""));
    if (room)
      chatRoomId = JSON.parse(
        localStorage.getItem("RoomData") ?? JSON.stringify("")
      ).ChatRoomId;

    if (files && files.length) {
      const formData = new FormData();
      for (let file of files) formData.append("formFile", file);
      formData.append("ChatRoomId",RoomData.ChatRoomId ?? 1478);
      formData.append(
        "Reciver_Id",
        receiver.Key ??
          JSON.parse(
            localStorage.getItem("RoomData") ?? JSON.stringify("")
          ).loginUserId?.split("-")[0] ?? User.Id
      );
      formData.append("FileName", "");
      formData.append("FileUrl", "");
      formData.append("IsFile", true);
      formData.append("GuestId", User.Id);
      setFiles([]);
      setUrl([]);
      dispatch(UploadGuestChatFile(formData));
    } else {
      if (message) {
      
        const payload = {
          ChatRoomId: RoomData.ChatRoomId ?? 1478,
          Message: message,
          Reciver_Id: User.Id,
          GuestId: User.Id,
        };
        setMessage("");
        dispatch(insertGuestChat(payload));
      }
    }
  };

  const handelClose = (index) => {
    console.log(index);
    let filterValue = [...url];
    filterValue.splice(index, 1);
    setUrl(filterValue);
  };

  useEffect(() => {
    if (chatMessages && chatMessages.length) {
      messageEnd.current.scrollIntoView({ behavior: "smooth" });
      const scroll =
        messageEnd.current.scrollHeight - messageEnd.current.clientHeight;
      messageEnd.current.scrollTop = messageEnd.current.scrollHeight;
    }
  }, [chatMessages, messageEnd]);

  return (
    <div
      className="feedback-group testbg image hide-bg chat-group is-loaded"
      style={{
        height: `calc( 100vh - 180px )`,
        width: width ? width : "100% ",
      }}
    >
      <div
        className="feedback-content"
        ref={messageEnd}
        style={{ height: "calc(100vh - 250px)", paddingBottom: "50px" }}
      >
        {/* {loading ? (
          <div className="message-line" style={{ opacity: 1 }}>
            <div className="join-user-message">
              <b>Loading... </b>
            </div>
            <div className="message-attachment">
              <div className="document-in" />
              <div />
            </div>
          </div>
        ) : ( */}
        <GuestChatMessageComponent publicLink={publicLink} list={chatMessages} />
        {/* )} */}
        <div style={{ float: "left", clear: "both" }} ref={messageEnd}></div>
      </div>

      {url && url.length ? (
        <div className="feedback-content-attachments">
          {url.map((item: any, index: any) => {
            return (
              <div key={index} className="feedback-content-attachment-file">
                {item.type == "img" ? (
                  <div className="feedback-content-thumbnail-img">
                    <img id="imgPrev" alt="img" src={item.img} />
                  </div>
                ) : null}

                {item.type == "file" ? (
                  <div className="feedback-content-attach-icon">
                    <UploadFileIcon style={{ fontSize: "40px" }} />
                  </div>
                ) : null}

                {item.type == "video" ? (
                  <div className="feedback-content-attach-icon">
                    <VideoFileIcon style={{ fontSize: "40px" }} />
                  </div>
                ) : null}

                <div className="feedback-content-attachment-name">
                  {item.name}
                </div>
                <div
                  className="feedback-content-attachment-icon"
                  onClick={() => handelClose(index)}
                >
                  <i className="fa fa-times" />
                </div>
              </div>
            );
          })}
        </div>
      ) : null}

      <form noValidate className="ng-untouched ng-pristine ng-valid">
        <div className="send-message">
          <input
            autoComplete="off"
            onChange={(e) => handleMessageFiled(e.target.value)}
            className="form-control ng-untouched ng-pristine ng-valid"
            name="chatBox"
            placeholder="Type a message"
            type="text"
            pattern="/.*\S.*/"
            value={message}
          />
          <div className="action-message-button">
            <button
              className="fa fa-paperclip"
              mat-raised-button=""
              type="button"
              onClick={handleClick}
            />
            <input
              style={{ display: "none" }}
              ref={inputElement}
              id="upload-files"
              onChange={handleAttachment}
              type="file"
              multiple
            />
            <button
              className="fa fa-paper-plane"
              onClick={handleSend}
              style={{ background: "#003964" }}
              aria-hidden="true"
              type="submit"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default GuestChatBox;
