import { Room, TwilioError } from 'twilio-video';
import { useEffect } from 'react';

import { Callback } from 'types';
import { DeletePublicMeetingUsers } from 'actions';
import { useDispatch } from 'react-redux';

export default function useHandleRoomDisconnection(

  room: Room | null,
  onError: Callback,
  removeLocalAudioTrack: () => void,
  removeLocalVideoTrack: () => void,
  isSharingScreen: boolean,
  toggleScreenShare: () => void
) 

{
  useEffect(() => {
  
    if (room) {
      const onDisconnected = (_: Room, error: TwilioError) => {
        if (error) {
          onError(error);
        }

      

        removeLocalAudioTrack();
        removeLocalVideoTrack();
        if (isSharingScreen) {
          toggleScreenShare();
        }
      };

      room.on('disconnected', onDisconnected);
      return () => {
        room.off('disconnected', onDisconnected);
      };
    }
  }, [room, onError, removeLocalAudioTrack, removeLocalVideoTrack, isSharingScreen, toggleScreenShare]);
}

function dispatch(arg0: (dispatch: import("redux").Dispatch<import("redux").AnyAction>) => Promise<void>) {
  throw new Error('Function not implemented.');
}

