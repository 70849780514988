// @ts-nocheck
import React, { useEffect } from 'react';
import { Typography, Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Steps } from '../PreJoinScreens';
import { useAppState } from 'state';
import useVideoContext from 'hooks/useVideoContext/useVideoContext';
import { LocalVideoTrack } from 'twilio-video';

interface DeviceSelectionScreenProps {
  name: string;
  roomName: string;
  setStep: (step: Steps) => void;
}

export default function DeviceSelectionScreen({ name, roomName, setStep }: DeviceSelectionScreenProps) {
  const { getToken } = useAppState();
  const { connect: videoConnect, localTracks } = useVideoContext();
  const videoTrack = localTracks.find(
    track => !track.name.includes('screen') && track.kind === 'video'
  ) as LocalVideoTrack;
  const handleJoin = () => { 
    const chatUser = localStorage.getItem('ChatUser') ? JSON.parse(localStorage.getItem('ChatUser')) : '';
    const localUser = JSON.parse(localStorage.getItem('profile') ?? JSON.stringify(''));
    const roomData = JSON.parse(localStorage.getItem('RoomData') ?? JSON.stringify(''));
    
    getToken(chatUser ? chatUser.Value : localUser.FirstName, roomData.CurrentroomName).then(({ token }) => {
      videoConnect(token);
    });
  };

  useEffect(() => {
    if (videoTrack)
      handleJoin();
  }, [videoTrack])
  return (
    <Grid container justifyContent="center" alignItems="center" direction="column" style={{ height: '100%' }}>
      <div>
        <CircularProgress variant="indeterminate" />
      </div>
      <div>
        <Typography variant="body2" style={{ fontWeight: 'bold', fontSize: '16px' }}>
          Joining Meeting
        </Typography>
      </div>
    </Grid>
  );
}
