import React from 'react'
const ChatMessageComponent = ({ list }: any) => {
    const profile = JSON.parse(localStorage.getItem('profile') ?? JSON.stringify(''))
    if (!(Array.isArray(list)))
        return null;
    return list && list.map((item: any, index: number) => {
        // console.log(item.Size,'fruitefeyfeyf')
        if (item.IsCall) return <div
            key={index}
            className="message-line"
            style={{ opacity: 1 }}
        >
            <div className="join-user-message">
                <span>{item.CreatedDate} </span>
                <b> {item.Message}</b>
            </div>
            <div className="message-attachment">
                <div
                    className="document-in"
                />
                <div />
            </div>
        </div>
        /* -----https://api.smallworldmeet.com/ url added By vidushi--------- */
        else if (item.IsFile == 1) return profile.UserId == item.Sender_Id  ?
            <div key={index} className="message-line" style={{ opacity: 1 }}>
                <div className="message-in-right" />
                <div className="message-out-right">
                    <div className="group-user-name">
                        <span>{item.CreatedDate}</span>
                    </div>
                </div>
                <div className="message-attachment">
                    <div className="document-out">
                        {item.FileType == 2 ? (
                            
                            <div>
                                <div className="message-attachment-file">
                                    <a
                                        style={{ color: "black" }}
                                        target="_blank"
                                        href={`https://api.smallworldmeet.com/${item.FileUrl}`}
                                    >
                                        <div className="message-attachment-file-name">
                                            {item.FileName}
                                        </div>
                                        <div className="message-attachment-file-size">
                                            
                                            {`${(Number(item.Size) / (1024*1024)).toFixed(2)} MB`}
                                        </div>
                                        <div className="attached-file">
                                            <i className="fa fa-file-o" /> File
                                        </div>
                                    </a>
                                    <a target="_blank" className="message-attachment-file-download" 
                                    href={`https://api.smallworldmeet.com/${item.FileUrl}`} download>Download</a>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <div className="img-box">
                                    <a target="_blank" href={`https://api.smallworldmeet.com/${item.FileUrl}`}>
                                        <img src={`https://api.smallworldmeet.com/${item.FileUrl}`} />
                                    </a>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div> :
            <div key={index} className="message-line" style={{ opacity: 1 }}>
                <div className="message-in-left" />
                <div className="message-out-left">
                    <div className="group-user-name">
                        <span>{item.CreatedDate}</span>
                    </div>
                </div>
                <div className="message-attachment">
                    <div className="document-in">
                        {item.FileType == 2 ? (
                            <div>
                                <div className="message-attachment-file">
                                    <a
                                        style={{ color: "black" }}
                                        target="_blank"
                                        href={`https://api.smallworldmeet.com/${item.FileUrl}`}
                                    >
                                        <div className="message-attachment-file-name">
                                            {item.FileName}
                                        </div>
                                        <div className="message-attachment-file-size">
                                            {`${(Number(item.Size) / (1024*1024)).toFixed(2)} MB`}
                                        </div>
                                        <div className="attached-file">
                                            <i className="fa fa-file-o" /> File
                                        </div>
                                    </a>
                                    <a target="_blank" className="message-attachment-file-download" 
                                    href={`https://api.smallworldmeet.com/${item.FileUrl}`} download>Download</a>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <div className="img-box">
                                    <a target="_blank" href={`https://api.smallworldmeet.com/${item.FileUrl}`}>
                                        <img src={`https://api.smallworldmeet.com/${item.FileUrl}`} />
                                    </a>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        else return profile.UserId == item.Sender_Id ? <div
            key={index}
            className="message-line"
            style={{ opacity: 1 }
            }
        >
            <div className="message-in-right" />
            <div className="message-out">
                <div>{item.Message}</div>
            </div>
            <div className="message-attachment">
                <div
                    className="document-in"
                />
            </div>
        </div> : <div
            key={index}
            className="message-line"
            style={{ opacity: 1 }}
        >
            
            <div className="message-in-left" />
            <div className="message-in">
                <div>{item.Message}</div>
            </div>
            <div className="message-attachment">
                <div
                    className="document-in"
                />
            </div>
        </div>
    })
}

export default ChatMessageComponent