import React, { useEffect, useState } from 'react';
// import ScreenShareIcon from 'icons/ScreenShareIcon';
import useScreenShareParticipant from 'hooks/useScreenShareParticipant/useScreenShareParticipant';
import useVideoContext from 'hooks/useVideoContext/useVideoContext';
import PresentToAllRoundedIcon from '@mui/icons-material/PresentToAllRounded';
import CancelPresentationRoundedIcon from '@mui/icons-material/CancelPresentationRounded';
import eventBus from 'utils/helper/eventBus';
import { CONTACT_COLLAPSE } from 'constants/common-constants';

export const SCREEN_SHARE_TEXT = 'Share Screen';
export const STOP_SCREEN_SHARE_TEXT = 'Stop Sharing Screen';
export const SHARE_IN_PROGRESS_TEXT = 'Cannot share screen when another user is sharing';
export const SHARE_NOT_SUPPORTED_TEXT = 'Screen sharing is not supported with this browser';


export default function ToggleScreenShareButton(props: { disabled?: boolean }) {
  const screenShareParticipant = useScreenShareParticipant();

  const { toggleScreenShare } = useVideoContext();
  const disableScreenShareButton = Boolean(screenShareParticipant);
  const isScreenShareSupported = navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia;
  const isDisabled = !isScreenShareSupported;

  let tooltipMessage = '';

  if (disableScreenShareButton) {
    tooltipMessage = SHARE_IN_PROGRESS_TEXT;
  }

  if (!isScreenShareSupported) {
    tooltipMessage = SHARE_NOT_SUPPORTED_TEXT;
  }
 
  return (
    <div style={{ marginRight: "30px", textAlign: "center" }}>
      <button
        // className={classes.button}
        style={{
          backgroundColor: isDisabled ? 'red' : '#447334',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          marginLeft: '10px'
        }}
        onClick={toggleScreenShare}
        disabled={isDisabled}
        // startIcon={<ScreenShareIcon />}
        data-cy-share-screen
      >
        {props.disabled ? <CancelPresentationRoundedIcon /> : <PresentToAllRoundedIcon />}
      </button>
      <span style={{ display: "block" }}>
        {props.disabled ? STOP_SCREEN_SHARE_TEXT : SCREEN_SHARE_TEXT}
      </span>
    </div>
  );
}
