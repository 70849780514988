// @ts-nocheck
import React, { useState, useEffect, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ExitCallButton from "components/ui/Buttons/ExitCallButton/ExitCallButton";
import RaiseHand from "components/ui/Buttons/RaiseHand/RaiseHand"
import EndCallButton from "components/ui/Buttons/EndCallButton/EndCallButton";
import { isMobile } from "utils";
import useRoomState from "hooks/useRoomState/useRoomState";
import useVideoContext from "hooks/useVideoContext/useVideoContext";
import ToggleAudioButton from "components/ui/Buttons/ToggleAudioButton/ToggleAudioButton";
import ToggleVideoButton from "components/ui/Buttons/ToggleVideoButton/ToggleVideoButton";
import ToggleScreenShareButton from "components/ui/Buttons/ToogleScreenShareButton/ToggleScreenShareButton";
import "./buttonStyles.css";
import { ComponentRightSide } from "constants/common-constants";
import VideoCallRoundedIcon from "@mui/icons-material/VideoCallRounded";
import ToggleChatButton from "components/ui/Buttons/ToggleChatButton/ToggleChatButton";
import { useDispatch } from "react-redux";
import {
  getChatMessages,
  DeletePublicMeetingUsers,
  DeletePublicMeetingForAllUsers,
  RemoveUsersInCall,
  freebreakoutRoom,
  UpdateOnlineUsers,
  GetUsersInCall,
  GetOnlineUsers,
  RemoveAllParticipants,
  DeleteOnlineUser
} from "actions";
import LinkIcon from "@mui/icons-material/Link";
import { toastSuccess } from "services";
import { useNavigate } from "react-router-dom";
import ToggleIncomingCallButton from "components/ui/Buttons/ToggleIncomingCallButton/ToggleIncomingCallButton";
import { useSelector } from "react-redux";
import { contactCollapseEvent } from "utils/helper/eventBus";
import { SignalRContext } from "services/signal-r-service";
import { ConnectSignalREnums } from "constants/common-constants";
import {SET_TIMER,SET_RAISEHAND,REMOVE_RAISEHAND} from "constants/store-actions";
import Reacts from "components/ui/Buttons/React/Reacts";
import FloatingEmoji from "../LeftSide/FloatingHeart";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.default,
      height: `60px`,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "0 1.43em",
      [theme.breakpoints.down("sm")]: {
        height: `${theme.mobileFooterHeight}px`,
        padding: 0,
      },
    },
    screenShareBanner: {
      position: "fixed",
      zIndex: 8,
      bottom: `${theme.footerHeight}px`,
      left: 0,
      right: 0,
      height: "104px",
      background: "rgba(0, 0, 0, 0.5)",
      "& h6": {
        color: "white",
      },
      "& button": {
        background: "white",
        color: theme.brand,
        border: `2px solid ${theme.brand}`,
        margin: "0 2em",
        "&:hover": {
          color: "#600101",
          border: `2px solid #600101`,
          background: "#FFE9E7",
        },
      },
    },
    hideMobile: {
      display: "initial",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  })
);

interface Menu {
  hideContacts: any;
  setHideContacts: any;
  setHandleComponentRightSide: any;
  setToggleChatWindow: any;
  toggleVideoChat: any;
  componentRightSideDashboard: any;
  handleIncomingCallpopup?: any;
  setIsSeen?: any;
  isSeen?: any;
  handleCollapse?: any;
  isCollapse?: any;
}

const VideoMenu = ({
  hideContacts,
  setHideContacts,
  setHandleComponentRightSide,
  setToggleChatWindow,
  toggleVideoChat,
  componentRightSideDashboard,
  handleIncomingCallpopup,
  handleCollapse,
  isSeen,
  setIsSeen,
  isCollapse,
}: Menu) => {
  const classes = useStyles();
  const { isSharingScreen } = useVideoContext();
  const roomState = useRoomState();
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const [showNotification, setShowNotification] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showButton, setShowButton] = useState(false);
  const publicLink = searchParams.get("public");
  const Id = searchParams.get("id");
  const isReconnecting = roomState === "reconnecting";
  const Profile = JSON.parse(localStorage.getItem("profile"));
  const guestLink = publicLink
    ? "https://app.theauthenticconsortium/guest-meeting/get-together?public=true"
    : `https://app.theauthenticconsortium/guest-meeting/${localStorage.getItem(
        "roomNameLatest"
      )}?id=${Profile?.UserId ?? Id}`;
  const { room } = useVideoContext();
  const connection = useContext(SignalRContext);
  const Local_profile = JSON.parse(
    localStorage.getItem("profile") ?? JSON.stringify("")
  );
  
  const deletedData = {
    UserId: Local_profile.UserId,
    Status:1,
  }
  const handleRoomDisconnect = () => {
    setHandleComponentRightSide(ComponentRightSide.RightSide);
    room?.disconnect();
    if (localStorage.getItem("guest")) {
      const rememberme = JSON.parse(
        localStorage.getItem("rememberme") || JSON.stringify("")
      );
      if(rememberme){
        const keyToKeep = 'password';
        const keyToKeep2 = 'rememberme';
        for (let key in localStorage) {
          if (localStorage.hasOwnProperty(key) && key !== keyToKeep && key !== keyToKeep2 ) {
            localStorage.removeItem(key);
          }
        }
      }
      else{
        localStorage.clear()
      }
    }
    const payload = {
      PublicGuestId: Local_profile.UserId,
    };
    // dispatch(DeletePublicMeetingUsers(payload));
    if (publicLink) {
      navigate("/");

      dispatch(DeletePublicMeetingUsers(payload));
      const payloadOnlineUser =
      {
        UserId: Local_profile.UserId,
      }  
	  dispatch(DeleteOnlineUser(payloadOnlineUser));
    }
    setHideContacts(true);
    navigate("/home");
    const Profile = JSON.parse(localStorage.getItem("RoomData") || "");
    const SingleUser = {
      UserInCall: Profile.UserInCall,
      dataType: ConnectSignalREnums.CallEnd,
    };
    if (connection) {
      connection.send("DataInSignalR", JSON.stringify(SingleUser));
    }
    dispatch(RemoveUsersInCall(deletedData));
    dispatch(UpdateOnlineUsers(deletedData))
    
    dispatch(GetOnlineUsers())
  
    dispatch(GetUsersInCall());

    dispatch(DeletePublicMeetingUsers(payload));
  };

  const handleEndEntireCall = () => {
    setHandleComponentRightSide(ComponentRightSide.RightSide);
    room?.disconnect();
    setHideContacts(true);
    setShowButton(true);
    toastSuccess("Remove User in Call !")
    localStorage.setItem("leaveRoom", false);
    localStorage.setItem("joinRoom", false);
    dispatch(RemoveAllParticipants());
    dispatch(freebreakoutRoom());
    dispatch({ type: SET_TIMER, payload: 0 });
    dispatch(RemoveUsersInCall(deletedData));
    dispatch(UpdateOnlineUsers(deletedData))
    dispatch(GetUsersInCall());
    dispatch(GetOnlineUsers())
    const RoomData = JSON.parse(
      localStorage.getItem("RoomData") || JSON.stringify("")
    );
    const Local_profile = JSON.parse(
      localStorage.getItem("profile") ?? JSON.stringify("")
    );
    if (publicLink) {
      navigate("/");
      const payload = {
        PublicGuestId: Local_profile.UserId,
      };
      dispatch(DeletePublicMeetingUsers(payload));
      const payloadOnlineUser =
      {
        UserId: Local_profile.UserId,
      }  
	  dispatch(DeleteOnlineUser(payloadOnlineUser));
    }
  
    // dispatch(DeletePublicMeetingForAllUsers(payload));

    // dispatch(RemoveUsersInCall(deletedData))
    dispatch(GetUsersInCall());
    const SingleUser = {
      Roomid: RoomData.CurrentroomName,
      RoomName:room?.name,
      dataType: ConnectSignalREnums.EndEntireCall,
      Name: Local_profile.FirstName,
    };
    if (publicLink) {
      setHandleComponentRightSide(ComponentRightSide.RightSide);
      room?.disconnect();

      if (connection) {
        connection.send("DataInSignalR", JSON.stringify(SingleUser));
  
        localStorage.removeItem("RoomData");
        navigate("/home");
      } else {
        console.log("Connection is not in the 'Connected' state.");
      }
    }
    if (connection) {
      connection.send("DataInSignalR", JSON.stringify(SingleUser));

      localStorage.removeItem("RoomData");
      navigate("/home");
    } else {
      console.log("Connection is not in the 'Connected' state.");
    }

    dispatch(RemoveUsersInCall(deletedData));
    dispatch(GetUsersInCall());
  };

  const { guestUser, publicOngoingCallUser } = useSelector(
    (state) => state.guest
  );

  useEffect(() => {
    if (guestUser.length) {
      setShowNotification(true);
      setIsSeen(true);
    }
  }, [guestUser]);

  const backToCall = () => {
    setHandleComponentRightSide(ComponentRightSide.RoomComponent);
  };

  const toggleContacts = () => {
    contactCollapseEvent(!hideContacts);
    setHideContacts(!hideContacts);
  };

  const toggleChatWindow = () => {
    const profile = JSON.parse(
      localStorage.getItem("chatUser") ?? JSON.stringify("")
    );
    let chatRoomId = profile.ChatRoomId;
    if (room)
      chatRoomId = JSON.parse(
        localStorage.getItem("RoomData") ?? JSON.stringify("")
      ).ChatRoomId;
    dispatch(
      getChatMessages({
        Reciver_Id: profile.Key,
        GuestId: 0,
        ChatRoomId: chatRoomId,
        IsRoom: 0,
      })
    );
    setToggleChatWindow();
    contactCollapseEvent();
  };

  const handleCopyGuestLink = () => {
    navigator.clipboard.writeText(guestLink);
    toastSuccess("Meeting Link Copied Successfully.");
  };
const handleRaiseHand =()=>{
  dispatch({ type: SET_RAISEHAND, payload: room.localParticipant.sid });
  toastSuccess("Raised Hand")
  const RoomData = JSON.parse(
    localStorage.getItem("RoomData") || JSON.stringify("")
  );
  const profile = JSON.parse(
    localStorage.getItem("profile") || JSON.stringify("")
  );
  const SingleUser = {
    sid:room.localParticipant.sid,
    UserId : profile.UserId,
    Roomid: RoomData.CurrentroomName,
    RoomName:room?.name,
    dataType: ConnectSignalREnums. RaiseHand,
    Name: Local_profile.FirstName,
  };
  if (connection) {
    connection.send("DataInSignalR", JSON.stringify(SingleUser));
  } else {
    console.log("Connection is not in the 'Connected' state.");
  }
}
const handleDownHand =()=>{
  dispatch({ type: REMOVE_RAISEHAND, payload: room.localParticipant.sid });
  toastSuccess("Down Hand")
  const RoomData = JSON.parse(
    localStorage.getItem("RoomData") || JSON.stringify("")
  );
  const profile = JSON.parse(
    localStorage.getItem("profile") || JSON.stringify("")
  );
  const SingleUser = {
    sid:room.localParticipant.sid,
    UserId : profile.UserId,
    Roomid: RoomData.CurrentroomName,
    RoomName:room?.name,
    dataType: ConnectSignalREnums. DownRaiseHand,
    Name: Local_profile.FirstName,
  };
  if (connection) {
    connection.send("DataInSignalR", JSON.stringify(SingleUser));
  } else {
    console.log("Connection is not in the 'Connected' state.");
  }
}

  return (
    <>
      {/* {isSharingScreen && (
                <Grid container justifyContent="center" alignItems="center" className={classes.screenShareBanner}>
                    <Typography variant="h6">You are sharing your screen</Typography>
                    <Button onClick={() => toggleScreenShare()}>Stop Sharing</Button>
                </Grid>
            )} */}
      {room ? (
        <footer className={classes.container} style={{ width: '100%', justifyContent: publicLink ? 'center' : 'space-between' }}>
          <button
            style={
              publicLink
                ? {
                    position: "absolute",
                    left: 0,
                    marginRight: "1000px !important",
                  }
                : {}
            }
            className="btn fw-bold contactControllerButton"
            onClick={publicLink || Id ? handleCollapse : toggleContacts}
          >
            {" "}
            {!isCollapse && !hideContacts ? "< Collapse" : "> Expand"} Contacts
          </button>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            
            }}
          >
            {componentRightSideDashboard ===
            ComponentRightSide.RoomComponent ? (
              <>
                <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position:'relative'
            }}
          >
                <Reacts connection ={connection}/>
               {/* <FloatingEmoji/> */}
               <RaiseHand handleRaiseHand={handleRaiseHand} handleDownHand ={handleDownHand}/>
             
                {showNotification ? (
                  <ToggleIncomingCallButton
                    isSeen={isSeen}
                    handleIncomingCallpopup={handleIncomingCallpopup}
                  />
                ) : null}
                {/* <ToggleAudioButton disabled={isReconnecting} />  */}
                <ToggleVideoButton disabled={isReconnecting} />
                {!isMobile && (
                  <ToggleScreenShareButton disabled={isSharingScreen} />
                )}
                <ExitCallButton handleDisconnect={handleRoomDisconnect} />
                {true ? (
                  <ToggleChatButton
                    toggleVideoChat={toggleVideoChat}
                    toggleChatWindow={toggleChatWindow}
                  />
                ) : null}

                <EndCallButton handleEndEntireCall={handleEndEntireCall} />
                </div>
              </>
            ) : showButton == "false" ? (
              <button
                style={{
                  backgroundColor: "#447334",
                  color: "white",
                  border: "none",
                  borderRadius: "5px",
                  marginLeft: "10px",
                }}
                onClick={backToCall}
              >
                <span style={{ marginLeft: "3px" }}>
                  <VideoCallRoundedIcon /> Back To Call
                </span>
              </button>
            ) : null}
               <div style={{ position: "absolute", right: 10 }}>
                  <input
                    style={{ margin: "1px 5px" }}
                    disabled
                    value={guestLink}
                  />
                  <LinkIcon
                    style={{ fontSize: "30px", cursor: "pointer" }}
                    onClick={handleCopyGuestLink}
                  />
                </div>
          </div>
          <div></div>
        </footer>
      ) : null}
    </>
  );
};

export default VideoMenu;
