import React, { useState, useEffect, FormEvent } from 'react';
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';
import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar';
import { useAppState } from 'state';
import { useParams } from 'react-router-dom';
import useVideoContext from 'hooks/useVideoContext/useVideoContext';
import { useSelector } from 'react-redux';

export enum Steps {
  roomNameStep,
  deviceSelectionStep,
}

export default function PreJoinScreens() {
  const { user } = useAppState();
  const { getAudioAndVideoTracks } = useVideoContext();
  const { URLRoomName } = useParams<{ URLRoomName?: string }>();
  const [step, setStep] = useState(Steps.roomNameStep);

  const [name, setName] = useState<string>(JSON.parse(localStorage.getItem('profile') ?? JSON.stringify(''))?.FirstName);
  const [roomName, setRoomName] = useState<string>(JSON.parse(localStorage.getItem('RoomData') ?? JSON.stringify(''))?.CurrentroomName);

  const [mediaError, setMediaError] = useState<Error>();
  const room = useSelector((state: any) => state)

  useEffect(() => {
    if (step === Steps.deviceSelectionStep && !mediaError) {
      getAudioAndVideoTracks().catch(error => {
        console.log('Error acquiring local media:');
        console.dir(error);
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, step, mediaError]);


  useEffect(() => {
    if (!window.location.origin.includes('twil.io')) {
      // window.history.replaceState(null, '', window.encodeURI(`/room/${roomName}${window.location.search || ''}`));
    }
    setStep(Steps.deviceSelectionStep);
  }, [])

  return (
    <div className='bg-dark' style={{ display: 'grid', placeItems: 'center' }}>
      <MediaErrorSnackbar error={mediaError} />
      {step === Steps.roomNameStep && (
        null
      )}

      {step === Steps.deviceSelectionStep && (
        <DeviceSelectionScreen name={name} roomName={roomName} setStep={setStep} />
      )}
    </div>
  );
}
