import React, { useContext, useEffect, useState } from "react";
import RoomComponent from "components/VideoCallFunctionality/Video/Video";
import GuestNameModal from "features/Modal/GuestName";
import VideoMenu from "components/Dashboard/VideoMenu/VideoMenu";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { SignalRContext } from "services/signal-r-service";
import { SignalREventsEnums } from "constants/signalR-events";
import { PushNotification } from "services/PushService";
import { INSERT_MESSAGE } from "constants/store-actions";
import { ComponentRightSide } from "constants/common-constants";
import CallPopup from "./Popup/CallPopup";
import { v4 as uuidv4 } from "uuid";
import GuestCallRejectedPopup from "./Popup/GuestCallRejectedPopup";
import LeftSide from "./LeftSide";
import useParticipantsContext from "hooks/useParticipantsContext/useParticipantsContext";
import useVideoContext from "hooks/useVideoContext/useVideoContext";
import { InsertPublicMeetingUsers,RemoveUsersInCall ,UpdateOnlineUsers,GetOnlineUsers, GetUsersInCall,DeletePublicMeetingUsers} from "actions";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// import room
const GuestMeetingComponent = () => {
  const navigate = useNavigate();
  const [join, setJoin] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const [handleComponentRightSide, setHandleComponentRightSide] = useState(ComponentRightSide.RightSide);
  const [isSchedule, setIsSchedule] = useState(false);
  const [name, setName] = useState("");
  const [List, setList] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const dispatch: any = useDispatch();
  const connection = useContext(SignalRContext);
  const [toggleVideoChat, setToggleVideoChat] = useState(false);
  const { galleryViewParticipants } = useParticipantsContext();
  const [isCollapse, setIsCollapse] = useState(false);
  const { room } = useVideoContext();
  useEffect(() => {
    const users: any = [];
    galleryViewParticipants.map((item) => {
      users.push({ name: item.identity });
    });
    setList(users);
  }, [galleryViewParticipants]);

  const handleVideoChatWindow = () => {
    setToggleVideoChat(!toggleVideoChat);
  };

  const handleCollapse = () => {
    setIsCollapse(!isCollapse);
  };

  const addPushNotification = (data: any) => {
    const payload: any = {
      title: `New message from ${data.SenderName}`,
      message: data.Message,
      theme: "darkblue",
    };
    return PushNotification(payload);
  };
  const Local_callEnd = JSON.parse(
    localStorage.getItem("callEnd") ?? JSON.stringify("")
  );
 
  useEffect(() => {
    
    if (Local_callEnd == true )  {
        room?.disconnect();      
    }
  }, [Local_callEnd]);



  useEffect(() => {
    if (connection) {
      connection.on("DataInSignalR", async (data: any) => {
        if (data) {
          // @ts-ignore
          const profile = JSON.parse(localStorage.getItem("profile"));
          const GuestUser = JSON.parse(localStorage.getItem("guestUser") || "");
          const loginId = profile.UserId;
          let result;
          result = JSON.parse(data);

          if (result && result.length) {
            if (result[0].dataType == SignalREventsEnums.UserInChat) {
              if (result[0].Reciver_Id == loginId) {
                // push notificaton
                addPushNotification(result[0]);
                dispatch({ type: INSERT_MESSAGE, payload: result[0] });
              }
            }
            
          }


          if (result.dataType == SignalREventsEnums.EndEntireCall) {
            room?.disconnect();
            console.log("------ENTIRE-CALL-END-----");
            const Local_GuestChatRoom = JSON.parse(
              localStorage.getItem("guestUser") ?? JSON.stringify("")
            ); 

            const Local_RoomData = JSON.parse(
              localStorage.getItem("RoomData") ?? JSON.stringify("")
            );
            const Local_profile = JSON.parse(
              localStorage.getItem("profile") ?? JSON.stringify("")
            );
            const deletedData = {
              UserId: Local_profile.UserId,
              Status:1,
            }
             console.log("------Remove-ENTIRE-CALL-END-----");
            dispatch(RemoveUsersInCall(deletedData))
            dispatch(UpdateOnlineUsers(deletedData))
            dispatch(GetUsersInCall())
            dispatch(GetOnlineUsers())
            localStorage.clear(); 
            if (result.Roomid == Local_RoomData?.CurrentroomName || result.RoomName == room?.name ) {           
              room?.disconnect();
              localStorage.removeItem("RoomData");
              localStorage.removeItem("User");
              localStorage.removeItem("roomNameLatest");
              localStorage.clear(); 
              
              const Local_profile = JSON.parse(
                localStorage.getItem("profile") ?? JSON.stringify("")
              );
              const payload = {
                PublicGuestId: Local_profile.UserId
              }
              dispatch(
                DeletePublicMeetingUsers(payload))
                toast.dismiss();                        
                toast(`Call Ended by ${result.Name}`);  
          
              window.location.reload();  
              navigate("/home");   
                                  
            }
            if (result.Roomid == Local_GuestChatRoom.roomId) {
              room?.disconnect();
              localStorage.clear(); 
              localStorage.removeItem("guestUser");  
              localStorage.removeItem("User");  
              localStorage.removeItem("roomNameLatest");  
              localStorage.clear(); 
              
              window.location.reload(); 
              toast.dismiss();                        
              toast(`Call Ended by ${result.Name}`);                          
            }
          } 



          if (result.dataType == SignalREventsEnums.GuestCallAccept) {
            console.log("----- CALL IS ACCEPTED -----");
            if (result.uuid == GuestUser.uuid) {
              setJoin(true);
            }
          }
          if (result.dataType == SignalREventsEnums.GuestCallRejected) {
            console.log("----- CALL IS REJECTED -----");
            if (result.uuid == GuestUser.uuid) {
              setShowPopup(true);
            }
          }
          if (result.dataType == SignalREventsEnums.GuestInChat) {
            console.log("GUEST_IN_CHAT");
           
          }
          if (result.dataType == SignalREventsEnums.UserInBreakoutCall) {
            console.log("breakout");
         
          }
        }
      });
    }
  }, [connection]);

  useEffect(() => {
    if (!localStorage.getItem("start")) {
      localStorage.clear();
      localStorage.setItem(
        "profile",
        JSON.stringify({ UserId: uuidv4(), FirstName: "", LastName: "" })
      );
      localStorage.setItem("start", "true");
      window.location.reload();
    }
  }, []);

  
  return (
    <div className="page-area">
      <div className="container-fluid">
        <div className="page-section">
          <div className="home-tab-content">
            <div className="main-content-area">
              {/* <div className="smallworld-cafe-title m-0">
                
              </div> */}
              {name && !join && !showPopup ? <CallPopup /> : null}
              <div
                className={clsx(
                  `search-page-group collab-coffee-search-page-group mt-2 p-0`,
                  name
                    ? " "
                    : "d-flex justify-content-center flex-column align-items-center h-100"
                )}
              >
                
                {join || isPublic || isSchedule? (
                  <>
                    <VideoMenu
                      hideContacts={false}
                      setHideContacts={(value: any) => {}}
                      setHandleComponentRightSide={(value: any) => {}}
                      setToggleChatWindow={handleVideoChatWindow}
                      toggleVideoChat={toggleVideoChat}
                      handleCollapse={handleCollapse}
                      isCollapse={isCollapse}
                      componentRightSideDashboard={
                        ComponentRightSide.RoomComponent
                      }
                    />
                    {isCollapse ? (
                      <RoomComponent toggleVideoChat={toggleVideoChat} />
                    ) : (
                      <div className="search-page-group collab-coffee-search-page-group mt-2">
                        <LeftSide List={List} />
                        <RoomComponent toggleVideoChat={toggleVideoChat} />
                      </div>
                    )}
                  </>
                ) : (
                  <GuestNameModal setIsPublic={setIsPublic} setName={setName} setIsSchedule={setIsSchedule}/>
                )}
              </div>
              {showPopup ? <GuestCallRejectedPopup /> : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuestMeetingComponent;
