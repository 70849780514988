import addNotification from "react-push-notification";

declare type Color = 'light' | 'darkblue' | 'red' | undefined;
declare type voidFunc = () => void;
declare type eventFunc = (e: any) => void;
declare type onClickType = voidFunc | eventFunc | undefined;

interface NotificationDataInterface {
    title: string;
    subtitle?: string;
    message?: string;
    onClick?: onClickType;
    theme?: Color;
    duration?: number;
    backgroundTop?: string;
    backgroundBottom?: string;
    colorTop?: string;
    colorBottom?: string;
    closeButton?: JSX.Element | string;
    native?: boolean;
    icon?: string;
    vibrate?: number | number[];
    silent?: boolean;
};

export const PushNotification = (data: NotificationDataInterface) => {
    return addNotification({
        ...data,
        native: true // when using native, your OS will handle theming.
    });
}