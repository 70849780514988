import { Link } from "react-router-dom"


const Footer = () => {
    return (
        <div>

            <div className="page-footer">
                <Link to="/privacy-policy"> © Privacy Policy</Link>
            </div>
        </div>
    )
}

export default Footer
