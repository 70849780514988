// @ts-nocheck
import React, { useEffect, useState, } from "react";
import ArrowBack from "@material-ui/icons/ArrowBack";
import ArrowForward from "@material-ui/icons/ArrowForward";
import clsx from "clsx";
import { GALLERY_VIEW_ASPECT_RATIO, SET_MUTED_PARTICIPANT } from "constants";
import { IconButton, makeStyles, createStyles, Theme } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import Participant from "../Participant/Participant";
import useGalleryViewLayout from "hooks/useGalleryViewLayout/useGalleryViewLayout";
import useVideoContext from "hooks/useVideoContext/useVideoContext";
import useParticipantsContext from "hooks/useParticipantsContext/useParticipantsContext";
import { usePagination } from "./usePagination/usePagination";
import useDominantSpeaker from "hooks/useDominantSpeaker/useDominantSpeaker";
import { useAppState } from "state";
import { Responsive, WidthProvider } from "react-grid-layout";
import MicOffRoundedIcon from "@mui/icons-material/MicOffRounded";
import MicRoundedIcon from "@mui/icons-material/MicRounded";
import { useDispatch, useSelector } from "react-redux";
import Draggable from "react-draggable";
import { useContext } from "react";
import useLocalAudioToggle from "hooks/useLocalAudioToggle/useLocalAudioToggle";
import { CONTACT_COLLAPSE } from "constants/common-constants";
import eventBus from "utils/helper/eventBus";
import MainParticipant from "../MainParticipant/MainParticipant";
import BackHandIcon from '@mui/icons-material/BackHand';
import useScreenShareParticipant from "hooks/useScreenShareParticipant/useScreenShareParticipant";
import {
  ComponentRightSide,
  ConnectSignalREnums,
} from "constants/common-constants";
import { contactCollapseEvent } from "utils/helper/eventBus";
import { SignalRContext } from "services/signal-r-service";
import { InsertUsersInCall, GetUsersInCall, updatedUserStatus, GetOnlineUsers, UpdateOnlineUsers } from 'actions'

const ReactGridLayout = WidthProvider(Responsive);
const CONTAINER_GUTTER = "50px";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      // background: theme.galleryViewBackgroundColor,
      position: "relative",
      gridArea: "1 / 1 / 2 / 3",
      overflowY: "scroll",
    },
    participantContainer: {
      // position: "absolute",
      display: "flex",
      top: CONTAINER_GUTTER,
      right: CONTAINER_GUTTER,
      bottom: CONTAINER_GUTTER,
      left: CONTAINER_GUTTER,
      margin: "0 auto",
      alignContent: "center",
      flexWrap: "wrap",
      justifyContent: "center",
    },
    buttonContainer: {
      position: "absolute",
      top: 0,
      bottom: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    buttonContainerLeft: {
      right: `calc(100% - ${CONTAINER_GUTTER})`,
      left: 0,
    },
    buttonContainerRight: {
      right: 0,
      left: `calc(100% - ${CONTAINER_GUTTER})`,
    },
    pagination: {
      "& .MuiPaginationItem-root": {
        color: "white",
      },
    },
    paginationButton: {
      color: "black",
      background: "rgba(255, 255, 255, 0.8)",
      width: "v0px",
      height: "40px",
      "&:hover": {
        background: "rgba(255, 255, 255)",
      },
    },
    paginationContainer: {
      position: "absolute",
      top: `calc(100% - ${CONTAINER_GUTTER})`,
      right: 0,
      bottom: 0,
      left: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  })
);

export function GalleryView({ isGalleryViewActive }) {
  const classes = useStyles();
  const connection = useContext(SignalRContext);

  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const { maxGalleryViewParticipants } = useAppState();
  const { room } = useVideoContext();
  const { galleryViewParticipants } = useParticipantsContext();
  const dominantSpeaker = useDominantSpeaker(true);
  const dispatch: any = useDispatch();
  const [collapse, setCollapse] = useState(true);
  const { isSharingScreen } = useVideoContext();
  const screenShareParticipant = useScreenShareParticipant();
  const ProfileData = JSON.parse(
    localStorage.getItem("profile") || JSON.stringify("")
  );
  const roomData = JSON.parse(localStorage.getItem('RoomData') ?? JSON.stringify(''));
  const { raiseHandId } = useSelector((state: any) => state.userStatusReducer);
  
  useEffect(() => {
    const payload = {
      FName: ProfileData.FirstName,
      LName: ProfileData.LastName,
      Email: ProfileData.Email,
      RoomName: roomData.CurrentroomName,
      UserId: ProfileData.UserId,
      dataType: ConnectSignalREnums.IsBusy,
    }
    if (connection) {
      connection.send("DataInSignalR", JSON.stringify({ ...payload, IsBusyUser: 1 }));
    } else {
      console.log("Connection is not in the 'Connected' state.");
    }
    dispatch(InsertUsersInCall(payload))
    const payloadStatus = {
      Status: 2,
      UserId: ProfileData.UserId,
    }
    dispatch(UpdateOnlineUsers(payloadStatus))
    dispatch(GetUsersInCall(payload))
    dispatch(GetOnlineUsers())
  }, [])
  useEffect(() => {
    dispatch(GetUsersInCall())
    dispatch(GetOnlineUsers())
  }, [])

  const { paginatedParticipants, setCurrentPage, currentPage, totalPages } =
    usePagination([room!.localParticipant, ...galleryViewParticipants]);

  const [paginateParticipant, setPaginateParticipant] = useState([
    ...galleryViewParticipants,
  ]);
  const { breakroom } = useSelector((state: any) => state.room);
  const [callingParticipants, setCallingParticipants] = useState([room!.localParticipant, ...paginateParticipant])
  const [handleShareScreen, setHandleShareScreen] = useState(false);
  // const [ShareScreen, setShareScreen] = useState(false);

  const galleryViewLayoutParticipantCount =
    currentPage === 1
      ? paginatedParticipants.length
      : maxGalleryViewParticipants;
  const { participantVideoWidth, containerRef } = useGalleryViewLayout(
    galleryViewLayoutParticipantCount
  );

  const participantWidth = `${participantVideoWidth}px`;
  const participantHeight = `${Math.floor(
    participantVideoWidth * GALLERY_VIEW_ASPECT_RATIO
  )}px`;

  const [muteParticipant, setMuteParticipant] = useState([]);

  const handleMutedLoginParticipant = () => {
    toggleAudioEnabled();
  };

  const handleMutedParticipant = (sid: string) => {
    const finder = muteParticipant.findIndex((item) => item == sid);
    let immutableList = [...muteParticipant];
    if (finder != -1) {
      immutableList.splice(finder, 1);
    } else {
      immutableList.push(sid);
    }
    dispatch({
      type: SET_MUTED_PARTICIPANT,
      payload: { name: "mute-particpant", value: immutableList },
    });
    setMuteParticipant(immutableList);
  };
/*-----screenShare-GridView done By vidushi---------*/
  const handleGrid = (index: number, screenShare: boolean) => {
    return {
      x: screenShare && index == 0 ? 0 : screenShare ? 600 : (index % 2) * 400,
      y: screenShare && index == 0 ? 0 : screenShare ? (index == 1 ? 0 : (index -1) * 15) : Math.floor(index / 2) * 31,
      w: screenShare && index == 0 ? 600 : screenShare ? 100 : 400,
      h: screenShare && index == 0 ? 40 : screenShare ? 10 : 25,
    };
  };

useEffect(()=>{
 contactCollapseEvent();
},[handleShareScreen])
  const breakOutRooms = (breakroom) => {
    let allParticipantsList = [room!.localParticipant, ...galleryViewParticipants];
    allParticipantsList = allParticipantsList.filter(item => item.sid !== room!.localParticipant.sid);
    allParticipantsList = [...allParticipantsList, room!.localParticipant]
    let handleNoRoomSelected = true;
    breakroom.map(item => {
      if (!item.length) {
        handleNoRoomSelected = false;
      }
      return item;
    })
    if (!handleNoRoomSelected) {
      setCallingParticipants([...allParticipantsList]);
      return;
    }
    let currentRoom = 0;
    breakroom.map((rooms, value: number) => {
      rooms.map((item: any, index: number) => {
        if (item?.SID == room!.localParticipant?.sid) {
          currentRoom = value;
        }
        return item;
      })
      return rooms;
    })

    let currentRoomParticipant = [];
    allParticipantsList.map((item: any) => {
      if (breakroom[currentRoom].filter(value => value?.SID == item?.sid).length) {
        currentRoomParticipant = [...currentRoomParticipant, item];
      }
      return item;
    })
    console.log("BRAKDJFSKDLFJ ", breakroom)
    return [...currentRoomParticipant];
  }

  useEffect(() => {
    eventBus.on(CONTACT_COLLAPSE, (data: boolean) => {
      setCollapse(false);
      setTimeout(() => {
        setCollapse(true);
      }, 500);
    });
  }, []);
  

  useEffect(() => {
    if (breakroom && breakroom?.length > 0) {
      const currentRoomParticipant = breakOutRooms(breakroom);
      setCallingParticipants(callingParticipants[0]?.mainParticipant ? [{ mainParticipant: true }, ...currentRoomParticipant] : [...currentRoomParticipant]);
    }
    else setCallingParticipants([room!.localParticipant, ...galleryViewParticipants]);
  }, [galleryViewParticipants.length])



  useEffect(() => {
    if (breakroom && breakroom?.length > 0) {
      const currentRoomParticipant = breakOutRooms(breakroom);
      setCallingParticipants([...currentRoomParticipant]);
    }
  }, [breakroom])


  const handleCallSend = (user: any) => {
    if (!user) return;
    const profile = JSON.parse(
      localStorage.getItem("profile") ?? JSON.stringify("")
    );
    let SingleUser = {
      UserInCall: user.Key,
      loginUserId: `${profile.UserId}-${user.Key}`,
      CurrentroomName: room ? localStorage.getItem("roomNameLatest") : uuidv4(),
      IsRoom: true,
      Name: profile.FirstName + " " + profile.LastName,
      callingName: profile.FirstName + " " + profile.LastName,
      dataType: ConnectSignalREnums.UserInCall,
      ChatRoomId: user.ChatRoomId,
    };
    localStorage.setItem("chatUser", JSON.stringify(user));
    if (connection) {
      console.log("Calling Event Sent.");
      connection.send("DataInSignalR", JSON.stringify(SingleUser));
      localStorage.setItem("RoomData", JSON.stringify(SingleUser));
      if (!room) sendThirdPersonCallResponse(SingleUser);
    }
  };

  const onDrop = (layout, layoutItem, _event) => {
    const CurrentUser = localStorage.getItem("SelectedUser");
    handleCallSend(JSON.parse(CurrentUser || ""));
  };
  const filteredParticipants = callingParticipants.filter((participant) => {
    const participantSid = participant.sid || "";
    return raiseHandId.includes(participantSid);
  });


  useEffect(() => {
    let currentRoomParticipant = [...paginateParticipant, room!.localParticipant];
  
    if (breakroom && breakroom.length) {
      currentRoomParticipant = breakOutRooms(breakroom);
  
      if (
        screenShareParticipant &&
        screenShareParticipant !== room!.localParticipant &&
        currentRoomParticipant &&
        currentRoomParticipant.length &&
        currentRoomParticipant.some(value => value.sid == screenShareParticipant.sid)
      ) {
        setCollapse(false);
        setTimeout(() => {
          setCollapse(true);
        }, 500);
        setHandleShareScreen(true);
      } else {
        setHandleShareScreen(false);
      }
    } else {
      if (
        screenShareParticipant &&
        screenShareParticipant !== room!.localParticipant &&
        currentRoomParticipant &&
        currentRoomParticipant.length &&
        currentRoomParticipant.some(value => value.sid != screenShareParticipant.sid)
      ) {
        setCollapse(false);
        setTimeout(() => {
          setCollapse(true);
        }, 500);
        setHandleShareScreen(true);
      } else {
        setHandleShareScreen(false);
      }
    }
  }, [screenShareParticipant, JSON.stringify(room), breakroom]);
 

  useEffect(() => {
    setCollapse(false);
    setTimeout(() => {
      setCollapse(true);
    }, 500);
  }, [callingParticipants.length])


  return (
    <div className={classes.container}>
      <div
        className={clsx(classes.buttonContainer, classes.buttonContainerLeft)}
      >
        {!(currentPage === 1) && (
          <IconButton
            className={classes.paginationButton}
            onClick={() => setCurrentPage((page) => page - 1)}
          >
            <ArrowBack />
          </IconButton>
        )}
      </div>
      <div
        className={clsx(classes.buttonContainer, classes.buttonContainerRight)}
      >
        {!(currentPage === totalPages) && (
          <IconButton
            className={classes.paginationButton}
            onClick={() => setCurrentPage((page) => page + 1)}
          >
            <ArrowForward />
          </IconButton>
        )}
      </div>
      <div className={classes.paginationContainer}>
        {totalPages > 1 && (
          <Pagination
            className={classes.pagination}
            page={currentPage}
            count={totalPages}
            onChange={(_, value) => setCurrentPage(value)}
            shape="rounded"
            color="primary"
            size="small"
            hidePrevButton
            hideNextButton
          />
        )}
      </div>
      <div className={classes.participantContainer} ref={containerRef}>
        {collapse ? (
          <ReactGridLayout
            className="w-100 h-100"
            allowOverlap={true}
            rowHeight={1}
            breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
            cols={{ lg: 800, md: 800, sm: 800, xs: 800, xxs: 800 }}
            resizeHandle={
              <p className="react-resizable-handle" style={{ display: "grid" }}>
                <span> </span>
                Resize
              </p>
            }
            onDrop={onDrop}
            isDroppable={true}
          >
            {(handleShareScreen ? [{ mainParticipant: true }, ...callingParticipants] : [...callingParticipants])?.map((participant, index) => {
              return participant.mainParticipant ? (
                <div
                  key={index}
                  className="mainParticipantGrid"
                  data-grid={handleGrid(index, Boolean(handleShareScreen))}
                  style={{
                    width: "100%",
                    height: '400px',
                    position: "relative",
                    zIndex: 999999,
                  }}
                >
                  <MainParticipant />
                </div>
              ) : (
                <div
                  key={participant.sid}
                  data-grid={handleGrid(index, Boolean(handleShareScreen))}
                  style={{
                    width: participantWidth,
                    height: participantHeight,
                    position: "relative",
                    zIndex: 9999,
                  }}
                >
                  {/* -----Raise Hand show near Mute Button done By vidushi--------- */}
                   {raiseHandId && raiseHandId.length && filteredParticipants.some((p) => p.sid === participant.sid)  ? (
                    <button
                      // onClick={() => {
                      //   if (participant === room!.localParticipant) {
                      //     handleMutedLoginParticipant();
                      //   } else {
                      //     handleMutedParticipant(participant.sid);
                      //   }
                      // }}
                      style={{
                        position: "absolute",
                        top: "5px",
                        right: "50px",
                        padding: "5px",
                        borderRadius: "10px",
                        backgroundColor: "gray",
                        zIndex: 99991,
                        cursor: "pointer",
                        border: "none",
                      }}
                    >
                        <BackHandIcon className="muted-audio" style={{ color: "red" }} />
                    </button>
                  ) :null}


                  {true ? (
                    <button
                      onClick={() => {
                        if (participant === room!.localParticipant) {
                          handleMutedLoginParticipant();
                        } else {
                          handleMutedParticipant(participant.sid);
                        }
                      }}
                      style={{
                        position: "absolute",
                        top: "5px",
                        right: "5px",
                        padding: "5px",
                        borderRadius: "10px",
                        backgroundColor: "gray",
                        zIndex: 99991,
                        cursor: "pointer",
                        border: "none",
                      }}
                    >
                      {muteParticipant.filter((item) => item === participant.sid).length ||
                        (participant === room!.localParticipant && !isAudioEnabled) ? (
                        <MicOffRoundedIcon className="muted-audio" style={{ color: "red" }} />
                      ) : (
                        <MicRoundedIcon />
                      )}
                    </button>
                  ) : null}
                  <div
                    className="draggbale"
                    title="move"
                    style={{ height: "100%", background: "#8b8b8b" }}
                  >
                    <Participant
                      participant={participant}
                      isLocalParticipant={participant === room!.localParticipant}
                      isDominantSpeaker={participant === dominantSpeaker}
                    />
                  </div>
                  <div
                    style={{
                      height: "40px",
                      width: "100%",
                      background: "grey",
                    }}
                    className="d-flex align-items-center justify-content-center"
                  >
                    {participant.identity}
                  </div>
                </div>
              );
            })}
          </ReactGridLayout>
        ) : null}
      </div>
    </div>
  );
}
