import audioIcon from "assets/images/av-test.png";
import mediaIcon from "assets/images/media-icon.png";
import { getChatMessages } from "actions/chat";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { ComponentRightSide } from "constants/common-constants";
import { useEffect, useState,useContext } from "react";


const ChatFeedback = ({
  setHandleComponentRightSide,
  handleSendCallNotification,
}: any) => {
  const dispatch: any = useDispatch();
  const [user, setUser] = useState("");
  const { currentChatUser } = useSelector((state: any) => state.user);
  const handleJoinMeeting = () => {
    const profile = localStorage.getItem("chatUser");
    handleSendCallNotification(
      uuidv4(),
      JSON.parse(profile ?? JSON.stringify(""))
    );
  };
  const handleChatClick = () => {
    const profile = JSON.parse(
      localStorage.getItem("chatUser") ?? JSON.stringify("")
    );
    dispatch(
      getChatMessages({
        Reciver_Id: profile.Key,
        GuestId: 0,
        ChatRoomId: profile.ChatRoomId,
        IsRoom: 0,
      })
    );
    setHandleComponentRightSide(ComponentRightSide.ChatBox);
  };

  useEffect(() => {
   setUser(currentChatUser?.Value)
  }, [currentChatUser])
  
  
  return (
    <div className="search-right-side coffee-icon">
      <div className="parent-div">
          <h5 className="text-center mt-1" style={{textTransform:"capitalize",fontWeight:600}}>{user || ""}</h5>
        <div className="feedback-group">
          <div
            className="feedback-group hide-bg"
            style={{ height: "calc(100vh - 167px)" }}
          >
            <ul className="feedback-group-action-icons custom-icon-design">
              <li className="test-audio-video">
                <a
                  href="javascript:void(0)"
                  className="media-gallery-icon"
                  title="Test Audio/Video"
                >
                  <span>
                    <i>
                      <img src={audioIcon} />
                    </i>{" "}
                    Test Audio/Video
                  </span>
                </a>
              </li>
              <li className="video-call" onClick={handleJoinMeeting}>
                <a
                  href="javascript:void(0)"
                  className="search-page-highlight-btn"
                  title="Video Call"
                >
                  {" "}
                  <span>
                    <i className="fa fa-video-camera"></i> Start Meeting
                  </span>{" "}
                </a>
              </li>
              <li className="chat" onClick={handleChatClick}>
                <a
                  href="javascript:void(0)"
                  className="search-page-highlight-btn"
                  title="Chat"
                >
                  <span>
                    <i className="fa fa-comment">
                      <div className="chat-icon-tooltip animated">
                        <div className="popover-content">
                          <span>New Chat</span>
                        </div>
                      </div>
                    </i>
                    Chat
                  </span>
                </a>
              </li>
              <li className="shared-documents">
                <a
                  href="javascript:void(0)"
                  className="media-gallery-icon"
                  title="Shared Documents"
                >
                  <span>
                    <i>
                      <img src={mediaIcon} />
                    </i>{" "}
                    Shared Documents
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatFeedback;
