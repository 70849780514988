import React, { useEffect, useState,useContext } from 'react'
import { ParticipantProvider } from 'components/VideoCallFunctionality/ParticipantProvider'
import { VideoProvider } from 'components/VideoCallFunctionality/VideoProvider'
import GuestMeetingComponent from 'components/GuestMeeting/GuestMeeting';
import { SignalR } from "services/signal-r-service";
import useConnectionOptions from 'utils/useConnectionOptions'
import { MuiThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import { SignalRContext, getConnection } from "services/signal-r-service";
import { SignalREventsEnums } from "constants/signalR-events";
import theme from 'theme';


const GuestMeeting = () => {

    const connectionOptions = useConnectionOptions();  
/*------when component is unmounted trigger DeletePublicMeetingUsers api added by vidushi----*/
    useEffect(() => {
      const handleUnload = async () => {
        const Profile = JSON.parse(localStorage.getItem("profile") ?? JSON.stringify(""));
        const payload = {
          PublicGuestId: Profile.UserId
        };
    
        try {
          const response = await fetch('https://api.smallworldmeet.com/api/User/DeletePublicMeetingUsers', {
            method: "POST",
            keepalive: true,
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(payload)
          });
    
          if (response.ok) {
            console.log("API call succeeded");
          } else {
            console.log("API call failed");
          }
        } catch (error) {
          console.log("API call error:", error);
        }
    
        const deletedData = {
          UserId: Profile.UserId
        };
        localStorage.removeItem("profile"); // Remove specific item
      };
    
      window.addEventListener("beforeunload", handleUnload);
    
      return () => {
        console.log("Component is unmounted");
        window.removeEventListener("beforeunload", handleUnload);
      };
    }, []);
    
   

    return (
        <SignalRContext.Provider value={SignalR}>
            <VideoProvider options={connectionOptions} onError={() => { }}>
                <ParticipantProvider>
                    <MuiThemeProvider theme={theme}>
                        <CssBaseline />
                        <GuestMeetingComponent />
                    </MuiThemeProvider>
                </ParticipantProvider>
            </VideoProvider>
        </SignalRContext.Provider>
    )
}

export default GuestMeeting