import welcomeImg from '../../../assets/images/welcome_banner.jpg'

const UserInteracition = ({
    setUserInteraction }:
    { setUserInteraction: (value: boolean) => void }) => {
    return (
        <div style={{ position: 'fixed', inset: 0, background: 'rgba(0,0,0,0.5', zIndex: 99 }}>
            <div className="incoming-video-call" style={{maxWidth:"100%",height:"450px",left: '56%', padding:"10px",fontSize:"30px !important", background: 'transparent'}}>
                <button className="btn" style={{border:"2px solid #38332d",position:"absolute", left:'168px',background:"rgb(98 130 126)",padding:"3px 5px", bottom: '12px', color:'#000', fontSize:"20px !important",fontWeight:"900"}} onClick={() => {
                    localStorage.setItem('reload','false')
                    setUserInteraction(false)
                }
                }><h3 style={{fontWeight:"bold", marginBottom: "0px"}}>CLICK HERE TO ENTER</h3></button>
                <img src="https://media.tenor.com/dQdZJ8UsFmwAAAAd/hot-coffee-heure-cafe.gif" width="100%"  alt="Hot Coffee Heure Cafe GIF - Hot Coffee Heure Cafe Jaime Le Café GIFs" style={{maxWidth: '833px'}}></img>            
            </div >
        </div>
        
        
    )
     
}

export default UserInteracition