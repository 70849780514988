import {
    GET_DETAILS,
    DETAIL_SUBMIT,
    GET_DROPDOWN,
    MENTOR_DROPDOWN,
    CREATERIA_SUBMIT,
    GET_USER_DETAILS,
    PROFESSTION_SUBMIT_UPDATE,
    THINGS_TO_DO_FOR_FUN,
    PROFESSTION_SERVICE_NEED_UPDATE,
    ACCOMPLISH_SUBMIT_UPDATE,
    UPLOAD_PROFILE_IMAGE,
} from "constants/store-actions";

const accountReducer = (
    state: any = {
        account: [],
        dropDown: [],
        funThing: [],
        serviceNeed: [],
        accomplish: [],
        updateConnectionCriteria: []
    },
    action: any
) => {
    switch (action.type) {
        case GET_DETAILS:
            return {
                ...state,
                account: action.payload.UserBasicDetail[0],
                userProfilePic: action.payload.UserProfilePic
            };
        case DETAIL_SUBMIT:
            return {
                ...state,
                account: action.payload,
            };
        case GET_DROPDOWN:
            return {
                ...state,
                dropDown: action.payload,
            };
        case MENTOR_DROPDOWN:
            return {
                ...state,
                mentoreDropDown: action.payload,
            };
        case CREATERIA_SUBMIT:
            return {
                ...state,
                updateConnectionCriteria: action.payload,
            };
        case GET_USER_DETAILS:
            return {
                ...state,
                userDetails: action.payload,
            };

        case UPLOAD_PROFILE_IMAGE:
            return {
                ...state,
                userProfilePic: action.payload
            }
        default:
            return state;
    }
};

export default accountReducer;