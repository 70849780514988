import { Dispatch, useState } from "react";
import Logo from "assets/images/logo.png";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { resendEmail } from "actions/auth";
import Joi from "joi";

interface formDataInterface {
    Email: string;
    emailError: string;
}

const Style: any = {
    position: "absolute",
    fontSize: "11px",
    bottom: "-18px",
    width: "100%",
    right: 0,
    textAlign: "left",
    lineHeight: "22px",
    color: "#ff4343",
};

const ExpireLinkPage = () => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({ field: "", message: "" });
    const dispatch: Dispatch<any> = useDispatch();
    const navigate = useNavigate();

    const [formData, setFormData] = useState<Partial<formDataInterface>>({});
    const [commonVariables, setCommonVariables] = useState({
        loading: false,
        isLoginForm: true,
        showIcon: true,
        showPassword: false,
        invalid: false,
        submit: false,
        rememberme: false,
    });

    const schema = Joi.object({
        Email: Joi.string()
            .email({ minDomainSegments: 2, tlds: { allow: ["com", "net"] } })
            .required(),
    });

    const handleChange = (e: any) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleLinkSubmit = (e: any) => {
        e.preventDefault();
        const { value, error }: any = schema.validate(formData);
        if (error == undefined) {
            setLoading(true)
            dispatch(resendEmail(formData, navigate, setLoading));
        } else {
            console.log(error);
            const field: any = error.toString().split(" ")[1].replace(/['"]+/g, '');
            const message: any = error.toString().split(":")[1].replace(/"|'/g, '');
            setErrors({ field: field, message: message });
        }
    };

    return (
        <div>
            <div className="login-layout">
                <section className="user">
                    <video
                        style={{ width: "100%", position: "fixed", zIndex: 0 }}
                        autoPlay
                        loop
                        id="videoPlayer"
                        muted
                    >
                        <source
                            src="https://smallworldmeet.com/assets/video/login-signup-video.mp4"
                            type="video/mp4"
                        />
                        Your browser does not support the video tag.
                    </video>
                    <div className="user_options-container">
                        <div
                            className="user_options-text"
                            style={{
                                backgroundImage: 'url("assets/images/home-banner.jpeg")',
                            }}
                        >
                            <div className="user_options-unregistered">
                                <h2 className="user_unregistered-title">Link has expired</h2>
                            </div>
                        </div>

                        <div className="user_options-forms" id="user_options-forms">
                            <div className="user_forms-login">
                                <div className="login-logo">
                                    <img src={Logo} />
                                </div>
                                <form className="contact-form-section" onSubmit={handleLinkSubmit}>
                                    <div className="form-field-wrapper">
                                        <div className="field-title">Email*</div>
                                        <input
                                            className={`form-full ${commonVariables.invalid ? "invalid" : ""
                                                }`}
                                            value={formData.Email}
                                            type="text"
                                            name="Email"
                                            placeholder="Enter Email Address"
                                            required
                                            onChange={handleChange}
                                        />
                                        {errors.field == 'Email' ? (
                                            <div className="help-block" style={Style}>
                                                <div>{errors.message}</div>
                                            </div>) : null}
                                    </div>
                                    <div className="form-field-wrapper">
                                        <button type="submit" className="field-button">
                                            Resend Link{" "}
                                            {commonVariables.loading && (
                                                <i className="fa fa-spinner fa-spin"></i>
                                            )}
                                            {loading ? (
                                                <div style={{ left: "285px", marginTop: "-5px", fontSize: "8px" }} className="spinner-border spinner-border-sm text-light" role="status"></div>
                                            ) : null}
                                        </button>
                                    </div>
                                    <div className="form-field-wrapper">
                                        <Link to="/" className="bottom-link">
                                            Back to Login
                                        </Link>
                                    </div>
                                </form>
                                <div className="login-footer">
                                    <a href="javascript:void(0)">
                                        {" "}
                                        © 2022-2023 THE AUTHENTIC CONSORTIUM. All rights reserved Privacy
                                        Policy
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default ExpireLinkPage;