import { userStatus } from 'actions';
import { GET_USER_LIST, INSERT_PUBLIC_MEETING_USERS, TOGGLE_LOADING, INSERT_USERS_IN_CALL, GET_ONLINE_USERS, UPDATE_CHAT_ROOM_ID, UPDATE_CHAT_USER, GET_USER_IN_CALL, GET_ONLINE_USERS_EVENT, USER_STATUS } from 'constants/store-actions';
import { valid } from 'joi';

const initialState = {
    chatUserList: [],
    userInCall: [],
    currentChatUser: {},
    InsertuserInCall: [],
    guestUser: {},
    userStatus: {},
    onlineUsers: [],
    loading: false
}

const userReducer = (state = initialState, action: { type: string, payload: any }) => {
    switch (action.type) {
        case GET_USER_LIST:
            return { chatUserList: action.payload, loading: false };
        case TOGGLE_LOADING:
            return { ...state, loading: !state.loading };

        case UPDATE_CHAT_USER:
            return { ...state, currentChatUser: action.payload };

        case GET_ONLINE_USERS:
            return { ...state, onlineUsers: action.payload };

        case UPDATE_CHAT_ROOM_ID:
            let immutableState = [...state.chatUserList];
            const { Sender_Id, ChatRoomId } = action.payload;
            immutableState.map((item: any) => {
                if (item.Key == Sender_Id)
                    item.ChatRoomId = ChatRoomId;
                return item;
            })

            //   const result1 = immutableState.filter((objA:any) => !state.onlineUsers.some((objB:any) => objA.Key === objB.Key));
            //   console.log(result);
            return { ...state, chatUserList: immutableState }

        case INSERT_USERS_IN_CALL:
            return { ...state, InsertuserInCall: action.payload };

        case USER_STATUS:
            const onlineKeys = action.payload.onlineList?.map((user: any) => user.Key);           
            const updatedOnline = action.payload.onlineList?.map((onlineUser:any) => {
                const matchedFavorite = action.payload.favouriteList.find((user:any) => user.Key == onlineUser.Key);
                const matchedRecent = action.payload.recentList.find((user:any) => user.Key == onlineUser.Key);
              
                if (matchedFavorite) {
                  return {
                    ...onlineUser,
                    LastMessageTime: matchedFavorite.LastMessageTime,
                    LastMessage: matchedFavorite.LastMessage,
                    ChatRoomId: matchedFavorite.ChatRoomId,
                  };
                } else if (matchedRecent) {
                  return {
                    ...onlineUser,
                    LastMessageTime: matchedRecent.LastMessageTime,
                    LastMessage: matchedRecent.LastMessage,
                    ChatRoomId: matchedRecent.ChatRoomId,
                  };
                }
              
                return onlineUser;
              });
              
            const filteredRecentList = action.payload.recentList.filter((recentUser: any) => !onlineKeys?.includes(recentUser.Key.toString()) && !onlineKeys?.includes(recentUser.Key));
            const filteredFavouriteList = action.payload.favouriteList.filter((favouriteUser: any) => !onlineKeys?.includes(favouriteUser.Key.toString()) && !onlineKeys?.includes(favouriteUser.Key));
            const result = {
                onlineList: updatedOnline,
                favouriteList: filteredFavouriteList,
                recentList: filteredRecentList
            };
            return { ...state, userStatus: result };

        case GET_USER_IN_CALL:
            let immutableState1 = state.userStatus
            // @ts-ignore
            const value = immutableState1?.onlineList && Object.values(immutableState1.onlineList).map((item: any) => {
                const changeItem = action.payload && action.payload.length && action.payload.find((changeItem: any) => changeItem.UserId === item.Key);
                if (changeItem) {
                    return {
                        ...item,
                        IsUserBusy: changeItem.IsBusyUser ? 1 : 0
                    };
                } else {
                    return {
                        ...item,
                        IsUserBusy: 0
                    };
                }
            });
            return { ...state, userStatus: { ...state.userStatus, onlineList: value } };
        case GET_ONLINE_USERS_EVENT:
            let immutableState2 = state.userStatus
            // @ts-ignore
            return { ...state, userStatus: { ...state.userStatus, onlineList: action.payload } };
        default:
            return state;
    }
};

export default userReducer;