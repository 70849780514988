import { Dispatch, useEffect, useState } from "react";
import {
    useLocation,
    useNavigate
} from "react-router-dom";
import { useDispatch } from "react-redux";
import { setPassword } from "actions/auth";
import * as api from "api";
import Main from "features/set-password";
import ExpireLinkPage from "features/set-password/ExpireLinkPage";
import Joi from "joi";


interface formDataInterface {
    Password: string;
    Token: string;
    ConfirmPassword: string;
}

const SetPassword = () => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({ field: "", message: "" });
    const dispatch: Dispatch<any> = useDispatch();
    const [isValidToken, setisValidToken] = useState(false)
    const [formData, setFormData] = useState<Partial<formDataInterface>>({});
    const [commonVariables, setCommonVariables] = useState({
        loading: false,
        isLoginForm: true,
        showIcon: true,
        showPassword: false,
        invalid: false,
        submit: false,
        rememberme: false,
    });
    const navigate = useNavigate();
    const location = useLocation();

    const schema = Joi.object({
        Password: Joi.string().min(8).required(),
        ConfirmPassword: Joi.string().min(8).required(),
    }).options({ allowUnknown: true });

    useEffect(() => {
        const Token = location?.search.split("=")[1];
        setFormData({ ...formData, ["Token"]: Token });
        if (!Token) {
            setisValidToken(true)
        }
        api
            .checkResetPasswordToken({ Token: Token })
            .then((res: any) => {
                if (res.data.responseCode == 400) {
                    setisValidToken(true)
                }
            })
            .catch((err: any) => console.error(err));
    }, [location]);


    const handleChange = (target: any, value: any) => {
        setFormData({ ...formData, [target]: value });
    };

    const handlePasswordSubmit = (e: any) => {
        e.preventDefault();
        const { value, error }: any = schema.validate(formData);
        if (error == undefined) {
            setErrors({
                field: "",
                message: "",
            });
            if (formData.Password !== formData.ConfirmPassword) {
                setErrors({ field: "ConfirmPassword", message: "Confirm password and password must be same." });
                return
            }
            const payload = {
                Password: formData.Password,
                Token: formData.Token
            };
            setLoading(true)
            dispatch(setPassword(payload, navigate, setLoading))
            setFormData({
                Password: "",
                ConfirmPassword: "",
            })
        } else {
            console.log(error);
            const field: any = error.toString().split(" ")[1].replace(/['"]+/g, '');
            const message: any = error.toString().split(":")[1].replace(/"|'/g, '');
            setErrors({ field: field, message: message });
        }
    };

    return (
        <>
            {
                !isValidToken ?
                    <Main
                        commonVariables={commonVariables}
                        handlePasswordSubmit={handlePasswordSubmit}
                        handleChange={handleChange}
                        setCommonVariables={setCommonVariables}
                        formData={formData}
                        errors={errors}
                        loading={loading}
                    />
                    : <ExpireLinkPage />}
        </>
    );
};

export default SetPassword;