/*---below all actions is added by vidushi---*/
import { userStatus } from 'actions';
import {GET_ONLINE_USERS,SET_RAISEHAND,REMOVE_RAISEHAND,SET_REACTION} from 'constants/store-actions';
import { valid } from 'joi';

const initialState = {
    onlineUsers:[],
    loading: false,
    raiseHandId:[],
    reaction:{}
    
}

const userStatusReducer = (state = initialState, action: { type: string, payload: any }) => {
    switch (action.type) {
       
            case GET_ONLINE_USERS:
                const profile = JSON.parse(
                    localStorage.getItem("profile") ?? JSON.stringify("")
                  );
                  const filteredPayload = action.payload?.filter((item:any) => item.Key !== profile.UserId.toString() || item.Email !== profile.Email);
                return { ...state, onlineUsers: filteredPayload };
                case SET_RAISEHAND:
                    return {...state, raiseHandId:[...state.raiseHandId ,action.payload]};
                    case SET_REACTION:
                        return {...state, reaction:action.payload};
               case REMOVE_RAISEHAND:
                const data =[...state.raiseHandId]
                const updatedWhole = data.filter((item:any) => item != action.payload);
                return {...state, raiseHandId:updatedWhole};
        default:
            return state;
    }
};
export default userStatusReducer;