import UserInfoCard from "features/UserInfoCard/UserInfoCard";
import { useEffect, useState } from "react";
import { SignalREventsEnums } from "constants/signalR-events";
import SelectBox from "components/SelectBox/SelectBox";
import { setRoom } from "actions/room";
import { useDispatch } from "react-redux";
import { GET_ONLINE_USERS, INSERT_MESSAGE,SET_RAISEHAND,SET_REACTION,REMOVE_RAISEHAND, SET_TIMER,INSERT_PUBLIC_MEETING_USERS, USER_STATUS,GET_ONLINE_USERS_EVENT } from "constants/store-actions";
import ScheduleMeetingModal from "./Modal/ScheduleMeetingModal";
import MeetingUrlModal from "./Modal/MeetingUrlModal";
import { PushNotification } from "services/PushService";
import { useSelector } from "react-redux";
import { addGuestUser } from "actions/guest";
import useVideoContext from "hooks/useVideoContext/useVideoContext";
import { ComponentRightSide } from "constants/common-constants";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { RemoveUsersInCall,UpdateOnlineUsers,DeleteOnlineUser, breakoutRoom, freebreakoutRoom,GetUsersInCall, DeletePublicMeetingUsers,RemoveAllParticipants,GetOnlineUsers } from "actions";
import {  GetPublicMeetingUsers } from "actions";
import useParticipantsContext from "hooks/useParticipantsContext/useParticipantsContext";
import FloatingEmoji from "./FloatingHeart";
const LeftSide = ({
  setHandleComponentRightSide,
  connection,
  setShowCallNotification,
  handleComponentRightSide,
  handleCallPickByReceiver,
}: any) => {
  const { chatUserList: chatUsers, userStatus } = useSelector(
    (state: any) => state.user
  );
  const {onlineUsers,reaction} = useSelector(
    (state: any) => state.userStatusReducer
  );

 
  const { allmemberInRoom } = useSelector((state: any) => state.guest);

  useEffect(() => {
    setUsersList(userStatus);
  }, [userStatus])


  const [usersList, setUsersList] = useState({
    onlineList: [],
    favouriteList: [],
    recentList: [],
  });
  const options = [
    { id: 1, value: "-- Meeting--" },
    { id: 2, value: "Host a Meeting" },
    { id: 3, value: "Scheduled Meetings" },
  ];
  const [loginUserId, setLoginUserId] = useState(null);
  const [usersOnline, setUsersOnline] = useState<any>([]);
  const [search, setSearch] = useState("");
  // const [searchList, setSearchList] = useState([]);
  const [searchList, setSearchList] = useState<any[]>([]);
  const dispatch: any = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [show, setShow] = useState(false);
  const [animation, setAnimation] = useState(false);
  const [callApi, setCallApi] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { room } = useVideoContext();
  const navigate = useNavigate();
  const { galleryViewParticipants } = useParticipantsContext();
  const [paginateParticipant, setPaginateParticipant] = useState([
    ...galleryViewParticipants,
  ]);
  const { timer } = useSelector((state: any) => state.room);

  const Local_callEnd = JSON.parse(
    localStorage.getItem("callEnd") ?? JSON.stringify("")
  );
  



  useEffect(() => {
    if (Local_callEnd == true )  {
      if(handleComponentRightSide != ComponentRightSide.RoomComponent){
        room?.disconnect();   
      }   
    }
  
  }, [Local_callEnd, handleComponentRightSide]);


  

  const handelCloseModal = () => {
    setShowModal(false);
  };
  const handelClose = () => {
    setShow(false);
  };
  const handleSelect = (value: any) => {
    if (value == 2) {
      setShowModal((prev: any) => !prev);
    }
  };
  const handleSearch = (value: any) => {
    setSearch(value);
    const wholeUserList = [
      ...usersList.onlineList,
      ...usersList.favouriteList,
      ...usersList.recentList,
    ];
    const filterUserList = wholeUserList.filter((item: any) => {
      return item.Value.toLowerCase().match(
        new RegExp(value.toLowerCase(), "i")
      );
    });
    setSearchList(filterUserList); 
  };

  const insertMessage = (data: any) => {
    dispatch({ type: INSERT_MESSAGE, payload: data });
  };

  const addPushNotification = (data: any) => {
    const payload: any = {
      title: `New message from ${data.SenderName}`,
      message: data.Message,
      theme: "darkblue",
    };
    return PushNotification(payload);
  };
/*---------online and busy user list added by vidushi----*/
  const updateUsersList = (tempUsersList: any) => {
    let tempList = {
      onlineList: [],
      favouriteList: [],
      recentList: [],

    };
    if (tempUsersList) {
      tempList.onlineList = tempUsersList.filter(
        (x: any) => x.Status || x.IsUserBusy
      );

      tempList.onlineList = onlineUsers;
      tempList.favouriteList = tempUsersList.filter(
        (x: any) => x.IsFavorite && !x.Status && !x.IsUserBusy
      );
      tempList.recentList = tempUsersList.filter(
        (x: any) => !x.IsFavorite && !x.Status && !x.IsUserBusy
      );

      setUsersList(tempList);

      dispatch({ type: USER_STATUS, payload: tempList });

    }
  };

  const UpdateUserStatusThroughSignalR = (userStatus = usersOnline) => {
    let tempUsersList = [...chatUsers];
    if (tempUsersList && tempUsersList.length > 0) {
      for (var user of tempUsersList) {
        user.Status = 0;
      }
      for (let i = 0; i < userStatus.length; i++) {
        var index = tempUsersList?.findIndex(
          (x: any) => x.Key === parseInt(userStatus[i].Key)
        );
        if (index != -1) tempUsersList[index].Status = 1;
      }
      updateUsersList(tempUsersList);
    }
  };

  useEffect(()=>{
    dispatch(GetOnlineUsers())
  },[])
  useEffect(() => {
    dispatch(GetUsersInCall())
  }, [callApi])


  useEffect(() => {
    const profile: any = localStorage.getItem("profile");
    if (profile) setLoginUserId(JSON.parse(profile).UserId);
  }, []);

  useEffect(() => {
    if (chatUsers) {
      const filteredChatUsers = chatUsers.filter((chatUser:any) =>
        !onlineUsers?.some((onlineUser:any) => onlineUser.Key === chatUser.Key)
      );
      const data = [...filteredChatUsers];

      for (var user of data) {
        user.Status = 0;
      }
      let immutableState = {
        onlineList: [],
        recentList: [],
        favouriteList: [],
      };

      immutableState.onlineList = onlineUsers;
      immutableState.recentList = chatUsers.filter(
        (x: any) => !x.Status && !x.IsUserBusy && !x.IsFavorite
      ) 

       immutableState.favouriteList = chatUsers.filter((x: any) => x.IsFavorite);

      setUsersList({ ...immutableState });
      UpdateUserStatusThroughSignalR();
    } 
  }, [chatUsers,onlineUsers]);

             

if(allmemberInRoom){
  localStorage.setItem("roomName",JSON.stringify(room?.name))
  localStorage.setItem("participants",allmemberInRoom.length)
}
  useEffect(() => {
    if (connection) {      
      connection.on("DataInSignalR", async (data: any) => {   
        if (data) {
          const profile = JSON.parse(
            localStorage.getItem("profile") ?? JSON.stringify("")
          );
          const Local_RoomData = JSON.parse(
            localStorage.getItem("RoomData") ?? JSON.stringify("")
          );
          const loginId = profile.UserId;
          let result;       
          result = JSON.parse(data);
          if (result && result.length) {
            if (result[0].dataType == SignalREventsEnums.UserInChat) {
              console.log("---- USER IN CHAT -----" );
              const participants = JSON.parse(
                localStorage.getItem("participants") ?? JSON.stringify("")
              ); 
              if(participants||allmemberInRoom && allmemberInRoom?.length){  
                const roomName = JSON.parse(
                  localStorage.getItem("roomName") ?? JSON.stringify("")
                ); 
                if (result[0].Room != null && result[0].Room != undefined) {
                  if (result[0].Room === roomName) {  
                    // push notification
                    addPushNotification(result[0]);
                    insertMessage(result[0]);
                  }
                }                
              }
              else{
              if(result[0].Reciver_Id === result[0].Sender_Id  ) {
                // push notificaton
                addPushNotification(result[0]);
                insertMessage(result[0]);
              }
            }
              if (result[0].Reciver_Id == loginId) {
                addPushNotification(result[0]);
                insertMessage(result[0]);
              }
            }
            else if (result[0].dataType == SignalREventsEnums.PublicMeetingUsers) {
              console.log("==PublicMeetingUsers==")
              dispatch(GetPublicMeetingUsers());
              }

            else if (result[0].dataType == SignalREventsEnums.GUEST_USER_LIST) {
              dispatch({
                type: INSERT_PUBLIC_MEETING_USERS,
                payload: result,
              });
            }
           
            else if (result[0].dataType == SignalREventsEnums.OnlineUser) {
              console.log("---user-is-online---")
              dispatch({ type: GET_ONLINE_USERS, payload: result });
              dispatch({ type: GET_ONLINE_USERS_EVENT, payload: result });
              //setUsersList.onlineList(onlineUsers)
            }           
            else {
              setUsersOnline(result);
              UpdateUserStatusThroughSignalR(result);
            }
          } else {
            
            if (result.dataType == SignalREventsEnums.EndEntireCall) {
              const Local_RoomData = JSON.parse(
                localStorage.getItem("RoomData") ?? JSON.stringify("")
              );

              const roomName = JSON.parse(
                localStorage.getItem("roomName") ?? JSON.stringify("")
              );

              if (result.Roomid == Local_RoomData.CurrentroomName || result.RoomName == roomName) {
                localStorage.removeItem("RoomData");
                setHandleComponentRightSide(ComponentRightSide.RightSide);
                room?.disconnect();
                console.log("--ENTIRE-CALL-END--");
                localStorage.removeItem("roomNameLatest");

                const Local_profile = JSON.parse(
                  localStorage.getItem("profile") ?? JSON.stringify("")
                );
                const deletedData = {
                  UserId: Local_profile.UserId,
                  Status:1,
                }
                dispatch(RemoveUsersInCall(deletedData))
                dispatch(UpdateOnlineUsers(deletedData))
              
                navigate("/home");
                toast.dismiss();
                dispatch(GetUsersInCall())
                dispatch(GetOnlineUsers())
              }
            }
            
            if (result.dataType == SignalREventsEnums.RaiseHand) {
              console.log("==HAND RAISE==")
              dispatch({ type: SET_RAISEHAND, payload: result.sid });
              const roomName = JSON.parse(
                localStorage.getItem("roomName") ?? JSON.stringify("")
              );

               if (result.RoomName == roomName){
               toast.dismiss();
              toast(`Hand Raised By ${result.Name}`);
               }
            }
            if (result.dataType == SignalREventsEnums.Reactions) {
              console.log("==SEND REACTIONS==")
              dispatch({ type: SET_REACTION, payload: result.Reaction });
              const roomName = JSON.parse(
                localStorage.getItem("roomName") ?? JSON.stringify("")
              );

               if (result.RoomName == roomName){
               toast.dismiss();
              toast(`${result.Reaction} send by ${result.Name} `);
              setAnimation(true)                                 
              setTimeout(() => {
                setAnimation(false)  
              },5000);
         
               }
            }

            if (result.dataType == SignalREventsEnums.DownRaiseHand) {
              console.log("==Down HAND RAISE==")
              dispatch({ type: REMOVE_RAISEHAND, payload: result.sid });
              const roomName = JSON.parse(
                localStorage.getItem("roomName") ?? JSON.stringify("")
              );

               if (result.RoomName == roomName){
               toast.dismiss();
              toast(`Hand Removed By ${result.Name}`);
               }
            }
            if (result.dataType == SignalREventsEnums.UserInCall) {
              console.log("--USER-IN-CALL--");
              console.log("--RECEIVED THE CALLING REQUEST--");
              if (result.UserInCall == loginId) {
                localStorage.removeItem("callEnd")          
                dispatch(setRoom(result));
                setShowCallNotification(true);
                localStorage.setItem("RoomData", JSON.stringify(result));
              }
            }
            if (result.dataType == SignalREventsEnums.CallEnd) {
              console.log("-- CALL END --");
              const Local_profile = JSON.parse(
                localStorage.getItem("profile") ?? JSON.stringify("")
              );
              const payload = {
                PublicGuestId: Local_profile.UserId
              }
              dispatch(
                DeletePublicMeetingUsers(payload))
                const payloadOnlineUser =
                {
                  UserId: Local_profile.UserId,
                }  
              dispatch(DeleteOnlineUser(payloadOnlineUser));
              dispatch(GetUsersInCall())
              if (result.UserInCall == loginId) {
                setShowCallNotification(false);
              }
            }
            if (result.dataType == SignalREventsEnums.IsBusy) {
              console.log("--I AM BUSY--");
              setCallApi(!callApi)
              dispatch(GetUsersInCall())
            }
            if (result.dataType == SignalREventsEnums.UserInBreakoutCall) {
              console.log("--BREAK-OUT--");
              localStorage.setItem('Timer', result.Timer)
              dispatch({ type: SET_TIMER, payload: result.Timer });
              if(result?.showLeaveRoomOption){
                localStorage.setItem('joinRoom', 'true')
              }
              setTimeout(() => {
                localStorage.removeItem('joinRoom')
                localStorage.removeItem('Timer')                
              }, timer * 60000);
              dispatch(breakoutRoom(result.rooms));
              if (result.enteringMainRoom == true) {
                localStorage.setItem('leaveRoom', 'true')
              }
              else {
                localStorage.setItem('leaveRoom', 'false')
              }
            }

            if (result.dataType == SignalREventsEnums.EndEntireCall) {
              console.log("--ENTIRE-CALL-END--");
              localStorage.setItem("callEnd",'true')
              localStorage.setItem("joinRoom",'false')
              
              // room?.disconnect();
              // setHandleComponentRightSide(ComponentRightSide.RightSide);
              dispatch(RemoveAllParticipants())
              dispatch(freebreakoutRoom())
              const Local_profile = JSON.parse(
                localStorage.getItem("profile") ?? JSON.stringify("")
              );
              const roomName = JSON.parse(
                localStorage.getItem("roomName") ?? JSON.stringify("")
              );
              const Local_RoomData = JSON.parse(
                localStorage.getItem("RoomData") ?? JSON.stringify("")
              );
              const deletedData = {
                UserId: Local_profile.UserId
              }
              console.log("--Remove-ENTIRE-CALL-END--");
              setCallApi(!callApi)
              if (result.Roomid == Local_RoomData.CurrentroomName || result.RoomName == roomName) {
                setHandleComponentRightSide(ComponentRightSide.RightSide);
                room?.disconnect();
                localStorage.removeItem("RoomData");
                navigate("/home");
                toast.dismiss();
                toast(`Call Ended by ${result.Name}`);
              }
            }

            if (result.dataType == SignalREventsEnums.joinCall) {
              console.log("--admin join the room---")
              if (result.isjoin == true) {
                localStorage.setItem("joinRoom",'true')
              
            }
            else{
              localStorage.setItem("joinRoom",'false')
            }
          }
            if (result.dataType == SignalREventsEnums.RequestForGuestCall) {
              console.log("--RECEIVED THE GUEST CALLING REQUEST--");
              dispatch(addGuestUser(result));
              //  if(result.userId == loginId) {
              //  }
            }

            if (result.dataType == SignalREventsEnums.UserDeclineCall) {
              console.log("---Call Declined---");
              handleCallPickByReceiver(false, { decline: true, result });
            }

            if (
              result.dataType == SignalREventsEnums.RequestPrivatecallResponse
            ) {
              if (result.receiverId == loginId) {
                handleCallPickByReceiver(true, result);
                if (result.roomName) {
                  let RoomData = JSON.parse(
                    localStorage.getItem("RoomData") ?? JSON.stringify("")
                  );
                  RoomData.CurrentroomName = result.roomName;
                  localStorage.setItem("RoomData", JSON.stringify(RoomData));
                }
              }
            }
          }
        }
      });
    }
  }, [connection]);
  return (
    <>
   {animation? <FloatingEmoji emoji={reaction}/>:null}
      {showModal ? (
        <ScheduleMeetingModal
          setShow={setShow}
          handelCloseModal={handelCloseModal}
        />
      ) : null}
      {show ? <MeetingUrlModal handelClose={handelClose} /> : null}
      <div className="search-left-side collab-coffee-shop-left-side">
        <div className="form-group search-form-group">
          <input
            disabled={chatUsers && chatUsers.length ? false : true}
            onChange={(e) => handleSearch(e.target.value)}
            className="form-control ng-untouched ng-pristine ng-valid"
            placeholder="Search"
            type="text"
          />
          <i className="fa fa-search"></i>
        </div>
        <div className="search-left-side-btn">
          <SelectBox data={options} handleSelect={handleSelect} />
          <button className="btn btn-group-sm test-audio" title="Email" type="button"> Email</button>
        </div>
        <div className="message-users-list scroll-y">
          <div
            className="tab-content"
            style={{ maxHeight: "calc(100vh - 100px)" }}
          >
            <div className="tab-pane fade active show">
              {search ? (
                <UserInfoCard
                  setHandleComponentRightSide={setHandleComponentRightSide}
                  list={searchList}
                  IsFavorite={false}
                  heading={`Searching ${search}`}
                />
              ) : chatUsers && chatUsers.length ? (
                <>
                  <UserInfoCard
                    setHandleComponentRightSide={setHandleComponentRightSide}
                    IsFavorite={false}
                    list={usersList.onlineList}
                    // list={onlineUsers}
                    heading={"Who is available"}
                  />
                  <UserInfoCard
                    setHandleComponentRightSide={setHandleComponentRightSide}
                    IsFavorite={true}
                    list={usersList.favouriteList}
                    heading={"Favourite"}
                  />
                  <UserInfoCard
                    setHandleComponentRightSide={setHandleComponentRightSide}
                    IsFavorite={false}
                    list={usersList.recentList}
                    heading={"Recent Contacts"}
                  />
                </>
              ) : (
                <>
                  <div className="text-center">Loading...</div>
                  <div className="btn-center">
                    <a href="#" className="btn btn-theme">
                      Add Contact
                    </a>
                  </div>
                </>
              )}
              {search && !searchList.length ? (
                <p className="text-center mt-2">no record found</p>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeftSide;
