import RingVolumeIcon from "@mui/icons-material/RingVolume";

const ToggleIncomingCallButton = ({ handleIncomingCallpopup,isSeen }: any) => {
  return (
    <div style={{ marginRight: "20px", textAlign: "center" }}>
      <button
        // className="btn btn-success btn-sm"
        style={{
          background: "green",
          color: "white",
          border: "none",
          borderRadius: "5px",
          marginLeft: "10px",
        }}
        onClick={handleIncomingCallpopup}
        // disabled={!hasAudioTrack || props.disabled}
        // startIcon={isAudioEnabled ? <MicIcon /> : <MicOffIcon />}
        data-cy-audio-toggle
      >
        <RingVolumeIcon className={isSeen?"blinking":""} />
      </button>
      <span style={{ display: "block", marginLeft: "7px" }}>
        Incoming Calls
      </span>
    </div>
  );
};

export default ToggleIncomingCallButton;
