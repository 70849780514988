import * as signalR from "@microsoft/signalr";
import React from "react";
import { v4 as uuidv4 } from "uuid";

export const getConnection = () => {
  const profile = localStorage.getItem("profile");
  let userInfo = JSON.parse(profile ?? JSON.stringify(""));
  const signalRConnection = new signalR.HubConnectionBuilder()
    .withUrl(
      `https://api.smallworldmeet.com/notificationHub?UserId=${
        userInfo.UserId ? userInfo.UserId : uuidv4()
      }`,
      {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      }      
    )
    .build();
  try {
    signalRConnection.start();
  } catch (error) {
    
    console.log("#############SignalR###############", error);
  }
  return signalRConnection;
};

export const SignalR = getConnection();
export const SignalRContext = React.createContext<any>(null);
