import {
  removeUserFavoriteChatList,
  insertUserFavoriteChatList,
  deleteChatRoomParticipants,
  insertCurrentChatUser,
} from "actions";
import DefaultUserLogo from "assets/images/default-user.png";
import Dropdown from "components/dropdown";
import {
  ComponentRightSide,
  ConnectSignalREnums,
} from "constants/common-constants";
import useVideoContext from "hooks/useVideoContext/useVideoContext";
import { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { SignalRContext } from "services/signal-r-service";
import { sendThirdPersonCallResponse } from "utils/helper/eventBus";
import { v4 as uuidv4 } from "uuid";
import DefaultUserImage from 'assets/images/default-user.png';

const UserInfoCardItem = ({
  user,
  setHandleComponentRightSide,
  IsFavorite,
}: any) => {
  const dispatch: any = useDispatch();
  let data = ["View Details"];
  if (IsFavorite) {
    data.push("Remove from favorites");
  } else {
    data.push("Add to favorites");
  }
  const { room } = useVideoContext();
  if (room) data.push("Add to Current call");
  const connection = useContext(SignalRContext);
  const handleDropdown = (value: any, user: any) => {
    const target: any = value.target.text;
    if (target == "Add to favorites") {
      const payload: any = {
        FavoriteUserID: user.Key,
        ChatRoomId: user.ChatRoomId,
        IsFavorite: true,
      };
      dispatch(insertUserFavoriteChatList(payload));
    } else if (target == "Remove from favorites") {
      const payload: any = {
        FavoriteUserID: user.Key,
        ChatRoomId: user.ChatRoomId,
        IsFavorite: false,
      };
      dispatch(removeUserFavoriteChatList(payload));
    } else if (target == "Leave this group") {
      const payload = {
        ChatRoomId: user.ChatRoomId,
      };
      dispatch(deleteChatRoomParticipants(payload));
    } else if (target == "Add to Current call") {
      handleCallSend(user);
    } else {
      console.log(target);
    }
  };

  const handleCallSend = (user: any) => {
    const profile = JSON.parse(
      localStorage.getItem("profile") ?? JSON.stringify("")
    );
    let SingleUser = {
      UserInCall: user.Key,
      loginUserId: `${profile.UserId}-${user.Key}`,
      CurrentroomName: room ? localStorage.getItem("roomNameLatest") : uuidv4(),
      IsRoom: true,
      Name: profile.FirstName + " " + profile.LastName,
      callingName: profile.FirstName + " " + profile.LastName,
      dataType: ConnectSignalREnums.UserInCall,
      ChatRoomId: user.ChatRoomId,
    };
    localStorage.setItem("chatUser", JSON.stringify(user));
    if (connection) {
      console.log("Calling Event Sent.");
      connection.send("DataInSignalR", JSON.stringify(SingleUser));
      localStorage.setItem("RoomData", JSON.stringify(SingleUser));
      if (!room) sendThirdPersonCallResponse(SingleUser);
    }
  };

  const drag = (user:any) => {
    // e?.dataTransfer.setData("SelectedUser", JSON.stringify(user));
    localStorage.setItem("SelectedUser",JSON.stringify(user))
  }

  return (
    <div className="single-user" key={user?.key} draggable={true} onDragStart={()=>drag(user)}>
      <div
        style={{ zIndex: 999 }}
        onClick={(e: any) => {
          localStorage.setItem("chatUser", JSON.stringify(user));
          setHandleComponentRightSide(ComponentRightSide.ChatFeedback);
          dispatch(insertCurrentChatUser(user))
        }}
      >
       
        <div className="user-pic">
          <img
            id="imgPrev"
            onError={(err: any) => {
              err.target.src = DefaultUserLogo;
            }}
           src= {user?.ProfilePicture ? `https://api.smallworldmeet.com/Upload/${user?.ProfilePicture}` : DefaultUserLogo}
            // src={`https://api.smallworldmeet.com/Upload/${user?.ProfilePicture}`}
          />
          <div
          className={`user-status ${user.Status === 0 ? 'away' : user.Status === 1 ? 'online' : 'busy'}`}
      
          //  className={`user-status ${user?.IsUserBusy ? (user?.IsUserBusy ? 'busy' : 'online') : 'away'}`}
       
          ></div>
        </div>
        <div className="user-name">{user?.Value}</div>
        <div className="project-name">{user?.LastMessage}</div>
        <div className="lastchat">{user?.LastMessageTime}</div>
        <div className="search-page-action-icons d-none"></div>
      </div>
      <button
        className="start-video-call"
        style={{ border: "none" }}
        onClick={() => handleCallSend(user)}
      >
        <i className="fa fa-video"></i>
        <div className="start-video-call-popup-tooltip">
          <div className="popover-content">
            <span>Start Meeting</span>
          </div>
        </div>
      </button>

      <div className="user-option-menu">
        
        <Dropdown
          data={user.IsRoom ? [...data, "Leave this group"] : data}
          user={user}
          handleDropdown={handleDropdown}
        />
      </div>
    </div>
  );
};

export default UserInfoCardItem;
