import { GetUserListForTeam, sendMeetingInvitation } from 'api'
import { SendInvitation } from "actions";
import SearchAndSelectBox from 'components/SelectBox/SearchAndSelectBox'
import { useEffect, useState } from 'react'
import { useDispatch,useSelector } from "react-redux";

const ScheduleMeetingModal = ({ setShow, handelCloseModal }: any) => {
    const dispatch: any = useDispatch();
    const [userList, setUserList] = useState([])
    const  {meettingurl}  = useSelector((state: any) => state.guest);
    const [form, setForm] = useState({
        meetingTitle: "",
        meetingAbout: "",
        meetingTime: "",
        meetingDuration: "",
        guestUserList: []
    })

    const [guestUser, setGuestUser] = useState({
        Key: 0,
        Status: 0,
        Name: "",
        email: "",
        Phone: "",
        IsSystemUser: true,
        isEmailCheck: false,
        isPhoneCheck: false,
        isBoth: false
    })

    const [formAction, setformAction] = useState({
        radioButton: 1,
        isGuestUser: false,
        showTabel: false,
        checkAll: false,        
    })

    const handleChange = (target: any, value: any) => {
        if (formAction.isGuestUser && target == "Name" || target == "Phone" || target == "email") {
            setGuestUser({ ...guestUser, [target]: value })
        } else if (target == "participant") {          
            const { value: fdd, label, ...rest } = value;
            setformAction({ ...formAction, isGuestUser: false,  showTabel:true })
            const newValue: any = [...form.guestUserList, {...rest, IsSystemUser: true}];
            setForm({
                ...form, guestUserList: newValue 
            })      
        }
        else {
            setForm({ ...form, [target]: value })
        }
    }
    const handleAddGuestUser = () => {
        setformAction({ ...formAction, isGuestUser: false, showTabel: true })
        const newValue: any = [...form.guestUserList, guestUser];
        setForm({
            ...form, guestUserList: newValue
        })
        setGuestUser({
            Key: 0,
            Status: 0,
            Name: "",
            email: "",
            Phone: "",
            IsSystemUser: true,
            isEmailCheck: false,
            isPhoneCheck: false,
            isBoth: false
        })
    }

    const handelFormAction = (value: any) => {
        setformAction({ ...formAction, ["radioButton"]: value })
    }

    const handelGuestUser = () => {
        setformAction({ ...formAction, ["isGuestUser"]: !formAction.isGuestUser })
    }

    const handleDeleteTableFields = (id: number) => {
        setForm({ ...form, guestUserList: form.guestUserList.filter((item, index) => index !== id) })
    }

    const handleCheckBox = (target: any, value: any, id: any) => {
        if (value == "all") {
            setformAction({ ...formAction, checkAll: !formAction.checkAll })
            const newValue: any = form.guestUserList.map((item: any) => {
                return {
                    ...item, isEmailCheck: !formAction.checkAll,
                    isPhoneCheck: !formAction.checkAll, isBoth: !formAction.checkAll
                }
            })
            setForm({
                ...form, guestUserList:newValue
            })
        } else {
            const newValue: any = form.guestUserList.map((item: any, index: any) => {

                if (index == id) {
                    if (target === 'isBoth') {
                        return {
                            ...item, isEmailCheck: !item[target],
                            isPhoneCheck: !item[target],
                            isBoth: !item[target]
                        }
                    }
                    return { ...item, [target]: !item[target] }
                }
                else {
                    return item
                }
            })
            setForm({
                ...form, guestUserList: newValue
            })

        }

    }
    const handleSendMettingInvite = async (e: any) => {

        e.preventDefault();
        if (form.guestUserList.length == 0 && form.meetingTitle == "") {
            return
        }
        const ProfileData = JSON.parse(
            localStorage.getItem("profile") || JSON.stringify(""))
        const payload = {
            email: ProfileData.Email,
            selectedDuration: form.meetingDuration ? form.meetingDuration : "15 Minute",
            value: form.meetingTitle,
            meetingpurpose: form.meetingAbout,
            meetingtime: form.meetingTime ? form.meetingTime : new Date().toISOString(),
            inviteUsers: form.guestUserList,
            IsRoom: 1
        }
         dispatch(SendInvitation(payload));   
        // console.log("================action==========",action) 
        setShow(true)
        handelCloseModal()
        setForm({
                    meetingTitle: "",
                    meetingAbout: "",
                    meetingTime: "",
                    meetingDuration: "",
                    guestUserList: []
                })
      
        // console.log("=============response============",response)
        // if (response.data.responseCode == 200) {
        //     setShow(true)
        //     handelCloseModal()
        //     setForm({
        //         meetingTitle: "",
        //         meetingAbout: "",
        //         meetingTime: "",
        //         meetingDuration: "",
        //         guestUserList: []
        //     })
        // }
       
    }

    useEffect(() => {
        GetUserListForTeam().then((res) => {
            setUserList(res.data.response.UsersList)
        }).catch((err) => console.error(err))
    }, [])


    return (
        <div
            role="dialog"
            tabIndex={-1}
            style={{
                background: 'rgba(0, 0, 0, 0.6)'
            }}
            className="modal d-block in modal-theme fade show"
            aria-modal="true"
        >
            <div role="document" className="modal-dialog modal-lg">
                <div className="modal-content">
                    <form
                        noValidate
                        className="ng-invalid ng-touched ng-dirty"
                    >
                        <div className="modal-header">
                            <button className="close" onClick={handelCloseModal} style={{ background: "#003964", border: 0 }} type="button">
                                ×
                            </button>
                            <h4 className="modal-title">
                                Schedule Meeting
                            </h4>
                        </div>
                        <div className="modal-body invite-members-popup">
                            <div className="row">
                                <div
                                    className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                                >
                                    <div className="form-group">
                                        <label className="control-label mb-2">
                                            Meeting Title{" "}
                                        </label>
                                        <input
                                            onChange={(e) => handleChange("meetingTitle", e.target.value)}
                                            className="form-control ng-pristine ng-invalid ng-touched mb-2"
                                            id="groupName"
                                            name="groupName"
                                            placeholder="Please enter meeting title"
                                            required
                                            type="text"
                                        />
                                    </div>
                                </div>
                                <div
                                    className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                                >
                                    <div className="form-group">
                                        <label className="control-label mb-2">
                                            What is the Meeting About?
                                        </label>
                                        <input
                                            onChange={(e) => handleChange("meetingAbout", e.target.value)}
                                            autoComplete="off"
                                            className="form-control ng-untouched ng-pristine ng-valid mb-2"
                                            name="meetingPurpose"
                                            placeholder="Please enter meeting about"
                                            type="text"
                                        />
                                    </div>
                                </div>
                                <div
                                    className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                                >
                                    <div className="form-group">
                                        <div className="radio-check">
                                            <div className="radio-theme">
                                                <input
                                                    className="styled ng-valid ng-dirty ng-touched"
                                                    id="seeMe"
                                                    type="radio"
                                                    value={1}
                                                    checked={formAction.radioButton == 1}
                                                    onChange={(e) => handelFormAction(e.target.value)}
                                                />
                                                <label htmlFor="seeMe">
                                                    Schedule now{" "}
                                                </label>
                                            </div>
                                            <div className="radio-theme">
                                                <input
                                                    className="styled ng-valid ng-dirty ng-touched"
                                                    id="watchMe"
                                                    type="radio"
                                                    defaultValue="watchMe"
                                                    value={2}
                                                    checked={formAction.radioButton == 2}
                                                    onChange={(e) => handelFormAction(e.target.value)}
                                                />
                                                <label htmlFor="watchMe">
                                                    Pick a meeting time{" "}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {formAction && formAction.radioButton == 2 ?
                                    <div
                                        className="col-lg-3 col-md-5 col-sm-5 col-xs-3 mb-3"
                                    >
                                        <div className="form-group">
                                            <label className="control-label mb-2">
                                                Meeting Time
                                            </label>
                                            <div className="calendar-input">
                                                <input
                                                    onChange={(e) => handleChange("meetingTime", e.target.value)}
                                                    className="form-control ng-untouched ng-pristine ng-invalid"
                                                    id="date"
                                                    name="date"
                                                    required
                                                    type="datetime-local"
                                                    min="2023-01-31T08:37"
                                                />
                                                {/* <i
                                                className="fa fa-calendar fa-icon"
                                            /> */}
                                            </div>
                                        </div>
                                    </div> : null
                                }
                                <div
                                    className="col-lg-3 col-md-5 col-sm-5 col-xs-3"
                                >
                                    <div className="form-group">
                                        <label className="control-label mb-2">
                                            Meeting Duration
                                        </label>
                                        <select
                                            onChange={(e) => handleChange("meetingDuration", e.target.value)}
                                            className="form-control ng-pristine ng-valid ng-touched"
                                            id="cars"
                                            name="duration"
                                            required
                                        >
                                            <option selected value="15 minute">
                                                15 Minute
                                            </option>
                                            <option value="30 minute">
                                                30 Minute
                                            </option>
                                            <option value="1 Hour">
                                                1 Hour
                                            </option>
                                            <option value="1 Hour 30 minute">
                                                1 Hour 30 Minute
                                            </option>
                                            <option value="2 Hour">
                                                2 Hour
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div
                                    className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3"
                                >
                                    <div
                                        className="form-group add-group-members"
                                    >
                                        <label className="control-label mb-2">
                                            <strong>Add Participants </strong>
                                        </label>
                                        <SearchAndSelectBox userList={userList} handleChange={handleChange} />
                                    </div>
                                </div>
                                <div
                                    className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                                >
                                    <div className="form-group">
                                        <strong onClick={handelGuestUser}
                                            className="add-guest-user-title mb-2"
                                        >
                                            Add Guest User
                                        </strong>
                                    </div>
                                </div>
                            </div>
                            <div>
                                {formAction && formAction.isGuestUser ? <div className="row mt-2">
                                    <div
                                        className="col-lg-4 col-md-12 col-sm-12 col-xs-12"
                                    >
                                        <div className="form-group">
                                            <label className="control-label mb-2">
                                                Name
                                            </label>
                                            <input
                                                onChange={(e) => handleChange("Name", e.target.value)}
                                                className="form-control ng-pristine ng-invalid ng-touched"
                                                name="Name"
                                                value={guestUser.Name}
                                                placeholder="Guestuser name"
                                                required
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="col-lg-4 col-md-12 col-sm-12 col-xs-12"
                                    >
                                        <div className="form-group">
                                            <label className="control-label mb-2">
                                                Email Address{" "}
                                            </label>
                                            <input
                                                className="form-control ng-pristine ng-invalid ng-touched"
                                                name="email" onChange={(e) => handleChange("email", e.target.value)}
                                                value={guestUser.email}
                                                ng-change="validateEmail(guestUser.email)"
                                                placeholder="Guestuser email"
                                                required
                                                type="email"
                                            />
                                        </div>
                                        {/* <div className="help-block mt-2">
                                            <div>
                                                <div style={{ fontSize: "12px", color: "red" }}>Enter valid email address</div>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div
                                        className="col-lg-4 col-md-12 col-sm-12 col-xs-12"
                                    >
                                        <div className="form-group">
                                            <label className="control-label mb-2">
                                                Phone Number
                                            </label>
                                            <input
                                                onChange={(e) => handleChange("Phone", e.target.value)}
                                                value={guestUser.Phone}
                                                className="form-control ng-pristine ng-invalid ng-touched"
                                                name="number"
                                                placeholder="Guestuser phone number"
                                                required
                                                type="number"
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-3"
                                    >
                                        <div className="form-group">
                                            <button
                                                onClick={handleAddGuestUser}
                                                className="btn btn-theme add-guest-user-btn"
                                                type="button"
                                            >
                                                Add Guest User
                                            </button>
                                        </div>
                                    </div>
                                </div> : null}
                            </div>
                            <div className="row">
                                <div
                                    className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                                    hidden
                                >
                                    <div className="form-group">
                                        <label className="control-label">
                                            Group Members{" "}
                                        </label>
                                    </div>
                                </div>
                                <div
                                    className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-3"
                                    hidden={form.guestUserList.length == 0 || !formAction.showTabel ? true : false}
                                >
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col" style={{ fontSize: "15px" }}>
                                                    Group Members
                                                </th>
                                                <th scope="col" style={{ fontSize: "15px" }}>
                                                    Send Invite to Email Address
                                                </th>
                                                <th scope="col" style={{ fontSize: "15px" }}>
                                                    Send Invite to Phone Number
                                                </th>
                                                <th scope="col" style={{ fontSize: "15px" }}>
                                                    Both{" "}
                                                </th>
                                                <th scope="col" style={{ fontSize: "15px" }}>
                                                    Action
                                                </th>
                                                <th className="text-center">
                                                    <div className="custom_checkbox">
                                                        <input
                                                            onChange={(e) => handleCheckBox("checkAll", e.target.value, "")}
                                                            className="form-control"
                                                            name="all"
                                                            value={'all'}
                                                            type="checkbox"
                                                            checked={formAction.checkAll}
                                                        />
                                                        <label

                                                            className="arrow-label"
                                                            htmlFor="all"
                                                        />
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {form.guestUserList.length !== 0 ?
                                                <TabelFields guestUserList={form.guestUserList} handleCheckBox={handleCheckBox} handleDeleteTableFields={handleDeleteTableFields} />
                                                : null
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                onClick={(e) => handleSendMettingInvite(e)}
                                className="btn btn-theme"
                            // type="submit"
                            >
                                Send Meeting Invite
                            </button>
                        </div>
                    </form>
                </div>
            </div >
        </div >
    )
}

export default ScheduleMeetingModal



export const TabelFields = ({ handleCheckBox, handleDeleteTableFields, guestUserList }: any) => {
    return (
        <>
            {guestUserList.map((item: any, index: number) => {
                return <tr key={index}>
                    <td>
                        {item?.Name}
                    </td>
                    <td>
                        <div className="custom_checkbox">
                            <input
                                onChange={(e) => handleCheckBox("isEmailCheck", e.target.value, index)}
                                className="form-control ng-untouched ng-pristine ng-valid"
                                type="checkbox"
                                id="email0"
                                checked={item.isEmailCheck}
                                value={'isEmailCheck'}
                            />
                            <label className="arrow-label" htmlFor="email0" />
                        </div>
                    </td>

                    <td>
                        <div className="custom_checkbox">
                            <input
                                onChange={(e) => handleCheckBox("isPhoneCheck", e.target.value, index)}
                                type="checkbox"
                                value={'isPhoneCheck'}
                                defaultValue="true"
                                id="random10"
                                checked={item.isPhoneCheck}
                                className="ng-untouched ng-pristine ng-valid"
                            />
                            <label className="arrow-label" htmlFor="random10">
                                &nbsp;
                            </label>
                            <br />
                        </div>
                    </td>

                    <td>
                        <div className="custom_checkbox">
                            <input
                                onChange={(e) => handleCheckBox("isBoth", e.target.value, index)}
                                type="checkbox"
                                value={'isBoth'}
                                checked={item.isBoth}
                                id="choiceProfessional30"
                                className="ng-untouched ng-pristine ng-valid"
                            />
                            <label
                                className="arrow-label"
                                htmlFor="choiceProfessional30"
                            />
                        </div>
                    </td>
                    <td>
                        <a onClick={() => handleDeleteTableFields(index)}
                            className="invite-members-action"
                            href="javascript:void(0);"
                        >
                            <i className="fa fa-trash" />
                        </a>
                    </td>

                </tr>
            })}
        </>
    )
}
