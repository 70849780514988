import { CALL_RESPONSE_EVENT,CONTACT_COLLAPSE } from "constants/common-constants";
import { EventEmitter } from "events";

const eventBus = new EventEmitter();

export default eventBus;

export const sendThirdPersonCallResponse = (data: any) => {
  eventBus.emit(CALL_RESPONSE_EVENT, data);
};


export const contactCollapseEvent = (data: boolean) => {
  eventBus.emit(CONTACT_COLLAPSE, data);
};
