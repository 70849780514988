/*------Entire Call End added by vidushi---*/
import React, { useContext } from "react";
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';


export default function EndCallButton({ handleEndEntireCall }: any) {
  return (
    <div title="End Entire Call">
    <div style={{ marginRight: '30px', textAlign: 'center' }}>
      <button
        style={{
          backgroundColor: "red",
          color: "white",
          border: "none",
          borderRadius: "5px",
          marginLeft: "10px",
          width: '50px'
        }}
        onClick={handleEndEntireCall}
        data-cy-disconnect
      >
        <PhoneDisabledIcon />
      </button>
      <span style={{ display: 'block', marginLeft: '7px' }}>End Call For All</span>
      </div>
    </div>
  );
}
