import { useState, useEffect } from 'react';
import { Participant } from 'twilio-video';
import { useAppState } from 'state';
import { AllUsers } from "actions";
import { useDispatch, useSelector } from 'react-redux';
import { ConnectSignalREnums } from "constants/common-constants";

export function usePagination(participants: Participant[]) {
  const [currentPage, setCurrentPage] = useState(1); // Pages are 1 indexed
  const { maxGalleryViewParticipants } = useAppState();
   const dispatch: any = useDispatch();
  const totalPages = Math.ceil(participants.length / maxGalleryViewParticipants);
  const isBeyondLastPage = currentPage > totalPages;
  // const guestUser = useSelector((state: any) => state);


  useEffect(() => {
    const RoomData = JSON.parse(
        localStorage.getItem("RoomData") || JSON.stringify("")
      );
    if (participants) {     
    
      let data = participants.map((item) => {
        return { name: item?.identity,check:false, SID:item?.sid,RoomId:RoomData?.CurrentroomName,dataType: ConnectSignalREnums.UserInBreakoutCall
        }
      })   
      dispatch(AllUsers(data));    
    }  
  }, [participants])

  useEffect(() => {
    if (isBeyondLastPage) {
      setCurrentPage(totalPages);
    }
  }, [isBeyondLastPage, totalPages]);

  let paginatedParticipants = participants.slice(
    (currentPage - 1) * maxGalleryViewParticipants,
    currentPage * maxGalleryViewParticipants
  );

  return { paginatedParticipants, setCurrentPage, currentPage, totalPages };
}
