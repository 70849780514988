import { AUTH, LOGOUT } from 'constants/store-actions';

const authReducer = (state = { authData: null }, action: { type: string, payload: any }) => {
    switch (action.type) {
        case AUTH:
            localStorage.setItem('profile', JSON.stringify(action.payload))
            return { ...state, authData: action.payload };
        case LOGOUT:
            localStorage.clear();
            return { ...state, authData: null };
        default:
            return state;
    }
};

export default authReducer;