import React, { Dispatch, useEffect, useState } from "react";
import Logo from "assets/images/logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { checkResetPasswordToken, setPassword } from "actions";
import Joi from "joi";
import ExpireLinkPage from "features/set-password/ExpireLinkPage";

interface formDataInterface {
    Password: string;
    confirmPassword: string
    Token: string;
}

const ResetPassword = () => {
    const [loading, setLoading] = useState(false);
    const dispatch: Dispatch<any> = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [errors, setErrors] = useState({ field: "", message: "" });
    const [isValidToken, setisValidToken] = useState(false)

    const schema = Joi.object({
        Password: Joi.string().min(8).required(),
        confirmPassword: Joi.string().min(8).required(),
    }).options({ allowUnknown: true });

    useEffect(() => {
        const Token = location?.search.split("=")[1];
        setFormData({ ...formData, ["Token"]: Token });
        if (!Token) {
            setisValidToken(true)
            return
        }
        dispatch(checkResetPasswordToken(Token, navigate, setLoading))
    }, []);

    const [formData, setFormData] = useState<Partial<formDataInterface>>({});
    const [commonVariables, setCommonVariables] = useState({
        loading: false,
        isLoginForm: true,
        showIcon: true,
        showPassword: false,
        invalid: false,
        submit: false,
        rememberme: false,
    });

    const handleChange = (target: string, value: string) => {
        setFormData({ ...formData, [target]: value });
    };

    const handleResetPasswordSubmit = (e: any) => {
        e.preventDefault();
        const { value, error }: any = schema.validate(formData);
        if (error == undefined) {
            setErrors({
                field: "",
                message: "",
            });
            if (formData.Password !== formData.confirmPassword) {
                setErrors({ field: "confirmPassword", message: "Confirm password and password must be same." });
                return
            }
            const payload = {
                Password: formData.Password,
                Token: formData.Token
            };
            setLoading(true)
            dispatch(setPassword(payload, navigate, setLoading))
            setFormData({
                Password: "",
                confirmPassword: "",
            })
        } else {
            console.log(error);
            const field: any = error.toString().split(" ")[1].replace(/['"]+/g, '');
            const message: any = error.toString().split(":")[1].replace(/"|'/g, '');
            setErrors({ field: field, message: message });
        }
    };

    const Style: any = {
        position: "absolute",
        fontSize: "11px",
        bottom: "-18px",
        width: "100%",
        right: 0,
        textAlign: "left",
        lineHeight: "22px",
        color: "#ff4343",
    };

    return (
        <div>
            {
                !isValidToken ?
                    <div className="login-layout">

                        <section className="user">
                            <video
                                style={{ width: "100%", position: "fixed", zIndex: 0 }}
                                autoPlay
                                loop
                                id="videoPlayer"
                                muted
                            >
                                <source
                                    src="https://smallworldmeet.com/assets/video/login-signup-video.mp4"
                                    type="video/mp4"
                                />
                                Your browser does not support the video tag.
                            </video>
                            <div className="user_options-container">
                                <div
                                    className="user_options-text"
                                    style={{
                                        backgroundImage: 'url("assets/images/home-banner.jpeg")',
                                    }}
                                >
                                    <div className="user_options-unregistered">
                                        <h2 className="user_unregistered-title">Reset Password</h2>
                                    </div>
                                </div>

                                <div className="user_options-forms" id="user_options-forms">
                                    <div className="user_forms-login">
                                        <div className="login-logo">
                                            <img src={Logo} />
                                        </div>
                                        <form className="contact-form-section" onSubmit={handleResetPasswordSubmit}>
                                            <div className="form-field-wrapper">
                                                <div className="field-title">Enter New  Password</div>
                                                <div className="field-password">
                                                    <input
                                                        className={`form-full ${commonVariables.invalid ? "invalid" : ""
                                                            }`}
                                                        type={
                                                            !commonVariables.showPassword ? "password" : "text"
                                                        }
                                                        value={formData.Password}
                                                        name='Password'
                                                        placeholder="Enter Password"
                                                        onChange={(e) =>
                                                            handleChange("Password", e.target.value)
                                                        }
                                                    />
                                                    {commonVariables.showPassword ? (
                                                        <a
                                                            onClick={() =>
                                                                setCommonVariables({
                                                                    ...commonVariables,
                                                                    showPassword: false,
                                                                })
                                                            }
                                                            href="javascript:void(0);"
                                                        >
                                                            <i className="fa fa-eye" aria-hidden="true"></i>
                                                        </a>
                                                    ) : (
                                                        <a
                                                            onClick={() =>
                                                                setCommonVariables({
                                                                    ...commonVariables,
                                                                    showPassword: true,
                                                                })
                                                            }
                                                            href="javascript:void(0);"
                                                        >
                                                            <i className="fa fa-eye-slash" aria-hidden="true"></i>
                                                        </a>
                                                    )}
                                                </div>

                                                {errors.field == 'Password' ? (
                                                    <div className="help-block" style={Style}>
                                                        <div>{errors.message}</div>
                                                    </div>
                                                ) : null}
                                            </div>

                                            <div className="form-field-wrapper">
                                                <div className="field-title">Enter New  Confirm Password</div>
                                                <div className="field-password">
                                                    <input
                                                        className={`form-full ${commonVariables.invalid ? "invalid" : ""
                                                            }`}
                                                        type={
                                                            !commonVariables.showPassword ? "password" : "text"
                                                        }
                                                        value={formData.confirmPassword}
                                                        name="confirmPassword"
                                                        placeholder="Enter Confirm Password"
                                                        onChange={(e) =>
                                                            handleChange("confirmPassword", e.target.value)
                                                        }
                                                    />

                                                    {commonVariables.showPassword ? (
                                                        <a
                                                            onClick={() =>
                                                                setCommonVariables({
                                                                    ...commonVariables,
                                                                    showPassword: false,
                                                                })
                                                            }
                                                            href="javascript:void(0);"
                                                        >
                                                            <i className="fa fa-eye" aria-hidden="true"></i>
                                                        </a>
                                                    ) : (
                                                        <a
                                                            onClick={() =>
                                                                setCommonVariables({
                                                                    ...commonVariables,
                                                                    showPassword: true,
                                                                })
                                                            }
                                                            href="javascript:void(0);"
                                                        >
                                                            <i className="fa fa-eye-slash" aria-hidden="true"></i>
                                                        </a>
                                                    )}
                                                </div>

                                                {errors.field == "confirmPassword" ? (
                                                    <div className="help-block" style={Style}>
                                                        <div>{errors.message}</div>
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="form-field-wrapper">
                                                <button type="submit" className="field-button">
                                                    Save{" "}
                                                    {commonVariables.loading && (
                                                        <i className="fa fa-spinner fa-spin"></i>
                                                    )}
                                                    {loading ? (
                                                        <div style={{ left: "250px", marginTop: "-5px", fontSize: "8px" }} className="spinner-border spinner-border-sm text-light" role="status"></div>
                                                    ) : null}
                                                </button>
                                            </div>
                                            <div className="form-field-wrapper">
                                                <a href="/" className="bottom-link">
                                                    Back to Login
                                                </a>
                                            </div>
                                        </form>
                                        <div className="login-footer">
                                            <a href="javascript:void(0)">
                                                {" "}
                                                © 2022-2023 THE AUTHENTIC CONSORTIUM. All rights reserved Privacy
                                                Policy
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    : <ExpireLinkPage />}
        </div>
    );
};

export default ResetPassword;