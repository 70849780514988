import { useEffect, useState } from "react";
import { getUserDetails, updateGeneralDetail, getUserProfileDropDown } from "actions/account";
import { useSelector } from "react-redux";
import { useAppDispatch } from "hooks";

const GernalDetails = ({ setShow }: any) => {
  const initialFormData: any = {
    Address: "",
    BasicDetailCountryId: "",
    BasicDetailStateId: "",
    City: "",
    Contact: "",
    CountryName: "",
    DOB: "",
    Email: "",
    FirstName: "",
    FullName: "",
    Gender: "",
    GenderId: "",
    LastName: "",
    Phone: "",
    StateName: "",
    ZipCode: "",
  };

  const dispatch = useAppDispatch();
  const Account = useSelector((state: any) => state.account);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [general, setGeneral] = useState(false);
  const dropDown = useSelector((state: any) => state.account.dropDown);

  const response = ({
    Address: Account?.account?.Address,
    BasicDetailCountryId: Account?.account?.BasicDetailCountryId,
    BasicDetailStateId: Account?.account?.BasicDetailStateId,
    City: Account?.account?.City,
    Contact: Account?.account?.Contact,
    CountryName: Account?.account?.CountryName,
    DOB: Account?.account?.DOB,
    Email: Account?.account?.Email,
    FirstName: Account?.account?.FirstName,
    FullName: Account?.account?.FullName,
    Gender: Account?.account?.Gender,
    GenderId: Account?.account?.GenderId,
    LastName: Account?.account?.LastName,
    Phone: Account?.account?.Phone,
    StateName: Account?.account?.StateName,
    ZipCode: Account?.account?.ZipCode,
  })

  const handlesubmit = () => {
    const payload = {
      Address: formData.Address,
      BasicDetailCountryId: formData.BasicDetailCountryId,
      BasicDetailStateId: formData.BasicDetailStateId,
      City: formData.City,
      Contact: formData.Contact,
      CountryName: formData.CountryName,
      DOB: formData.DOB,
      Email: formData.Email,
      FirstName: formData.FirstName,
      FullName: formData.FullName,
      Gender: formData.Gender,
      GenderId: formData.GenderId,
      LastName: formData.LastName,
      StateName: formData.StateName,
      ZipCode: formData.ZipCode,
      Phone: formData.Phone,
    };

    dispatch(updateGeneralDetail(payload, setLoading));
    setEdit(false);
    setShow(true);
  };

  const handleClose = () => {
    setFormData(response)
    setEdit(false)
  }

  useEffect(() => {
    dispatch(getUserDetails());
    dispatch(getUserProfileDropDown());
  }, []);

  useEffect(() => {
    if (Account.account) {
      setFormData(response);
    }
  }, [Account]);
  return (
    <div className="single-section">
      <div className="section-name">
        <span>GENERAL DETAILS</span>
        <div className="right-action">
          {!general ? (
            <a onClick={() => setGeneral(true)} className="show-hide-panel">
              <span>Click Here to close</span>
            </a>
          ) : (
            <a onClick={() => setGeneral(false)} className="show-hide-panel">
              <span>Click Here to open</span>
            </a>
          )}
        </div>
      </div>
      {!general ? (
        <div className="section-body">
          <a
            onClick={() => setEdit(true)}
            className="edit-button fa fa-edit"
          >
            <span>Edit</span>
          </a>
          <div className="section-body-content">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="single-line">
                  <div className="form-group">
                    <div className="label-title">First Name</div>
                    {!edit ? (
                      <div className="label-data">{Account?.account?.FirstName}</div>
                    ) : (
                      <div className="label-data">
                        <input
                          className="form-control"
                          type="text"
                          value={formData.FirstName}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              FirstName: e.target.value,
                            });
                          }}
                          placeholder="Enter First Name"
                        ></input>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="single-line">
                  <div className="form-group">
                    <div className="label-title">Last Name</div>
                    {!edit ? (
                      <div className="label-data">{Account?.account?.LastName}</div>
                    ) : (
                      <div className="label-data">
                        <input
                          className="form-control"
                          type="text"
                          value={formData.LastName}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              LastName: e.target.value,
                            });
                          }}
                          placeholder="Enter Last Name"
                        ></input>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="single-line">
                  <div className="form-group">
                    <div className="label-title">Email Address</div>
                    {!edit ? (
                      <div className="label-data">{Account?.account?.Email}</div>
                    ) : (
                      <div className="label-data">
                        <input
                          className="form-control"
                          type="text"
                          defaultValue={formData.Email}
                          disabled={true}
                          placeholder="Enter Email"
                        ></input>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="single-line">
                  <div className="form-group">
                    <div className="label-title">Birth Date</div>
                    {!edit ? (
                      <div className="label-data">{Account?.account?.DOB}</div>
                    ) : (
                      <div className="label-data">
                        <input
                          className="form-control"
                          // 
                          type="date"
                          value={formData.DOB}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              DOB: e.target.value,
                            });
                          }}
                        ></input>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="single-line">
                  <div className="form-group">
                    <div className="label-title">Gender</div>
                    {!edit ? (
                      <div className="label-data">{Account?.account?.Gender}</div>
                    ) : (
                      <div className="label-data">
                        <select
                          className="form-control"
                          value={formData.GenderId}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              GenderId: e.target.value,
                            });
                          }}
                        >
                          {dropDown.GenderList.map((item: any, index: any) => (
                            <option key={item.Key} value={item.Key}>{item.Value}</option>
                          ))}
                        </select>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="single-line">
                  <div className="form-group">
                    <div className="label-title">Mobile</div>
                    {!edit ? (
                      <div className="label-data">{Account?.account?.Phone}</div>
                    ) : (
                      <div className="label-data">
                        <input
                          className="form-control"
                          value={formData.Phone}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              Phone: e.target.value,
                            });
                          }}
                          type="number"
                          placeholder="Enter Mobile Number"
                        ></input>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="single-line">
                  <div className="form-group">
                    <div className="label-title">Phone</div>
                    {!edit ? (
                      <div className="label-data">{Account?.account?.Contact}</div>
                    ) : (
                      <div className="label-data">
                        <input
                          className="form-control"

                          value={formData.Contact}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              Contact: e.target.value,
                            });
                          }}
                          type="number"
                          placeholder="Enter Phone Number"
                        ></input>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="single-line">
                  <div className="form-group">
                    <div className="label-title">Address</div>
                    {!edit ? (
                      <div className="label-data">{Account?.account?.Address}</div>
                    ) : (
                      <div className="label-data">
                        <input
                          className="form-control"

                          value={formData.Address}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              Address: e.target.value,
                            });
                          }}
                          type="text"
                          placeholder="Enter Address"
                        ></input>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="single-line">
                  <div className="form-group">
                    <div className="label-title">Country</div>
                    {!edit ? (
                      <div className="label-data">{Account?.account?.CountryName}</div>
                    ) : (
                      <div className="label-data">
                        <input
                          className="form-control"
                          type="text"
                          value={formData.CountryName}
                          placeholder="Enter Country"
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              CountryName: e.target.value,
                            });
                          }}
                        ></input>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="single-line">
                  <div className="form-group">
                    <div className="label-title">State</div>
                    {!edit ? (
                      <div className="label-data">{Account?.account?.StateName}</div>
                    ) : (
                      <div className="label-data">
                        <select
                          className="form-control"
                          value={formData.BasicDetailStateId}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              BasicDetailStateId: e.target.value,
                            });
                          }}
                        >
                          {dropDown.StateList.map((item: any, index: any) => (
                            <option key={item.key} value={item.Key}>{item.Value}</option>
                          ))}
                        </select>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="single-line">
                  <div className="form-group">
                    <div className="label-title">City</div>
                    {!edit ? (
                      <div className="label-data">{Account?.account?.City}</div>
                    ) : (
                      <div className="label-data">
                        <input
                          className="form-control"
                          type="text"
                          value={formData.City}
                          placeholder="Enter City Name"
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              City: e.target.value,
                            });
                          }}
                        ></input>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="single-line">
                  <div className="form-group">
                    <div className="label-title">Zip Code</div>
                    {!edit ? (
                      <div className="label-data">{Account?.account?.ZipCode}</div>
                    ) : (
                      <div className="label-data">
                        <input
                          className="form-control"

                          type="text"
                          value={formData.ZipCode}
                          placeholder="Enter Zipcode"
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              ZipCode: e.target.value,
                            });
                          }}
                        ></input>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {edit ? (
            <div className="action-profile">
              <button
                onClick={() => {
                  handlesubmit();
                }}
                type="submit"
                className="btn btn-theme btn-sm"
              >
                Save
              </button>
              <button
                className="btn btn-gray btn-sm"
                onClick={() => handleClose()}
              >
                Close
              </button>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default GernalDetails;
