import { SET_ROOM, UPDATE_ROOM, FORMAT_ROOM, BREAKOUT_ROOM, FREE_BREAKROOM,SET_RAISEHAND,SET_MANUAL,SET_AUTOMATICALLY,SET_MODAL,SET_TIMER } from 'constants/store-actions';

const initialState = {
    room: {},
    isCall: false,
    breakroom: [],
    showManual: false,
    showAutomatic: false,
    showModal:false,
    timer:0,


}
const userReducer = (state = initialState, action: { type: string, payload: any }) => {
    switch (action.type) {
        case SET_ROOM:
            return { room: action.payload, isCall: true };
        case UPDATE_ROOM:
            return { room: action.payload, isCall: true };
        case SET_MANUAL:
            return {...state, showManual: action.payload, showAutomatic:false,showModal:false};
        case SET_AUTOMATICALLY:
            return {...state, showAutomatic: action.payload,showManual:false,showModal:false };
        case SET_TIMER:
                return {...state, timer: action.payload };
    
            case SET_MODAL:
            return {...state, showModal: action.payload,showManual:false , showAutomatic:false};
        case FORMAT_ROOM:
            return initialState;
        case BREAKOUT_ROOM:
            return { ...state, breakroom: action.payload, isCall: true }
        case FREE_BREAKROOM:
            return { ...state, breakroom: [], isCall: false }
        default:
            return state;
    }
};

export default userReducer;