import axios from 'axios';

const API = axios.create({ baseURL: 'https://api.smallworldmeet.com/api/' });

API.interceptors.request.use((req: any) => {
    if (localStorage.getItem('token')) {
        req.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    }
    return req;
});

export default API;
// Authentication API
export const signIn = (formData: any) => API.post('Account/Login', formData);
export const signUp = (formData: any) => API.post('Account/Register', formData);
export const setPassword = (formData: any) => API.post('Account/SetPassword', formData);
export const setForgotPassword = (formData: any) => API.post('Account/ForgotPassword', formData);
export const resendLink = (formData: any) => API.post('Account/ForgotPassword', formData);
export const checkResetPasswordToken = (formData: any) => API.post('Account/CheckResetPasswordToken', formData);
export const changePassword = (formData: any) => API.post('Account/ChangePassword', formData);

// User List
export const getUserListForChat = () => API.get('User/GetUserListForChat');
export const GetUserListForTeam = () => API.get('User/GetUserListForTeam');
export const InsertUserFavoriteChatRoom = (formData: any) => API.post('User/InsertUserFavoriteChatRoom', formData);
export const deleteChatRoomParticipants = (formData: any) => API.post('User/DeleteChatRoomParticipants', formData);
//online api added by vidushi
export const GetOnlineUsers = () => API.get('User/GetOnlineUsers');
export const InsertOnlineAndBusyUsers = (formData: any) => API.post('User/InsertOnlineAndBusyUsers', formData);
export const UpdateOnlineUsers = (formData: any) => API.post('User/UpdateOnlineUsers', formData);
export const DeleteOnlineUser = (formData: any) => API.post('User/DeleteOnlineUser', formData);

// Chat Message
export const getChatMessages = (data: any) => API.post('User/GetChat', data)
export const sendMessage = (data: any) => API.post('User/InsertChat', data);
export const sendFile = (data: any) => API.post('User/UploadChatFile', data);

//Guest User
export const createGuestUser = (data: any) => API.post('User/InsertGuestUser',data)
export const insertGuestchat = (data: any) => API.post('User/InsertGuestChat',data)
export const UploadGuestChatFile = (data: any) => API.post('User/UploadGuestChatFile',data)
/*---------publicMeeting api added by vidushi----*/
export const InsertPublicMeetingUsers = (formData: any) => API.post('User/InsertPublicMeetingUsers',formData)
export const DeletePublicMeetingUsers = (formData:any) => API.post('User/DeletePublicMeetingUsers',formData)
export const DeletePublicMeetingForAllUsers = (formData:any) => API.post('User/DeletePublicMeetingForAllUsers',formData)
export const GetPublicMeetingUsers = () => API.get('User/GetPublicMeetingUsers')
// My Account
export const getUserDetails = () => API.get('Account/GetUserDetail')
export const basicdetailSubmit = (formData: any) => API.post('Account/UpdateGeneralDetail', formData)
export const updateGeneralDetail = (formData: any) => API.post('/Account/UpdateGeneralDetail', formData)
export const getUserProfileDropDown = () => API.get('/Account/GetUserProfileDropDown')
export const uploadProfileImage = (data: { formFile: any }) => API.post('/Account/UploadProfileImage', data);


// Metting Invitation added by vidushi
export const sendMeetingInvitation = (formData: any) => API.post('User/SendMeetingInvitation', formData);


// Wild Card Method
export const getMethod = (endpoint: string, body: any) => API.get(endpoint, body);

//insert User In Call added by vidushi
export const InsertUsersInCall = (formData: any) => API.post('User/InsertUserInCall',formData)
export const RemoveUsersInCall = (formData: any) => API.post('User/RemoveUsersInCall', formData);
export const GetUsersInCall = () => API.get('User/GetUsersInCall');

//video rooms
export const GetRooms = () => API.get('video/rooms')
//Check CheckMeetingValid  url
export const CheckMeetingValid = (formData: any) => API.post('User/CheckMeetingValid', formData);