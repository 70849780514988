import Logo from "assets/images/logo.png";
import { Link } from "react-router-dom";

const Style: any = {
    position: "absolute",
    fontSize: "11px",
    bottom: "-18px",
    width: "100%",
    right: 0,
    textAlign: "left",
    lineHeight: "22px",
    color: "#ff4343",
};

const Main = ({ loading, commonVariables, setCommonVariables, formData, handleChange, handlePasswordSubmit, errors }: any) => {

    return (
        <div className="login-layout">

            <section className="user">
                <video
                    style={{ width: "100%", position: "fixed", zIndex: 0 }}
                    autoPlay
                    loop
                    id="videoPlayer"
                    muted
                >
                    <source
                        src="https://smallworldmeet.com/assets/video/login-signup-video.mp4"
                        type="video/mp4"
                    />
                    Your browser does not support the video tag.
                </video>
                <div className="user_options-container">
                    <div
                        className="user_options-text"
                        style={{
                            backgroundImage: 'url("assets/images/home-banner.jpeg")',
                        }}
                    >
                        <div className="user_options-unregistered">
                            <h2 className="user_unregistered-title">Set Password</h2>
                        </div>
                    </div>

                    <div className="user_options-forms" id="user_options-forms">
                        <div className="user_forms-login">
                            <div className="login-logo">
                                <img src={Logo} />
                            </div>
                            <form
                                className="contact-form-section"
                                onSubmit={handlePasswordSubmit}
                            >
                                <div className="form-field-wrapper">
                                    <div className="field-title">Enter Password</div>
                                    <div className="field-password">
                                        <input
                                            className={`form-full ${commonVariables.invalid ? "invalid" : ""
                                                }`}
                                            type={
                                                !commonVariables.showPassword ? "password" : "text"
                                            }
                                            value={formData.Password}
                                            name="Password"
                                            placeholder="Enter Password"
                                            onChange={(e) =>
                                                handleChange("Password", e.target.value)
                                            }
                                        />
                                        {commonVariables.showPassword ? (
                                            <a
                                                onClick={() =>
                                                    setCommonVariables({
                                                        ...commonVariables,
                                                        showPassword: false,
                                                    })
                                                }
                                                href="javascript:void(0);"
                                            >
                                                <i className="fa fa-eye" aria-hidden="true"></i>
                                            </a>
                                        ) : (
                                            <a
                                                onClick={() =>
                                                    setCommonVariables({
                                                        ...commonVariables,
                                                        showPassword: true,
                                                    })
                                                }
                                                href="javascript:void(0);"
                                            >
                                                <i className="fa fa-eye-slash" aria-hidden="true"></i>
                                            </a>
                                        )}
                                    </div>

                                    {errors.field == 'Password' ? (
                                        <div className="help-block" style={Style}>
                                            <div>{errors.message}</div>
                                        </div>
                                    ) : null
                                    }
                                </div>

                                <div className="form-field-wrapper">
                                    <div className="field-title">Enter Confirm Password</div>
                                    <div className="field-password">
                                        <input
                                            className={`form-full ${commonVariables.invalid ? "invalid" : ""
                                                }`}
                                            type={
                                                !commonVariables.showPassword ? "password" : "text"
                                            }
                                            name="ConfirmPassword"
                                            value={formData.ConfirmPassword}
                                            placeholder="Enter Confirm Password"
                                            onChange={(e) =>
                                                handleChange("ConfirmPassword", e.target.value)
                                            }
                                        />

                                        {commonVariables.showPassword ? (
                                            <a
                                                onClick={() =>
                                                    setCommonVariables({
                                                        ...commonVariables,
                                                        showPassword: false,
                                                    })
                                                }
                                                href="javascript:void(0);"
                                            >
                                                <i className="fa fa-eye" aria-hidden="true"></i>
                                            </a>
                                        ) : (
                                            <a
                                                onClick={() =>
                                                    setCommonVariables({
                                                        ...commonVariables,
                                                        showPassword: true,
                                                    })
                                                }
                                                href="#"
                                            >
                                                <i className="fa fa-eye-slash" aria-hidden="true"></i>
                                            </a>
                                        )}
                                    </div>

                                    {errors.field == 'ConfirmPassword' ? (
                                        <div className="help-block" style={Style}>
                                            <div>{errors.message}</div>
                                        </div>
                                    ) : null
                                    }
                                </div>
                                <div className="form-field-wrapper">
                                    <button type="submit" className="field-button">
                                        Save{" "}
                                        {commonVariables.loading && (
                                            <i className="fa fa-spinner fa-spin"></i>
                                        )}
                                        {loading ? (
                                            <div style={{ left: "250px", marginTop: "-5px", fontSize: "8px" }} className="spinner-border spinner-border-sm text-light" role="status"></div>
                                        ) : null}
                                    </button>
                                </div>
                                <div className="form-field-wrapper">
                                    <Link to="/" className="bottom-link">
                                        Back to Login
                                    </Link>
                                </div>
                            </form>
                            <div className="login-footer">
                                <a href="#">
                                    © 2022-2023 THE AUTHENTIC CONSORTIUM. All rights reserved Privacy
                                    Policy
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Main;