import { DEFAULT_SETTING, SET_MUTED_PARTICIPANT, SET_SETTINGS } from 'constants/store-actions';

const initialState = {
    onGoingCall: false,
    mutedParticipantList: []
}

interface ActionPayloadInterface {
    name: string;
    value: any;
}
const setting = (state = initialState, action: { type: string, payload: ActionPayloadInterface }) => {
    switch (action.type) {
        case SET_SETTINGS:
            const data = action.payload;
        
            return { ...state, [data.name]: data.value };
        case DEFAULT_SETTING:
            return initialState;
        case SET_MUTED_PARTICIPANT:
            return { ...state, mutedParticipantList: action.payload.value }
        default:
            return state;
    }
};

export default setting;