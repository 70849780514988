import React from 'react'
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { toastSuccess } from "services";

const RightSide = ({ handleCollapse }: any) => {

    const guestLink = `https://smallworldmeet.com/guest-meeting/get-together?public=true`;

    const handleCopyGuestLink = () => {
        navigator.clipboard.writeText(guestLink);
        toastSuccess("Meeting Link Copied Successfully.");
      };
    
      const handleCopyPublicLink = () => {
        window.open(guestLink, "_self");
      };

    return (
        <>
            {/* <a href="javascript: void(0);" onClick={handleCollapse} className="top-cafe-btn"> */}
                {/* <i className="fa fa-angle-up"></i> */}
            {/* </a> */}
            <div className="search-right-side coffee-icon">
                <div className="parent-div">
                    <div className="feedback-group">
                        {/* <div className="invite-others">
                            <p className="cafe-instructions">
                                If you would like to video chat and collaborate with
                                someone, simply
                            </p>
                            <ol className="cafe-instructions-list">
                                <li>
                                    Run your cursor over that person's name in the CONTACTS
                                    list on the left of this page.
                                </li>
                                <li>
                                    Make sure they have a{" "}
                                    <strong style={{ color: "#21d500" }}>green </strong> light
                                    next to their name indicating they are 'in' the
                                    application.
                                </li>
                                <li>
                                    Then, to call them simply click on the green video icon.{" "}
                                    <i
                                        className="fa fa-video-camera"
                                        style={{ fontSize: "24px", color: "#21d500" }}
                                    ></i>
                                </li>
                            </ol>
                            Select a user/group to communicate now
                            <br />
                            or
                            <br/>
                            click below button to join public meeting
                            <br/>
                            <div
                            style={{
                            //   background: "red",
                            marginTop:"10px",
                              paddingRight: "10px",
                              borderRadius: "5px",
                            }}
                          >
                            <div
                              onClick={handleCopyPublicLink}
                            //   style={{backgroundColor:"#003964"}}
                              className="btn btn-light"
                            >
                              Cafe Get Together
                            </div>
                            <i title="copy link">
                              <ContentCopyIcon
                                style={{
                                  marginLeft: "5px",
                                  fontSize: "23px",
                                  cursor: "pointer",
                                  marginTop: "0px",
                                }}
                                onClick={handleCopyGuestLink}
                              />
                            </i>
                          </div> */}
                            {/* or <br />
                            <a href="javascript: void(0);">Schedule a meeting here</a> */}
                        </div>
                    </div>
                </div>
            {/* </div> */}
        </>
    )
}

export default RightSide