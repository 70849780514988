import UserInfoCardItem from "./UserInfoCardItem";

const UserInfoCard = ({ list, heading, IsFavorite, setHandleComponentRightSide }: any) => {
    const renderComponent = (user: any, index: number) => <UserInfoCardItem setHandleComponentRightSide={setHandleComponentRightSide} IsFavorite={IsFavorite} key={index} user={user} /> 
  
    return (
        <>
            {list && list.length ? <>
                <div className="user-sub-heading" dangerouslySetInnerHTML={{ __html: heading }}></div>
                <div className="h-100">
                    {list.map(renderComponent)}
                   
                </div>
            </> : null}
        </>)
}

export default UserInfoCard;