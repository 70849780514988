import PhoneIcon from "@mui/icons-material/Phone";
import PhoneDisabledIcon from "@mui/icons-material/PhoneDisabled";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import userImage from "assets/images/default-user.png";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useContext } from "react";
import { removeGuestUser } from "actions";
import { ConnectSignalREnums } from "constants/common-constants";
import { SignalRContext } from "services/signal-r-service";

const IncomingCallList = ({ handleIncomingCallpopup }: any) => {
  const [List, setList] = useState([]);
  const dispatch: any = useDispatch();
  const connection = useContext(SignalRContext);
  const { guestUser } = useSelector((state: any) => state.guest);

  const handleRejectCall = (user: any) => {
    dispatch(removeGuestUser(user));
    const SingleUser = {
      ...user,
      dataType: ConnectSignalREnums.GuestCallRejected,
    };
    if (connection) {
      connection.send("DataInSignalR", JSON.stringify(SingleUser));
    }
  };

  const handleAcceptCall = (user: any) => {
    const RoomData = JSON.parse(localStorage.getItem("RoomData")||"")
    const SingleUser = {
      ...user,
      ChatRoomId:RoomData.ChatRoomId,
      dataType: ConnectSignalREnums.GuestCallAccepted,
    };
    if (connection) {
      connection.send("DataInSignalR", JSON.stringify(SingleUser));
      dispatch(removeGuestUser(user));
    }
  };

  useEffect(() => {
    setList(guestUser);
  }, [guestUser]);

  return (
    <div
      role="dialog"
      tabIndex={-1}
      style={{
        background: "rgba(0, 0, 0, 0.6)",
        zIndex: 9999999,
      }}
      className="modal d-block in modal-theme fade show"
      aria-modal="true"
    >
      <div role="document" className="modal-dialog modal-lg">
        <div className="modal-content">
          <form noValidate className="ng-invalid ng-touched ng-dirty">
            <div className="modal-header">
              <button
                className="close"
                onClick={handleIncomingCallpopup}
                style={{ background: "#003964", border: 0 }}
                type="button"
              >
                ×
              </button>
              <h4 className="modal-title">Incoming Calls</h4>
            </div>
            <div
              className="modal-body invite-members-popup"
              style={{
                height: "200px",
                maxHeight: "800px",
                overflow: "scroll",
              }}
            >
              {!List.length ? <p style={{textAlign:"center"}}>No Notification Found</p> : null}
              {List &&
                List.map((item: any, index: number) => {
                  return (
                    <div className="row mt-2" key={index}>
                      <div
                        style={{
                          background: "rgb(225 224 224)",
                          fontWeight: 600,
                        }}
                        className="col-md-12 d-flex rounded fw-600 justify-content-between align-items-center p-2"
                      >
                        <div className="fs-6">
                          <img
                            src={userImage}
                            style={{ width: "30px", marginRight: "10px" }}
                            alt="user-image"
                          />
                          {item.name}
                        </div>
                        <div>
                          <div
                            className="btn btn-success btn-sm mx-2"
                            onClick={() => handleAcceptCall(item)}
                          >
                            <PhoneIcon />
                          </div>
                          <div
                            className="btn btn-danger btn-sm"
                            onClick={() => handleRejectCall(item)}
                          >
                            <PhoneDisabledIcon />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="modal-footer"></div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default IncomingCallList;
