
import { useSearchParams } from "react-router-dom";
import Footer from "./Footer"
import Header from "./header/Header"
import {useContext } from "react";
import { SignalR, SignalRContext } from 'services/signal-r-service'

const Layout = (props: any) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const publicLink = searchParams.get("public");
    const Id = searchParams.get("id");
    const connection = useContext(SignalRContext);
    return (
        <SignalRContext.Provider value={SignalR}>
           {publicLink || Id ?<div style={{marginTop:"50px"}}>
           </div>: <Header/>}
            {props.children}
            <Footer />
        </SignalRContext.Provider>
    )
}

export default Layout