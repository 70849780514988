export const DEFAULT_VIDEO_CONSTRAINTS: MediaStreamConstraints['video'] = {
  width: 1280,
  height: 720,
  frameRate: 24,
};

// These are used to store the selected media devices in localStorage
export const SELECTED_AUDIO_INPUT_KEY = 'TwilioVideoApp-selectedAudioInput';
export const SELECTED_AUDIO_OUTPUT_KEY = 'TwilioVideoApp-selectedAudioOutput';
export const SELECTED_VIDEO_INPUT_KEY = 'TwilioVideoApp-selectedVideoInput';

// This is used to store the current background settings in localStorage
export const SELECTED_BACKGROUND_SETTINGS_KEY = 'TwilioVideoApp-selectedBackgroundSettings';

export const GALLERY_VIEW_ASPECT_RATIO = 9 / 16; // 16:9
export const GALLERY_VIEW_MARGIN = 3;

export enum ConnectSignalREnums {
  VideoChat = 1,
  UserInChat = 2,
  UserInCall = 3,
  EndCallUserBeforeStart = 4,
  UserDeclineCall = 5,
  NewCallGroup = 6,
  UserUnavailableForCall = 7,
  CallAccept = 8,
  OnlineUser = 102,
  CallEnd = 9,
  UpdateUserStatus = 10,
  NewUserRegister = 11,
  GuestIdAfterJoin = 12,
  GetOnGoingCalls = 13,
  UserInBreakoutCall = 14,
  RequestPrivatecall = 15,
  RequestPrivatecallResponse = 16,
  PrivatePublicCallToastr = 17,
  GuestCallAccepted = 18,
  RequestForGuestCall = 19,
  GuestCallRejected = 20,
  GuestInChat= 21,
  EndEntireCall=22,
  RaiseHand = 25,
  DownRaiseHand = 26,
  IsBusy=23,
  joinCall=24,
  PublicMeetingUsers=100,
  Reactions = 27
}

// Dashboard Right Side
export enum ComponentRightSide {
  RightSide = 1,
  ChatFeedback = 2,
  ChatBox = 3,
  RoomComponent = 4
}

export const CALL_RESPONSE_EVENT = 'CALL_RESPONSE_EVENT'
export const CONTACT_COLLAPSE = 'CONTACT_COLLAPSE'