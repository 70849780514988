export const AUTH = 'AUTH';

export const DEFAULT_VIDEO_CONSTRAINTS: MediaStreamConstraints['video'] = {
  width: 1280,
  height: 720,
  frameRate: 24,
};

// These are used to store the selected media devices in localStorage
export const SELECTED_AUDIO_INPUT_KEY = 'TwilioVideoApp-selectedAudioInput';
export const SELECTED_AUDIO_OUTPUT_KEY = 'TwilioVideoApp-selectedAudioOutput';
export const SELECTED_VIDEO_INPUT_KEY = 'TwilioVideoApp-selectedVideoInput';

// This is used to store the current background settings in localStorage
export const SELECTED_BACKGROUND_SETTINGS_KEY = 'TwilioVideoApp-selectedBackgroundSettings';

export const GALLERY_VIEW_ASPECT_RATIO = 9 / 16; // 16:9
export const GALLERY_VIEW_MARGIN = 2;

export const TOGGLE_LOADING = 'TOGGLE_LOADING';

// User Reducer
export const LOGOUT = 'LOGOUT';
export const GET_USER_LIST = 'GET_USER_LIST';
export const UPDATE_CHAT_USER = 'UPDATE_CHAT_USER';
export const UPDATE_CHAT_ROOM_ID = 'UPDATE_CHAT_ROOM_ID';
export const GET_USER_IN_CALL= 'GET_USER_IN_CALL';
export const USER_STATUS = 'USER_STATUS';
export const INSERT_USERS_IN_CALL = 'INSERT_USERS_IN_CALL';
export const GET_ONLINE_USERS = 'GET_ONLINE_USERS';

// Chat Messages
export const GET_CHAT_MESSAGES = "GET_CHAT_MESSAGES";
export const FORMAT_CHAT_MESSAGES = "FORMAT_CHAT_MESSAGES";
export const CHAT_LOADING = 'CHAT_LOADING'
export const INSERT_MESSAGE = "INSERT_MESSAGE";
export const INSERT_FILES = "INSERT_FILES"

// Room Reducer
export const SET_ROOM = 'SET_ROOM';
export const UPDATE_ROOM = 'UPDATE_ROOM';
export const FORMAT_ROOM = 'FORMAT_ROOM';
export const BREAKOUT_ROOM = 'BREAKOUT_ROOM';
export const FREE_BREAKROOM = 'FREE_BREAKROOM';

//guest users
export const ADD_GUEST_USER = "ADD_GUEST_USER"
export const REMOVE_GUEST_USER = "REMOVE_GUEST_USER"
export const INSERT_GUEST_CHAT_MESSAGES = "INSERT_GUEST_CHAT_MESSAGES"
export const INSERT_PUBLIC_MEETING_USERS = "INSERT_PUBLIC_MEETING_USERS"
export const GET_INSERT_PUBLIC_MEETING_USERS = "GET_INSERT_PUBLIC_MEETING_USERS"
export const GET_MEETTING_URL = "GET_MEETTING_URL"
export const CHECK_MEETTING_URL = "CHECK_MEETTING_URL"
export const GET_ONLINE_USERS_EVENT = "GET_ONLINE_USERS_EVENT"
// Account Reducer
export const GET_DETAILS = 'GET_DETAILS';
export const DETAIL_SUBMIT = 'DETAIL_SUBMIT'
export const GET_DROPDOWN = 'GET_DROPDOWN'
export const MENTOR_DROPDOWN = 'MENTOR_DROPDOWN'
export const CREATERIA_SUBMIT = 'CREATERIA_SUBMIT'
export const GET_USER_DETAILS = 'GET_USER_DETAILS'
export const PROFESSTION_SUBMIT_UPDATE = 'PROFESSTION_SUBMIT_UPDATE'
export const THINGS_TO_DO_FOR_FUN = 'THINGS_TO_DO_FOR_FUN'
export const PROFESSTION_SERVICE_NEED_UPDATE = 'PROFESSTION_SERVICE_NEED_UPDATE'
export const ACCOMPLISH_SUBMIT_UPDATE = 'ACCOMPLISH_SUBMIT_UPDATE'
export const UPLOAD_PROFILE_IMAGE = 'UPLOAD_PROFILE_IMAGE'

// Setting Reducer
export const SET_SETTINGS = 'SET_SETTINGS'
export const DEFAULT_SETTING = "DEFUALT_SETTING"
export const TOGGLE_ONGOING_CALL = "TOGGLE_ONGOING_CALL"
export const SET_MUTED_PARTICIPANT = 'SET_MUTED_PARTICIPANT'

//ROOMS
export const GET_INSERT_ROOM_MEETING_USERS ='GET_INSERT_ROOM_MEETING_USERS'

//participants
export const GET_All_PARTICIPANTS ='GET_All_PARTICIPANTS'
export const REMOVE_All_PARTICIPANTS ='REMOVE_All_PARTICIPANTS'
export const SET_MANUAL ='SET_MANUAL'
export const SET_AUTOMATICALLY ='SET_AUTOMATICALLY'
export const SET_MODAL ='SET_MODAL'
export const SET_TIMER ='SET_TIMER'
export const SET_RAISEHAND ='SET_RAISEHAND'
export const SET_REACTION ='SET_REACTION'
export const REMOVE_RAISEHAND ='REMOVE_RAISEHAND'




