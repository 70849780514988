/* -----Reactions done By vidushi--------- */
import React, { useContext, useState } from 'react';
import { IconButton } from '@material-ui/core';
import TagFacesIcon from '@material-ui/icons/TagFaces';
import { SignalRContext } from "services/signal-r-service";
import { ConnectSignalREnums } from "constants/common-constants";
import useVideoContext from 'hooks/useVideoContext/useVideoContext';
import { toast } from 'react-toastify';
import FloatingEmoji from 'components/Dashboard/LeftSide/FloatingHeart';
import { useDispatch, useSelector } from 'react-redux';
import { SET_REACTION } from 'constants/store-actions';

// export default function Reacts({ handleDisconnect }: any) {
export default function Reacts({connection}:any) {
  const [isHovered, setIsHovered] = useState(false);
  const [selectedReaction, setSelectedReaction] = useState('');
  const [animation ,setAnimation] = useState(false)
  const { room } = useVideoContext();
  const dispatch: any = useDispatch();
  // const connection = useContext(SignalRContext);
  const {onlineUsers,reaction} = useSelector(
    (state: any) => state.userStatusReducer
  );
  const handleHover = () => {
    setIsHovered(true);
  };

  const handleLeave = () => {
    setIsHovered(false);
  };

  const handleReactionClick = (reaction: string) => {
    setSelectedReaction(reaction);

    const profile = JSON.parse(
      localStorage.getItem("profile") || JSON.stringify("")
    );
    const RoomData = JSON.parse(
      localStorage.getItem("RoomData") || JSON.stringify("")
    );
    const SingleUser = {
      // sid:room.localParticipant.sid,
      Reaction:reaction,
      UserId : profile.UserId,
      Roomid: RoomData.CurrentroomName,
      RoomName:room?.name,
      dataType: ConnectSignalREnums. Reactions,
      Name: profile.FirstName,
    };
    toast(`${reaction} send Successfully `);
    setAnimation(true)                                 
    setTimeout(() => {
      setAnimation(false)  
    },5000);
    dispatch({ type: SET_REACTION, payload: reaction});
   
    if (connection) {
      connection.send("DataInSignalR", JSON.stringify(SingleUser));
    } else {
      console.log("Connection is not in the 'Connected' state.");
    }
  };
  return (
    <>
    {animation?<FloatingEmoji emoji={reaction}/>:null}
    <div style={{ marginRight: '30px', textAlign: 'center' }}>
      <button
        style={{
          backgroundColor: 'red',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          marginLeft: '10px',
        }}
        // onClick={handleDisconnect}
        onMouseEnter={handleHover}
        onMouseLeave={handleLeave}
      >
        <TagFacesIcon />
      </button>
      <span style={{ display: 'block', marginLeft: '7px' }}  onMouseEnter={handleHover}
        onMouseLeave={handleLeave}>React</span>

      {isHovered && (
        <div
          style={{
            position: 'absolute',
            top: '80%',
            left: '0%',
            transform: 'translateX(-50%)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            background: '#fff',
            borderRadius: '5px',
            boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
            padding: '10px',
            zIndex: 999,
          }}
          onMouseEnter={handleHover}
          onMouseLeave={handleLeave}
        >
          <IconButton
            title="Thumbs Up"
            onClick={() => handleReactionClick('👍')}
            style={{ color:'inherit' }}
          >
            👍
          </IconButton>
          <IconButton
            title="Clap"
            onClick={() => handleReactionClick('👏')}
            style={{ color: 'inherit' }}
          >
            👏
          </IconButton>
          <IconButton
            title="Heart"
            onClick={() => handleReactionClick('❤️')}
            style={{ color:'inherit' }}
          >
            ❤️
          </IconButton>
          <IconButton
            title="Smile"
            onClick={() => handleReactionClick('😄')}
            style={{ color:'inherit' }}
          >
            😄
          </IconButton>
          <IconButton
            title="Shocked"
            onClick={() => handleReactionClick('😮')}
            style={{ color: 'inherit' }}
          >
            😮
          </IconButton>
        </div>
      )}
    </div>
    </>
  );
}
