import { Console } from 'console';
import { FORMAT_ROOM, SET_ROOM ,BREAKOUT_ROOM} from 'constants/store-actions';
import { Dispatch } from 'redux';

export const setRoom = (data: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: SET_ROOM, payload: data })
    } catch (error) {
        console.log(error);
    }
};

export const formatRoom = () => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: FORMAT_ROOM })
    } catch (error) {
        console.log(error);
    }
};
/*---------below actions added by vidushi----*/
export const breakoutRoom = (data: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type:BREAKOUT_ROOM, payload:data })
    } catch (error) {
        console.log(error);
    }
};
export const freebreakoutRoom = () => async (dispatch: Dispatch) => {
    try {
        dispatch({ type:BREAKOUT_ROOM })
    } catch (error) {
        console.log(error);
    }
};