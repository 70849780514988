import BasicCallerTune from 'assets/callSound/BasicCallerTune.mp3';
import { ComponentRightSide } from 'constants/common-constants';
import useVideoContext from 'hooks/useVideoContext/useVideoContext';
import { useNavigate } from 'react-router-dom';
const CallPopup = () => {

    const navigate = useNavigate();

    const handleRoomDisconnect = () => {
    localStorage.clear();
        window.location.reload();
    }

    return (
        <>
            <div style={{ position: 'fixed', inset: 0, width: '100vw', height: '100vh', background: 'rgba(0,0,0,0.5', zIndex: 9999999, display: 'grid', placeItems: 'center' }}>
                <div className='d-flex flex-column justify-content-center align-items-center' style={{ background: 'white', width: '400px', fontSize: '20px', fontWeight: 'bold', textAlign: 'center', padding: '20px 10px', borderRadius: 10 }}><p>Asking admin for join permission...</p>
                    <button className="btn btn-theme" onClick={handleRoomDisconnect} style={{ background: 'red', padding: '2px 5px 2px 5px' }}>
                        End Call{" "}
                        <i className="fa fa-sign-out"></i>
                    </button>
                </div>
            </div>
            <audio autoPlay loop className="audio-element">
                <source src={BasicCallerTune}></source>
            </audio>

        </>
    )
}

export default CallPopup
