import { changePassword } from "actions/auth";
import Joi from "joi";
import { useState } from "react";
import { useDispatch } from "react-redux";
import "App.css";

const ChangePassword = () => {
    const dispatch: any = useDispatch();
    const [errors, setErrors] = useState({ field: "", message: "" });
    const [loading, setLoading] = useState(false)
    const [form, setForm] = useState({
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
    });

    const handleChange = (target: string, value: string) => {
        setForm({ ...form, [target]: value });
    };

    const schema = Joi.object({
        currentPassword: Joi.string().required(),
        newPassword: Joi.string().min(8).required(),
        confirmPassword: Joi.string().min(8).required(),
    });

    const handleSave = async (event: any) => {
        event.preventDefault();
        const { value, error }: any = schema.validate(form);
        if (error == undefined) {
            setErrors({
                field: "",
                message: "",
            });
            if (form.newPassword !== form.confirmPassword) {
                setErrors({ field: "confirmPassword", message: "Confirm password and password must be same." });
                return;
            }
            const profile: any = localStorage.getItem("profile");
            const User: any = JSON.parse(profile);
            const payload = {
                Email: User.Email,
                Password: form.newPassword,
                CurrentPassword: form.currentPassword,
            };
            setLoading(true)
            dispatch(changePassword(payload, setLoading))
            setForm({
                currentPassword: "",
                newPassword: "",
                confirmPassword: "",
            })
        } else {
            console.log(error);
            const field: any = error.toString().split(" ")[1].replace(/['"]+/g, "");
            const message: any = error.toString().split(":")[1].replace(/"|'/g, "");
            setErrors({ field: field, message: message });
        }
    };

    const Style: any = {
        position: "absolute",
        fontSize: "11px",
        bottom: "-18px",
        width: "100%",
        right: 0,
        textAlign: "right",
        lineHeight: "16px",
        color: "#ff4343",
    };
    return (
        <div className="page-area">
            <a href="javascript:void(0)" className="top-menu-btn">
                <i className="fa fa-angle-up"></i>
            </a>
            <div className="container-fluid">
                <div className="page-section">
                    <div className="title-top">
                        <div className="page-title">
                            <strong>Change Password</strong>
                        </div>
                    </div>
                    <form
                        className="contact-form-section change-password-section"
                        noValidate
                    >
                        <div className="card-detail-section">
                            <div className="row row-colleagues">
                                <div className="col-md-6">
                                    <div className="card-detail-input">
                                        <div className="label">Enter Current Password</div>
                                        <input
                                            className={
                                                errors.field == "currentPassword"
                                                    ? "form-control show-error"
                                                    : "form-control"
                                            }
                                            onChange={(e) =>
                                                handleChange("currentPassword", e.target.value)
                                            }
                                            type="password"
                                            name="current-Password"
                                            required
                                        />
                                        {errors.field == "currentPassword" ? (
                                            <div className="help-block" style={Style}>
                                                <div>{errors.message}</div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="row row-colleagues">
                                <div className="col-md-6">
                                    <div className="card-detail-input">
                                        <div className="label">Enter New Password</div>
                                        <input
                                            className={
                                                errors.field == "newPassword"
                                                    ? "form-control show-error"
                                                    : "form-control"
                                            }
                                            onChange={(e) =>
                                                handleChange("newPassword", e.target.value)
                                            }
                                            type="password"
                                            value={form.newPassword}
                                            name="new-Password"
                                            required
                                            maxLength={20}
                                            minLength={8}
                                        />

                                        {errors.field == "newPassword" ? (
                                            <div className="help-block" style={Style}>
                                                <div>{errors.message}</div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="row row-colleagues">
                                <div className="col-md-6">
                                    <div className="card-detail-input card-detail-input-last">
                                        <div className="label">Enter Confirm Password</div>
                                        <input
                                            className={
                                                errors.field == "confirmPassword"
                                                    ? "form-control show-error"
                                                    : "form-control"
                                            }
                                            onChange={(e) =>
                                                handleChange("confirmPassword", e.target.value)
                                            }
                                            type="password"
                                            value={form.confirmPassword}
                                            name="confirm-Password"
                                            required
                                            maxLength={20}
                                        />
                                        {errors.field == "confirmPassword" ? (
                                            <div className="help-block" style={Style}>
                                                <div>{errors.message}</div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="action-profile">
                                <button onClick={handleSave} className="btn btn-theme btn-sm">
                                    Save{" "}
                                    {loading ? (
                                        <div style={{ left: "255px", marginTop: "-5px", fontSize: "8px" }} className="spinner-border spinner-border-sm text-light" role="status"></div>
                                    ) : null}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ChangePassword;
