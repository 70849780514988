import UserInfoCard from "features/UserInfoCard/UserInfoCard";
import { useEffect, useState,useContext } from "react";
import { SignalREventsEnums } from "constants/signalR-events";
import SelectBox from "components/SelectBox/SelectBox";
import { formatRoom, setRoom } from "actions/room";
import { useDispatch } from "react-redux";
import { INSERT_MESSAGE, SET_REACTION } from "constants/store-actions";
import { useSelector } from "react-redux";
import { addGuestUser } from "actions/guest";
import DefaultUserLogo from "assets/images/default-user.png";
import { useSearchParams } from "react-router-dom";
import { SignalRContext, getConnection } from "services/signal-r-service";
import { ComponentRightSide } from "constants/common-constants";
import { Participant, Room as IRoom } from "twilio-video";
import useVideoContext from "hooks/useVideoContext/useVideoContext";
import FloatingEmoji from "components/Dashboard/LeftSide/FloatingHeart";
import { toast } from "react-toastify";

const LeftSide = ({ List }: any) => {
  const [search, setSearch] = useState("");
  const [searchList, setSearchList] = useState([]);
  const [animation, setAnimation] = useState(false);
  const connection = useContext(SignalRContext);
  const [searchParams, setSearchParams] = useSearchParams();
const publicLink = searchParams.get("public");
const Id = searchParams.get("id");
const { room } = useVideoContext();
const [handleComponentRightSide, setHandleComponentRightSide] = useState(ComponentRightSide.RightSide);
const {reaction} = useSelector(
  (state: any) => state.userStatusReducer
);
const dispatch: any = useDispatch();

  const handleSearch = (value: any) => {
    setSearch(value);
    const filterUserList = List.filter((item: any) => {
      return item.name
        .toLowerCase()
        .match(new RegExp(value.toLowerCase(), "i"));
    });
    setSearchList(filterUserList);
  };
  const Local_callEnd = JSON.parse(
    localStorage.getItem("callEnd") ?? JSON.stringify("")
  );
  useEffect(() => {
    if (Local_callEnd == true )  {
        room?.disconnect();       
    }
  }, [Local_callEnd]);
  const roomName = JSON.parse(
    localStorage.getItem("RoomData") ?? JSON.stringify("")
  );

  useEffect(() => {
    if (connection) {
      connection.on("DataInSignalR", async (data: any) => {
        if (data) {
          let result;

          result = JSON.parse(data);
          if (result && result.length) {
           
            if (result[0].dataType == SignalREventsEnums.UserInChat) {
                console.log("-- GUEST USER IN CHAT --");
                if(publicLink || Id){
                  
                }
            }
          } 
          else{
            if (result.dataType == SignalREventsEnums.Reactions) {
              if(publicLink || Id){
              console.log("==SEND REACTIONS==")                        
              dispatch({ type: SET_REACTION, payload: result.Reaction });
               if (result.RoomName == roomName?.CurrentroomName ){
               toast.dismiss();
              toast(`${result.Reaction} send by ${result.Name}`);
              setAnimation(true)                                 
              setTimeout(() => {
                setAnimation(false)  
              },5000);
               }
            }
          }
          }          
        }
      });
    }
  }, [connection]);


  return (
    <>
     {animation? <FloatingEmoji emoji={reaction}/>:null}
      <div className="search-left-side collab-coffee-shop-left-side">
        <div className="form-group search-form-group">
          <input
            disabled={List.length ? false : true}
            onChange={(e) => handleSearch(e.target.value)}
            className="form-control ng-untouched ng-pristine ng-valid"
            placeholder="Search"
            type="text"
          />
          <i className="fa fa-search"></i>
        </div>
        <div className="message-users-list scroll-y">
          <div
            className="tab-content"
            style={{ maxHeight: "calc(100vh - 100px)" }}
          >
            <div className="tab-pane fade active show">
              <div className="user-sub-heading"></div>
              <div className="h-100">
                {search &&
                  searchList.map((user: any, index: any) => {
                    return (
                      <div className="single-user" key={index}>
                        <div style={{ zIndex: 999 }}>
                          <div className="user-pic">
                            <img
                              id="imgPrev"
                              onError={(err: any) => {
                                err.target.src = DefaultUserLogo;
                              }}
                              src={DefaultUserLogo}
                            />
                            <div className="user-status online"></div>
                          </div>
                          <div className="user-name mt-2">{user?.name}</div>
                          <div className="search-page-action-icons d-none"></div>
                        </div>
                      </div>
                    );
                  })}
                {List &&
                  !search &&
                  List.map((user: any, index: any) => {
                    return (
                      <div className="single-user" key={index}>
                        <div style={{ zIndex: 999 }}>
                          <div className="user-pic">
                            <img
                              id="imgPrev"
                              onError={(err: any) => {
                                err.target.src = DefaultUserLogo;
                              }}
                              src={DefaultUserLogo}
                            />
                            <div className="user-status online"></div>
                          </div>
                          <div className="user-name mt-2">{user?.name}</div>
                          <div className="search-page-action-icons d-none"></div>
                        </div>
                      </div>
                    );
                  })}
              </div>
              {List.length == 0 ? (<div className="text-center">No User Found</div>) : null}
              {(search && !searchList.length) ? <div className="text-center">No Record Found</div>: null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeftSide;
