import { GET_CHAT_MESSAGES, FORMAT_CHAT_MESSAGES, INSERT_GUEST_CHAT_MESSAGES, CHAT_LOADING, INSERT_MESSAGE, INSERT_FILES } from 'constants/store-actions';

const initialState = {
    chatMessages: [],
    loading: false
}
const chatMessages = (state = initialState, action: { type: string, payload: any }) => {
    switch (action.type) {
        case GET_CHAT_MESSAGES:
            return { chatMessages: action.payload, loading: false };
        case FORMAT_CHAT_MESSAGES:
            return initialState;
        case CHAT_LOADING:
            return { ...state, loading: true };
        case INSERT_GUEST_CHAT_MESSAGES:
            // @ts-ignore
            if (state && state.chatMessages[state.chatMessages?.length - 1]?.ChatId != action.payload?.ChatId){
                return { ...state, chatMessages: [...state.chatMessages, action.payload] }
            }else{
                return state;
            }
        case INSERT_MESSAGE:
            // @ts-ignore
            if (state.chatMessages.length && state.chatMessages[state.chatMessages.length - 1].ChatId != action.payload.ChatId)
                return { ...state, chatMessages: [...state.chatMessages, action.payload] }
            return state;
        case INSERT_FILES:
            // @ts-ignore
            const List = [...state.chatMessages, ...action.payload];
            const filteredList = List.filter((value: any, index: any, self: any) =>
                self.findIndex((v: any) => v.ChatId === value.ChatId) === index
            );
            return { ...state, chatMessages: filteredList }

            return state;
        default:
            return state;
    }
};

export default chatMessages;