import React, { useState, useEffect } from 'react';


const FloatingEmoji = ({emoji}:any) => {
  const [position, setPosition] = useState({ top: 0, left: 0 });

  // Generate random position for the heart
  useEffect(() => {
    const intervalId = setInterval(() => {
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      const randomTop = Math.floor(Math.random() * windowHeight);
      const randomLeft = Math.floor(Math.random() * windowWidth);
      setPosition({ top: randomTop, left: randomLeft });
    }, 2000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);
  
  const floatAnimationKeyframes = `
     @keyframes floatAnimation {
    
    0% {
        transform: rotate(10deg);
        opacity: 0;
      }
    
      25% {
        transform: rotate(-10deg);
        opacity: 1;
      }
    
      75% {
        transform: rotate(10deg);
        opacity: 0.5;
      }
    
      100% {
        transform: rotate(-10deg);
        opacity: 0;
        bottom: 10%;
      }
  `;

  return (
    <div>
      <style>{floatAnimationKeyframes}</style>
      <div style={{
         position: 'fixed',
         display: 'flex',
         alignItems: 'center',
         justifyContent: 'center',
         bottom: 0,
         left: '85%',
         width: '100px',
         height: '100px',
         color: 'red',
         fontSize: '40px',
         pointerEvents: 'none',
         zIndex: 9999,
         animationDelay: '1.33s',
         animation: 'floatAnimation 5s infinite',
      }}>
       {emoji}
      </div>
      <div style={{
         position: 'fixed',
         display: 'flex',
         alignItems: 'center',
         justifyContent: 'center',
         bottom: 0,
         left: '86%',
         width: '100px',
         height: '100px',
         color: 'red',
         fontSize: '40px',
         pointerEvents: 'none',
         zIndex: 9999,
         animationDelay: '1.33s',
         animation: 'floatAnimation 3s infinite',
      }}>
       {emoji}
      </div>
      <div style={{
         position: 'fixed',
         display: 'flex',
         alignItems: 'center',
         justifyContent: 'center',
         bottom: 0,
         left: '85%',
         width: '200px',
         height: '200px',
         color: 'red',
         fontSize: '40px',
         pointerEvents: 'none',
         zIndex: 9999,
         animationDelay: '0.66s',
         animation: 'floatAnimation 5s infinite',
      }}>
       {emoji}
      </div>
    </div>
  );
};

export default FloatingEmoji;
