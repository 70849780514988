import React, { useEffect, useState } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useDispatch, useSelector } from "react-redux";
import { DeletePublicMeetingUsers, GetPublicMeetingUsers } from "actions";
import demo from "../../assets/images/default-user.png";
import { GetRooms} from "actions/guest";

const PublicOngoingCall = () => {
  const dispatch: any = useDispatch();
  const publicUser = useSelector((state: any) => state.guest);
  const [open, setOpen] = useState(false);
  const [List, setList] = useState([]);


// useEffect(() => {
//   dispatch(GetPublicMeetingUsers());
// }, [publicUser]);

useEffect(() => {
  dispatch(GetPublicMeetingUsers());
}, []);


  return (
    <div>
      {publicUser?.allPublicOngoingCallUser &&
      publicUser?.allPublicOngoingCallUser?.length ? (
        <div
          className="meeting-users"
          style={{
            color: "#003964",
            marginTop: "0px",
            marginLeft: "10px",
            position:'relative',
            backgroundColor: "white",
            borderRadius: "50px",
            height: "37px",
            lineHeight: "34px",
            width: "55px",
          }}
          onClick={() => setOpen(true)}
        >
          <>
          <div title="Who is in meeting">
            <div style={
              {position:"relative",top:"0px",left:"-8px"}
            }>
          <AccountCircleIcon />
            </div>
          <div style={
            {
              position:"absolute",
              right:"5px",
              top:"9px",
            }
          } className="online"></div>
          </div>
          </>
        </div>
      ) : null}
      {open ? (
        <div
       
          style={{
            position: "absolute",
            zIndex: "9",
            top: "62px",
            width: "70%",
            height: "20%", 
          }}
        >
          <div
            style={{
              background: "#003964",
              fontWeight: 600,
              color: "white",
              width: "80%",
              borderRadius: "7px 7px 0px 0px",
            }}
            className="col-md-12 d-flex  fw-600 justify-content-between align-items-center p-2"
          >
            Who is in Meeting{" "}
            <div onClick={() => setOpen(false)}>
              <CancelOutlinedIcon />
            </div>
          </div>
          <div
            style={{
              maxHeight: "316px",
              height: "auto",
              overflow: "hidden",
              overflowY: "auto",
              width: "80%",
            }}
          >
            {publicUser.allPublicOngoingCallUser &&
              publicUser.allPublicOngoingCallUser?.map(
                (item: any, index: any) => {
                  return (
                    <div
                      style={{
                        backgroundColor: "rgb(225 224 224)",
                        padding: "10px 0px 10px 30px",
                        display: "flex",
                      }}
                    >
                      <div>
                        <img
                          style={{ height: "40px", width: "40px" }}
                          src={demo}
                        />
                      </div>
                      <div style={{ textAlign: "left", marginLeft: "20px" }}>
                        <span className="public-user-name">{item.FName}</span>
                        <span className="public-user-email">{item.Email}</span>
                      </div>
                    </div>
                  );
                }
              )}
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default PublicOngoingCall;
