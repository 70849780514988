import { RemoteAudioTrack, RemoteParticipant } from 'twilio-video';
import useParticipants from 'hooks/useParticipants/useParticipants';
import useTracks from 'hooks/useTracks/useTracks';
import AudioTrack from '../AudioTrack/AudioTrack';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from "react";
import useParticipantsContext from 'hooks/useParticipantsContext/useParticipantsContext';
import useVideoContext from 'hooks/useVideoContext/useVideoContext';

function Participant({ participant }: { participant: RemoteParticipant }) {
  const tracks = useTracks(participant);
  const audioTrack = tracks.find(track => track.kind === 'audio') as RemoteAudioTrack | undefined;

  if (audioTrack?.kind === 'audio') return <AudioTrack track={audioTrack} />;

  return null;
}

export function ParticipantAudioTracks() {
  const participants = useParticipants();
  const { mutedParticipantList } = useSelector((state: any) => state.setting);
  const { breakroom } = useSelector((state: any) => state.room);
  const { room } = useVideoContext();
  const [callingParticipants, setCallingParticipants] = useState(participants);
  // console.log("=============callingParticipants",callingParticipants)
  useEffect(() => {
    if (participants.length === 0 || !breakroom || breakroom.length === 0) {
      setCallingParticipants(participants);
      return;
    }

    // const allParticipantsList = [room?.localParticipant, ...participants];
    const allParticipantsList = [...participants];
    let currentRoomParticipant: any = [];

    if (breakroom && breakroom.length > 0) {
      let currentRoom = 0;

      breakroom?.forEach((rooms: any, value: number) => {
        rooms?.forEach((item: any) => {
          if (item?.SID == room!.localParticipant?.sid) {
            currentRoom = value;
          }
        });
      });
      currentRoomParticipant = allParticipantsList.filter((item: any) =>
        breakroom[currentRoom].some((value: any) => value.SID == item.sid)
      );
    }

    setCallingParticipants(currentRoomParticipant);
  }, [participants, breakroom, room]);


  return (
    <>
      {callingParticipants.map((participant: RemoteParticipant) => (
        !mutedParticipantList.includes(participant.sid) ? (
          <Participant key={participant.sid} participant={participant} />
        ) : null
      ))}
    </>
  );
}
