import React, { useContext, useEffect, useState,useRef } from 'react'
import ChatBox from './ChatBox/ChatBox'
import ChatFeedback from './ChatFeedback'
import { getChatUsersList } from 'actions'
import { useDispatch, useSelector } from 'react-redux'
import LeftSide from './LeftSide'
import RightSide from './RightSide'
import RoomComponent from 'components/VideoCallFunctionality/Video/Video'
import { CALL_RESPONSE_EVENT, ComponentRightSide, ConnectSignalREnums } from 'constants/common-constants'
import { setRoom } from 'actions/room'
import VideoMenu from './VideoMenu/VideoMenu'
import clsx from 'clsx'
import CallNotification from './PopUps/CallNotification'
import { SignalREventsEnums } from 'constants/signalR-events'
import CallDeclinedPopup from './PopUps/CallDeclinedPopup'
import { useNavigate } from 'react-router-dom'
import { SignalRContext } from 'services/signal-r-service'
import { SET_SETTINGS } from 'constants/store-actions'
import useVideoContext from 'hooks/useVideoContext/useVideoContext'
import eventBus from 'utils/helper/eventBus'
import { toastInfo } from 'services'
import UserInteracition from './PopUps/UserInteraction'
import { GetUsersInCall ,insertOnlineAndBusyUsers,GetOnlineUsers} from "actions";


const Dashboard = () => {
    const [handleComponentRightSide, setHandleComponentRightSide] = useState(ComponentRightSide.RightSide);
    const [showCallNotification, setShowCallNotification] = useState(false);
    const [showContacts, setShowContacts] = useState(true);
    const [showModal, setShowModal] = useState(true);
    const [callDeclinedPopup, setCallDeclinedPopup] = useState<any>({ decline: false, result: {} });
    const [toggleVideoChat, setToggleVideoChat] = useState(false);
    const [toggleIncomingCallPopup, setToggleIncomingCallPopup] = useState(false)
    const [calling, setCalling] = useState(false);
    const [callingThirdPerson, setCallingThirdPerson] = useState(false);
    const [isSeen, setIsSeen] = useState(false)
    const [collapse, setCollapse] = useState(false)
    const { chatUserList} = useSelector((state: any) => state.user);

    const { room } = useVideoContext();
    const isMounted = useRef(false);
    const shouldSkipUnload = useRef(false);
  
    const navigate = useNavigate();
    const dispatch: any = useDispatch();
    const connection = useContext(SignalRContext);

    const handleSendCallNotification = (roomName: string, user: any) => {   
        const profile = JSON.parse(localStorage.getItem('profile') ?? JSON.stringify(''));
        const roomData = JSON.parse(localStorage.getItem('RoomData') ?? JSON.stringify(''));
        let SingleUser = {
            UserInCall: user.Key,
            loginUserId: `${profile.UserId}-${user.Key}`,
            CurrentroomName: roomName,
            IsRoom: true,  
            Name: profile.FirstName + ' ' + profile.LastName,
            callingName: user.Value + ' ' + user.LastName,
            dataType: ConnectSignalREnums.UserInCall,
            ChatRoomId: room && roomData ? roomData.ChatRoomId : user.ChatRoomId,        
        }
        if (connection) {
            setCalling(true);
            setTimeout(() => {
                setCalling(false);
            }, 30000);
            connection.send('DataInSignalR', JSON.stringify(SingleUser));
            console.log("Call Event Sent")
            localStorage.setItem('RoomData', JSON.stringify(SingleUser));
            dispatch(setRoom(SingleUser));
        }
    }

    const handleCallPick = (value: boolean) => {
        // if user have picked the call
        if (value) {
            const roomData = JSON.parse(localStorage.getItem('RoomData') ?? JSON.stringify(''));
            // When user is already in call and someone else is calling
            if (handleComponentRightSide == ComponentRightSide.RoomComponent) {          
                let data: any = {};
                data.dataType = SignalREventsEnums.RequestPrivatecallResponse;
                data.receiverId = roomData.loginUserId?.split('-')[0];
                data.roomName = localStorage.getItem('roomNameLatest');
                // Sending Current Room data to the person who is calling
                connection.send('DataInSignalR', JSON.stringify(data));
                setShowCallNotification(false);
            }
            else {
                setHandleComponentRightSide(ComponentRightSide.RoomComponent);
                let data: any = {};
                data.dataType = SignalREventsEnums.RequestPrivatecallResponse;
                data.receiverId = roomData.loginUserId?.split('-')[0];
                connection.send('DataInSignalR', JSON.stringify(data));
                dispatch({ type: SET_SETTINGS, payload: { name: 'onGoingCall', value: true } })
                setShowCallNotification(false);
            }
        } else {
            let data: any = {};
            const profile = JSON.parse(localStorage.getItem('profile') ?? JSON.stringify(''));
            data.dataType = SignalREventsEnums.UserDeclineCall;
            data.callDeclineUserName = profile?.FirstName + ' ' + profile?.LastName;
            data.callDeclineUserId = profile?.UserId;
            connection.send('DataInSignalR', JSON.stringify(data));
            setShowCallNotification(false);
        }
    }

    const handleCallPickByReceiver = (value: boolean, data?: any) => {
        if (value) {
            setCalling(false);
            setHandleComponentRightSide(ComponentRightSide.RoomComponent)
            setCallDeclinedPopup({ decline: false, result: {} })
            setCallingThirdPerson(false);
        }
        else {
            if (callingThirdPerson) {
                setCallingThirdPerson(false);
                toastInfo(`${data.callingName} rejected the call request.`)
            }
            else setCallDeclinedPopup(data);
        }
    }

    const handleVideoChatWindow = () => {
        setToggleVideoChat(!toggleVideoChat)
    }

    const handleIncomingCallpopup = () => {
        setToggleIncomingCallPopup(!toggleIncomingCallPopup)
        setIsSeen(false)
    }

    const handleClosePermissionModal = () => {
        setShowModal(false)
    }

    const handleCollapse = () => {
        setCollapse(!collapse)
    }

    const handleCallEndButton = () => {
        setCallDeclinedPopup({ decline: false, result: {} })
        setCalling(false);
        setHandleComponentRightSide(ComponentRightSide.RightSide)
    }

    useEffect(() => {
        if (!chatUserList?.length) localStorage.setItem('reload', 'true');
    }, [chatUserList])

    useEffect(() => {
        dispatch(getChatUsersList());
        eventBus.on(CALL_RESPONSE_EVENT, (data: any) => {
            setCallingThirdPerson(true);
            setTimeout(() => {
                if (callingThirdPerson) {
                    setCallingThirdPerson(false);
                    toastInfo(`${data.callingName} didn't pick the call.`)
                }
            }, 30000)
        })
        if (!localStorage.getItem('ringer')) {
            setTimeout(() => {
                localStorage.setItem('ringer', 'true')
                window.location.reload();
            }, 200);
        }
    }, [])

    useEffect(()=>{
        const profile = JSON.parse(localStorage.getItem('profile') ?? JSON.stringify(''));
        const chatUser = JSON.parse(localStorage.getItem('chatUser') ?? JSON.stringify(''));
          const payload =
          {
            FName:profile.FirstName,
            LName:profile.LastName,
            Email:profile.Email,
            UserId:profile.UserId,
            LastMessageTime:chatUser.LastMessageTime,
            LastMessage:chatUser.LastMessage,
            Status:1
          } 
          dispatch(insertOnlineAndBusyUsers(payload));
      },[])


    useEffect(()=>{
        dispatch(GetOnlineUsers())
      },[])
      
      useEffect(()=>{
        dispatch(GetUsersInCall())
      },[])

    useEffect(() => {
        if (callDeclinedPopup.decline) {
            setTimeout(() => {
                if (room) setCallDeclinedPopup({ decline: false, result: '' })
                else navigate(0);
            }, 500);
        }
    }, [callDeclinedPopup])


    useEffect(() => {
        isMounted.current = true;
    
        return () => {
          isMounted.current = false;
        };
      }, []);

    return (
        <div className="page-area">
            {(callDeclinedPopup && callDeclinedPopup.decline) || calling ? <CallDeclinedPopup name={callDeclinedPopup.result?.callDeclineUserName} calling={calling} setHandleComponentRightSide={handleCallEndButton} /> : null}
            {callingThirdPerson ? <CallDeclinedPopup calling={true} setHandleComponentRightSide={setHandleComponentRightSide} setCallingThirdPerson={setCallingThirdPerson} /> : null}
            <div className="container-fluid">
                <div className="page-section">
                    {showCallNotification ? <CallNotification name="Test" startVideoCall={handleCallPick} /> : null}
                    <div className="home-tab-content">
                        <div className="main-content-area">
                            {
                                // !collapse ? <div className="smallworld-cafe-title m-0">
                                //     {/* Welcome To Small World Cafe */}
                                // </div> : null
                            }

                            <VideoMenu
                                hideContacts={showContacts}
                                setHideContacts={setShowContacts}
                                setHandleComponentRightSide={setHandleComponentRightSide}
                                setToggleChatWindow={handleVideoChatWindow}
                                toggleVideoChat={toggleVideoChat}
                                handleIncomingCallpopup={handleIncomingCallpopup}
                                componentRightSideDashboard={handleComponentRightSide}
                                isSeen={isSeen}
                                setIsSeen={setIsSeen}
                            />
                            <div className={clsx(`search-page-group collab-coffee-search-page-group mt-2`, showContacts ? '' : 'p-0')}>
                                {showContacts ?
                                    <LeftSide
                                        connection={connection}
                                        showCallNotification={showCallNotification}
                                        setHandleComponentRightSide={setHandleComponentRightSide}
                                        setShowCallNotification={setShowCallNotification}
                                        handleCallPickByReceiver={handleCallPickByReceiver}
                                    /> : null}
                                {handleComponentRightSide == ComponentRightSide.RightSide ? <RightSide handleCollapse={handleCollapse} /> : null}
                                {handleComponentRightSide == ComponentRightSide.ChatFeedback ?
                                    <ChatFeedback
                                        handleSendCallNotification={handleSendCallNotification}
                                        setHandleComponentRightSide={setHandleComponentRightSide}
                                    /> : null}
                                {handleComponentRightSide == ComponentRightSide.ChatBox ? <ChatBox /> : null}
                                {handleComponentRightSide == ComponentRightSide.RoomComponent ?
                                    <RoomComponent
                                        toggleVideoChat={toggleVideoChat}
                                        toggleIncomingCallPopup={toggleIncomingCallPopup}
                                        handleIncomingCallpopup={handleIncomingCallpopup}
                                    /> : null}
                            </div>
                        </div>
                    </div>
                    {localStorage.getItem('reload') == 'true' ? <UserInteracition setUserInteraction={handleClosePermissionModal} /> : null}
                </div>
            </div>
        </div>
    )
}

export default Dashboard