import { GET_USER_LIST, INSERT_GUEST_CHAT_MESSAGES, INSERT_MESSAGE, GET_USER_IN_CALL, USER_STATUS,GET_ONLINE_USERS, INSERT_USERS_IN_CALL, INSERT_PUBLIC_MEETING_USERS, TOGGLE_LOADING, UPDATE_CHAT_USER } from 'constants/store-actions';
import { Dispatch } from 'redux';
import * as api from 'api';
import { toastErr, toastSuccess } from 'services';
import { InsertPublicMeetingUsers } from './guest';

export const getChatUsersList = () => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: TOGGLE_LOADING, payload: {} })
        const { data: result } = await api.getUserListForChat();
        if (result.responseCode === 400) {
            toastErr(result.message);
            return;
        }
        if (result.responseCode === 200) {
            dispatch({ type: GET_USER_LIST, payload: result.response.UsersList })
        }
    } catch (error) {
        console.log(error);
        dispatch({ type: TOGGLE_LOADING, payload: {} })
    }
};

export const GetOnlineUsers = () => async (dispatch: Dispatch) => {
    try {
        const data = await api.GetOnlineUsers();
        dispatch({ type: GET_ONLINE_USERS, payload: data.data.response });
    } catch (error) {
        console.log(error);
    }
};

export const insertUserFavoriteChatList = (formData: any) => async (dispatch: Dispatch) => {
    try {
        const { data: result } = await api.InsertUserFavoriteChatRoom(formData);
        if (result.responseCode === 200) {
            toastSuccess("Chat room added to Favorite !");
            return;
        }
        if (result.responseCode === 400) {
            toastErr(result.message);
            return;
        }
    } catch (error) {
        console.log(error)
    }
}

export const UpdateOnlineUsers = (formData: any) => async (dispatch: Dispatch) => {
    try {
        const { data: result } = await api.UpdateOnlineUsers(formData);
        if (result.responseCode === 200) {
            // toastSuccess("Chat room added to Favorite !");
            return;
        }
        if (result.responseCode === 400) {
            // toastErr(result.message);
            return;
        }
    } catch (error) {
        console.log(error)
    }
}
export const insertOnlineAndBusyUsers = (formData: any) => async (dispatch: Dispatch) => {
    try {
        const { data: result } = await api.InsertOnlineAndBusyUsers(formData);
        if (result.responseCode === 200) {
            // toastSuccess("Chat room added to Favorite !");
            return;
        }
        if (result.responseCode === 400) {
            // toastErr(result.message);
            return;
        }
    } catch (error) {
        console.log(error)
    }
}
export const DeleteOnlineUser = (formData: any) => async (dispatch: Dispatch) => {
    try {
        const { data: result } = await api.DeleteOnlineUser(formData);
        if (result.responseCode === 200) {
            // toastSuccess("Chat room added to Favorite !");
            return;
        }
        if (result.responseCode === 400) {
            // toastErr(result.message);
            return;
        }
    } catch (error) {
        console.log(error)
    }
}



export const removeUserFavoriteChatList = (formData: any) => async (dispatch: Dispatch) => {
    try {
        const { data: result } = await api.InsertUserFavoriteChatRoom(formData);
        if (result.responseCode === 200) {
            toastSuccess("Chat room removed from Favorite !")
            return;
        }
        if (result.responseCode === 400) {
            toastErr(result.message);
            return;
        }
    } catch (error) {
        console.log(error)
    }
}

export const deleteChatRoomParticipants = (formData: any) => async (dispatch: Dispatch) => {
    try {
        const { data: result } = await api.deleteChatRoomParticipants(formData);
        if (result.responseCode === 200) {
            toastSuccess("Chat room removed from group !")
            return;
        }
        if (result.responseCode === 400) {
            toastErr(result.message);
            return;
        }
    } catch (error) {
        console.log(error)
    }
}

export const createGuestUser = (formData: any) => async (dispatch: Dispatch) => {
    try {
        const { data: { response } } = await api.createGuestUser(formData);
        const payload = {
            FName : response[0].Name,
            LName: '',
            Email: response[0].Email,
            profilepicture: '',
            PublicGuestId: response[0].Id
           }
        await api.InsertPublicMeetingUsers(payload)
        localStorage.setItem("User", JSON.stringify(response[0]))
        dispatch({ type: INSERT_PUBLIC_MEETING_USERS, payload: response[0] })
        localStorage.setItem("profile", JSON.stringify({ FirstName: response[0].Name, LastName: "", Email: response[0].Email, UserId: response[0].Id }))
    } catch (error) {
        console.log(error)
    }
}

export const insertGuestChat = (formData: any) => async (dispatch: Dispatch) => {
    try {
        const { data: { response } } = await api.insertGuestchat(formData);
        // console.log(response)
        // if(response.length){
        // dispatch({ type: INSERT_GUEST_CHAT_MESSAGES, payload: response[0] });
        // }
    } catch (error) {
        console.log(error)
    }
}
export const UploadGuestChatFile = (formData: any) => async (dispatch: Dispatch) => {
    try {
        const { data: { response } } = await api.UploadGuestChatFile(formData);
        // if(response.length){
        // dispatch({ type: INSERT_GUEST_CHAT_MESSAGES, payload: response[0] });
        // }
    } catch (error) {
        console.log(error)
    }
}

export const insertCurrentChatUser = (formData: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: UPDATE_CHAT_USER, payload: formData })
    } catch (error) {
        console.error(error)
    }
}
/*-----below all actions added by vidushi----*/
export const InsertUsersInCall = (formData: any) => async (dispatch: Dispatch) => {
    try {
        const { data: result } = await api.InsertUsersInCall(formData)
        dispatch({
            type: INSERT_USERS_IN_CALL,
            payload: result.response,
        });
    } catch (error) {
        console.log(error);
    }
}


export const RemoveUsersInCall = (formData: any) => async (dispatch: Dispatch) => {
    try {
        const { data: result } = await api.RemoveUsersInCall(formData);
        if (result.responseCode == 200) {
            // toastSuccess("Remove User in Call !")
            return;
        }
        if (result.responseCode == 400) {

            return;
        }
    } catch (error) {
        console.log(error)
    }
}

export const GetUsersInCall = () => async (dispatch: Dispatch) => {
    try {
        const data = await api.GetUsersInCall();
        dispatch({ type: GET_USER_IN_CALL, payload: data.data.response });
    } catch (error) {
        console.log(error);
    }
};
export const userStatus = (formData: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: USER_STATUS, payload: formData });
    } catch (error) {
        console.log(error);
    }
}
