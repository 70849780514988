
const SelectBox = ({ data, handleSelect }: any) => {
    return (
        <select className="form-control" id="mySelect" onChange={(e) => handleSelect(e.target.value)}>
            {data.map((item: { id: number, value: string }) => {
                return <option key={item.id} value={item.id}>{item.value}</option>
            })}
        </select>
    )
}

export default SelectBox