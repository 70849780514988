import { GET_DETAILS, DETAIL_SUBMIT,GET_DROPDOWN,UPLOAD_PROFILE_IMAGE } from "constants/store-actions";
import * as api from "api";
import { Dispatch } from "redux";
import { defaultToast, toastSuccess } from 'services';

export const getUserDetails = () => async (dispatch: Dispatch) => {
  try {
    const data = await api.getUserDetails();
    dispatch({ type: GET_DETAILS, payload: data.data.response });
  } catch (error) {
    console.log(error);
  }
};

export const basicDetailSubmit = (formData: any, setLoading: any) => async (dispatch: Dispatch) => {
    setLoading(true);
    try {
        const data = await api.basicdetailSubmit(formData);
        if (data.data.responseCode
            == '200') {
            dispatch({ type: DETAIL_SUBMIT, payload: data.data.response })
            defaultToast("Data Saved Successfully");
            setLoading(false);
            return;
        }
    } catch (error) {
      console.log(error);
    }
}

export const updateGeneralDetail =
  (formData: any, setLoading: any) => async (dispatch: Dispatch) => {
    setLoading(true);
    try {
      const data = await api.updateGeneralDetail(formData);
      if (data.data.responseCode == "200") {
        dispatch({ type: DETAIL_SUBMIT, payload: data.data.response });
        toastSuccess("Data Saved Successfully");
        setLoading(false);
        return;
      }
    } catch (error) {
      console.log(error);
    }
  };

export const getUserProfileDropDown = () => async (dispatch: Dispatch) => {
  try {
    const data = await api.getUserProfileDropDown();
    dispatch({ type: GET_DROPDOWN, payload: data.data.response });
  } catch (error) {
    console.log(error);
  }
};

export const uploadProfileImage = (data:{formFile: any}) => async (dispatch: Dispatch) => {
  try {
    const response = await api.uploadProfileImage(data)
    dispatch({ type: UPLOAD_PROFILE_IMAGE, payload: response.data.response })
  } catch (error) {
    console.log(error)
  }
}