import Select from 'react-select';

const SearchAndSelectBox = ({ userList, handleChange }: any) => {

    const List: any = userList.map((item: any) => {
        return { ...item, value: item.Name, label: item.Name }
    })

    return (
        <Select
            autoFocus
            onChange={(e) => handleChange("participant", e)}
            placeholder="Search participants by name or email"
            className="basic-single"
            classNamePrefix="select"
            name="color"
            options={List}
        />
    )
}

export default SearchAndSelectBox

