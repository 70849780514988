import {
  Button,
  Grid,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { ConnectSignalREnums } from "constants/common-constants";
import React, { useContext, useState, useEffect } from "react";
import { createGuestUser, InsertPublicMeetingUsers } from "actions";
import { useDispatch } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { SignalRContext } from "services/signal-r-service";
import { v4 as uuidv4 } from "uuid";
import { CheckMeetingValid } from "actions";
import { useNavigate } from "react-router-dom";
import { DeletePublicMeetingUsers} from "actions";
import useVideoContext from "hooks/useVideoContext/useVideoContext";
const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: "1em",
  },
  inputContainer: {
    display: "flex",
    justifyContent: "space-between",
    margin: "1.5em 0 3.5em",
    "& div:not(:last-child)": {
      marginRight: "1em",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "1.5em 0 2em",
    },
  },
  textFieldContainer: {
    width: "100%",
  },
  continueButton: {
    width: "100%",
    marginTop: "10px",
  },
}));

const GuestNameModal = ({
  setIsPublic,
  setName,
  setIsSchedule,
}: {
  setIsPublic?: any;
  setIsSchedule?: any;
  setName: (value: string) => void;
}) => {
  const dispatch: any = useDispatch();
  const [username, setUsername] = useState("");
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("")
  const [searchParams, setSearchParams] = useSearchParams();
  const connection = useContext(SignalRContext);
  const classes = useStyles();
  const { roomId } = useParams();
  const Id = searchParams.get("id");
  const publicLink = searchParams.get("public");
  const userEmail = searchParams.get("email");
  const chatRoomId = new URLSearchParams(roomId?.split("&")[0])
  const meetingId = new URLSearchParams(roomId?.split("&")[1])
  const ChatRoomId = chatRoomId.get('ChatRoomId');
  const MeetingId = meetingId.get('MeetingId');
  const { room } = useVideoContext();
  const navigate = useNavigate();
  const [form, setForm] = useState({
    name: "",
    email: userEmail,
  });
  const handleChange = (target: any, value: any) => {
    if (target == "email") {
      const isValid = validateEmail(value);
      if (!isValid) {
        setError(true);
      } else {
        setError(false);
      }
    }
    if (target == "name") {
      setUsername(value);
    }
    if (target == "email") {
      setEmail(value)
    }
    setForm({ ...form, [target]: value });
  };

  const validateEmail = (value: any) => {
    var reEmail =
      /^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/;
    if (!value?.match(reEmail)) {
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    if (error) return;

    if (!username) return;
    localStorage.setItem(
      "profile",
      JSON.stringify({ FirstName: username, LastName: "" })
    );
    localStorage.setItem(
      "RoomData",
      JSON.stringify({ CurrentroomName: roomId })
    );
    localStorage.setItem("guest", "true");
    const payload = {
      Name: form.name,
      Email: form.email,
      ChatRoomId: "",
      MeetingId: "",
    };
    dispatch(createGuestUser(payload));
    if (MeetingId) {
      setIsSchedule(true);
      localStorage.setItem(
        "RoomData",
        JSON.stringify({ CurrentroomName: ChatRoomId })
      );
      return;
    }
    if (username && publicLink) {
      setIsPublic(publicLink);
      return
    }
    setName(username);
    const SingleUser = {
      name: username,
      userId: Id,
      roomId: roomId,
      isGuest: true,
      uuid: uuidv4(),
      dataType: ConnectSignalREnums.RequestForGuestCall,
    };
    localStorage.setItem("guestUser", JSON.stringify(SingleUser));
    if (connection) {
      connection.send("DataInSignalR", JSON.stringify(SingleUser));
    }
  };

  const Local_callEnd = JSON.parse(
    localStorage.getItem("callEnd") ?? JSON.stringify("")
  );

  useEffect(() => {
    if (Local_callEnd == true )  {
     
        room?.disconnect();   
       
    }
  }, []);

  useEffect(() => {
    if (MeetingId) {
      var today: any = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();
      today = yyyy + '-' + mm + '-' + dd;
      const payload = {
        CreatedDate: today,
        Id: MeetingId
      };
      dispatch(CheckMeetingValid(payload));
    }
  }, [MeetingId])

  const [renderCount, setRenderCount] = useState(0);

 
  return (
    <div
      style={{
        background: "white",
        borderRadius: "5px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        padding: "5px 10px",
        width: "400px",
        height: "235px",
        border: "0.5px solid gray",
      }}
    >
      <Typography variant="h5" className={classes.gutterBottom}>
        Join Room
      </Typography>
      <div className={classes.textFieldContainer}>
        <TextField
          id="input-user-name"
          variant="outlined"
          placeholder="Enter your name"
          fullWidth
          size="small"
          value={form.name}
          onChange={(e: any) => handleChange("name", e.target.value)}
        />
      </div>
      <div style={{ marginTop: "15px" }} className={classes.textFieldContainer}>
        <TextField
          id="input-user-name"
          variant="outlined"
          placeholder="Enter your email"
          fullWidth
          type="email"
          size="small"
          value={form.email}
          onChange={(e: any) => handleChange("email", e.target.value)}
        />
        {error ? (
          <span style={{ color: "red", marginLeft: "4px", fontSize: "12px" }}>
            Invalid email address
          </span>
        ) : null}
      </div>

      <Button
        style={{ marginBottom: "12px" }}
        variant="contained"
        type="submit"
        color="primary"
        disabled={!form.name || !form.email || !roomId || error}
        className={classes.continueButton}
        onClick={handleSubmit}
      >
        Join Room
      </Button>
    </div>
  );
};

export default GuestNameModal;
