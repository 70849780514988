import React from 'react'

const Dropdown = ({ data, handleDropdown, user }: any) => {
    return (
        <>
            <div className="dropdown">
                <a className="fa fa-ellipsis-v" data-bs-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                </a>
                <ul className="dropdown-menu" style={{ left: 'inherit !important', right: '0'}} onClick={(e) => handleDropdown(e, user)}>
                    {
                        data.map((item: string, index: number) => {
                            return <li key={index} value={item}><a className="dropdown-item" href="javascript:void(0);">{item}</a></li>
                        })
                    }
                </ul>
            </div>
        </>
    )
}

export default Dropdown