import { combineReducers } from 'redux';

import auth from './auth';
import user from './userList';
import room from './Room';
import chatMessages from './chatMessages';
import account from './account';
import setting from './setting';
import guest from './guestUsers'
import userStatusReducer from './userStatus';

export const reducers = combineReducers({ 
    auth, 
    user, 
    room, 
    chat: chatMessages, 
    account, 
    setting ,
    guest,
    userStatusReducer,
});