
const GuestCallRejectedPopup = () => {

    const handleclose = () => {
        localStorage.clear();
        window.location.reload()
    }

    return (
        <div
            role="dialog"
            tabIndex={-1}
            style={{
                background: 'rgba(0, 0, 0, 0.6)',
            }}
            className="modal d-block in modal-theme fade show"
            aria-modal="true"
        >
            <div role="document" className="modal-dialog modal-lg">
                <div className="modal-content">
                    <form
                        noValidate
                        className="ng-invalid ng-touched ng-dirty"
                    >
                        <div className="modal-header">
                            {/* <button className="close" onClick={handleclose} style={{ background: "#003964", border: 0 }} type="button">
                                ×
                            </button>
                            <h4 className="modal-title">
                                Information
                            </h4> */}
                        </div>
                        <div className="modal-body invite-members-popup" style={{ height: "120px" }}>
                            <div className="row px-5" >
                                <div className="text-center">
                                    <h6>
                                        Call is rejected by Admin
                                    </h6>
                                </div>
                                <div onClick={handleclose} className="btn btn-danger mt-2">return</div>
                            </div>
                        </div>
                        <div className="modal-footer">
                        </div>
                    </form>
                </div>
            </div >
        </div >
    )
}

export default GuestCallRejectedPopup
