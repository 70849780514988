import React from 'react'
import {useSelector } from "react-redux";
import { toastSuccess } from "services";
const MeetingUrlModal = ({ handelClose }: any) => {
    const  {meettingurl}  = useSelector((state: any) => state.guest);
   
    const copyUrl = ()=>{
        navigator.clipboard.writeText(`https://smallworldmeet.com/guest-meeting/${meettingurl}`);
        toastSuccess("Meeting Link Copied Successfully.");
    }
    return (
        <div
            role="dialog"
            style={{
                background: 'rgba(0, 0, 0, 0.6)'
            }}
            tabIndex={-1}
            className="modal d-block in modal-theme fade show"
            aria-modal="true"
        >
            <div role="document" className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div>
                        <div className="modal-header">
                            <button onClick={handelClose} className="close" type="button" style={{ background: "#003964", border: 0 }}>
                                ×
                            </button>
                            <h4 className="modal-title">
                                Meeting URL to Share
                            </h4>
                        </div>
                        <div className="modal-body">
                            <div className="content">
                                <div
                                    className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                                >
                                    <div className="form-group">
                                        <div className="label" style={{ fontSize: "14px" }}>
                                            Meeting URL to Share With Users:
                                        </div>
                                        <input

                                            className="form-control disable-input guest-user-input"
                                            id="userurl"
                                            type="text"
                                            value={`https://smallworldmeet.com/guest-meeting/${meettingurl}`}
                                           
                                        />
                                        <button

                                            className="guest-user-btn"
                                            value="click to copy"
                                            onClick={()=>copyUrl()}
                                        >
                                            Copy
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                className="btn btn-primary"
                                type="button"
                                onClick={handelClose}
                            >
                                ok
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default MeetingUrlModal