import { AUTH } from 'constants/store-actions';
import { Dispatch } from 'redux';
import * as api from 'api';
import { toastErr, toastSuccess } from 'services';

export const signin = (formData: any, navigate: any, setLoading: any) => async (dispatch: Dispatch) => {
    setLoading(true);
    try {
        const { data: result } = await api.signIn(formData);
        if (result.responseCode == '400') {
            toastErr("Your email and password does not match. Please try again.");
            setLoading(false);
            return;
        }
        if (!result.authentication.response.error) {

            if (formData.rememberme) {
            }
            setLoading(false);
            localStorage.setItem('token', result.token)

            dispatch({ type: AUTH, payload: result.authentication.response });
            navigate('/home');
            /*signalR Start*/
            //this.signalR.startConnection();
            /*signalR End*/
        }
    } catch (error) {
        console.log(error);
        setLoading(false);
    }
};

export const signup = (formData: any, navigate: any, setLoading: any) => async (dispatch: Dispatch) => {
    setLoading(true);
    try {
        const { data: result } = await api.signUp(formData);

        if (result.responseCode == '400') {
            toastErr("User already exist");
            setLoading(false);
        }

        if (!result.authentication.response.error) {
            toastSuccess(result.authentication.response.errorMessage);
            setLoading(false);
            navigate("/");
        }
        dispatch({ type: AUTH, payload: result.authentication.response });
        navigate('/');
    } catch (error) {
        console.log(error);
    }
};

export const setPassword = (formData: any, navigate: any, setLoading: any,) => async (dispatch: Dispatch) => {
    setLoading(true);
    try {
        const { data: result } = await api.setPassword(formData);
        if (result && result.responseCode == "200") {
            setLoading(false);
            toastSuccess(result?.response.errorMessage);
            navigate("/");
        }

        if (result && result.responseCode == "400") {
            setLoading(false);
            toastErr("error");
        }
        dispatch({ type: AUTH, payload: result?.authentication.response });
        navigate('/');
    } catch (error) {
        console.log(error);
    }
};

export const forgotPassword = (formData: any, navigate: any, setLoading: any, setShow: any) => async (dispatch: Dispatch) => {
    setLoading(true);
    try {

        const { data: result } = await api.setForgotPassword(formData);

        if (result.responseCode == "200") {
            toastSuccess("Email sent successfully!");
            setShow(true);
            setLoading(false);
        }
        if (result.responseCode == "400") {
            toastErr(result.message);
            setLoading(false);

        }
        dispatch({ type: AUTH, payload: result.authentication.response });
        navigate('/');
    } catch (error) {
        console.log(error);
    }

};

export const changePassword = async (formData: any, setLoading: any) => {
    try {
        const { data: result } = await api.changePassword(formData);
        if (result?.authentication?.responseCode == 200) {
            setLoading(false)
            toastSuccess("Password updated successfully");
        } else if (result.responseCode == 400) {
            setLoading(false)
            toastErr(result?.message)
        } else {
            setLoading(false)
            toastErr("server error")
        }
    } catch (error) {
        console.log(error);
    }
}


export const checkResetPasswordToken = (formData: any, navigate: any, setLoading: any) => async () => {
    try {
        const { data: result } = await api.checkResetPasswordToken(formData);
        if (result.responseCode == "400") {
            navigate('/')
        }
    } catch (error) {
        console.log(error);
    }
};


export const resendEmail = (formData: any, navigate: any, setLoading: any,) => async (dispatch: Dispatch) => {
    setLoading(true);
    try {
        const { data: result } = await api.resendLink(formData);
        if (result.responseCode == "200") {
            toastSuccess("Email sent successfully!");
            setLoading(false);
        }
        if (result.responseCode == "400") {
            toastErr(result.message);
            setLoading(false);
        }
        dispatch({ type: AUTH, payload: result.authentication.response });
        navigate('/');
    } catch (error) {
        console.log(error);
    }

};

