import Dashboard from "components/Dashboard/Dashboard";
import { ParticipantProvider } from 'components/VideoCallFunctionality/ParticipantProvider'
import { VideoProvider } from 'components/VideoCallFunctionality/VideoProvider'
import React, { useEffect } from 'react'
import useConnectionOptions from 'utils/useConnectionOptions'
import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { SignalRContext, SignalR } from "services/signal-r-service";
import theme from 'theme';



const Room = () => {
  const connectionOptions = useConnectionOptions();

  return (
    <SignalRContext.Provider value={SignalR}>
      <VideoProvider options={connectionOptions} onError={() => { }}>
        <ParticipantProvider>
          <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <Dashboard />
          </MuiThemeProvider>
        </ParticipantProvider>
      </VideoProvider>
    </SignalRContext.Provider>
  )
}

export default Room
