import { useEffect, useState } from "react";
import Logo from "assets/images/logo.png";
import { useDispatch } from "react-redux";
import { signin, signup } from "actions";
import { Link, useNavigate } from "react-router-dom";
import { Dispatch } from "redux";
import SignupConformationModal from "features/Modal/SignupConformationModal";
import Joi from "joi";
import {
  insertOnlineAndBusyUsers
} from "actions";

interface LoginFormInterface {
  Email: string;
  Password: string
}

interface SignupformInterface {
  FirstName: string;
  LastName: string;
  EmailAddress: string;
}

function LoginSignUpPage() {
  const local_rememberme = JSON.parse(
    localStorage.getItem("rememberme") || JSON.stringify("")
  );
 
  const [loginForm, setLoginForm] = useState<Partial<LoginFormInterface>>({});
  const [signupForm, setSignupForm] = useState<Partial<SignupformInterface>>({});
  const [commonVariables, setCommonVariables] = useState({
    loading: false,
    isLoginForm: true,
    showIcon: true,
    showPassword: false,
    invalid: false,
    submit: false,
    rememberme: local_rememberme,
  });
  const [loading, setLoading] = useState(false);
  const dispatch: Dispatch<any> = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [errors, setErrors] = useState({ field: "", message: "" });

  const SignupSchema = Joi.object({
    FirstName: Joi.string().min(3).required(),
    LastName: Joi.string().min(1).required(),
    EmailAddress: Joi.string()
      .email({ minDomainSegments: 2, tlds: { allow: ["com", "net"] } })
      .required(),
  });

  const LoginSchema = Joi.object({
    Email: Joi.string()
      .email({ minDomainSegments: 2, tlds: { allow: ["com", "net"] } })
      .required(),
    Password: Joi.string().required(),
  });

  const handleLoginSignup = () => {
    setCommonVariables({
      ...commonVariables,
      isLoginForm: !commonVariables.isLoginForm,
    });
  };
  useEffect(()=>{
    const rememberme = JSON.parse(
      localStorage.getItem("rememberme") || JSON.stringify("")
    );
    const password = JSON.parse(
      localStorage.getItem("password") || JSON.stringify("")
    );
    if(rememberme){
      setLoginForm({...loginForm , Email:password.Email ,Password:password.Password})
    }
   
  },[])
  if (commonVariables.rememberme == true) {
    localStorage.setItem("rememberme", 'true')
  }
  else {
    localStorage.removeItem("rememberme")
  }

  const hanldeLoginFomrfields = (e: any) => {
    setLoginForm({ ...loginForm, [e.target.name]: e.target.value });
  };

  const handleSignupFormFileds = (e: any) => {
    setSignupForm({ ...signupForm, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    localStorage.setItem("password", JSON.stringify(loginForm));
    const { value, error }: any = LoginSchema.validate(loginForm);
    if (error == undefined) {
      setLoading(true)
      dispatch(signin(loginForm, navigate, setLoading));
    } else {
      const field: any = error.toString().split(" ")[1].replace(/['"]+/g, '');
      const message: any = error.toString().split(":")[1].replace(/"|'/g, '');
      setErrors({ field: field, message: message });
    }
  };

  const handleSignupSubmit = async (e: any) => {

    e.preventDefault();
    // localStorage.clear()
    const { value, error }: any = SignupSchema.validate(signupForm);
    if (error == undefined) {
      setLoading(true)
      dispatch(signup(signupForm, navigate, setLoading));
      if (signupForm) {
        setShow(true);
      }
    } else {
      const field: any = error.toString().split(" ")[1].replace(/['"]+/g, '');
      const message: any = error.toString().split(":")[1].replace(/"|'/g, '');
      setErrors({ field: field, message: message });
    }
  };

  const handleCloseModal = () => {
    setCommonVariables({
      ...commonVariables,
      isLoginForm: !commonVariables.isLoginForm,
    });
    setShow(false);
  };

  const Style: any = {
    position: "absolute",
    fontSize: "11px",
    bottom: "-18px",
    width: "100%",
    right: 0,
    textAlign: "left",
    lineHeight: "22px",
    color: "#ff4343",
  };

  useEffect(() => { }, [loginForm]);
  useEffect(() => {
    const rememberme = JSON.parse(
      localStorage.getItem("rememberme") || JSON.stringify("")
    );
    if(rememberme){
      const keyToKeep = 'password';
      const keyToKeep2 = 'rememberme';
      for (let key in localStorage) {
        if (localStorage.hasOwnProperty(key) && key !== keyToKeep && key !== keyToKeep2 ) {
          localStorage.removeItem(key);
        }
      }
    }
    else{
      localStorage.clear()
    }
  }, [])


  return (
    <div>
      <div className="login-layout">
        <video
          style={{ width: "100%", position: "fixed", zIndex: 0 }}
          autoPlay
          loop
          id="videoPlayer"
          muted
        >
          <source
            src="https://smallworldmeet.com/assets/video/login-signup-video.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
        <section className="user">
          <div
            className={`user_options-container ${commonVariables.isLoginForm ? "sign-in-form" : "sign-up-from"
              }`}
          >
            <div
              className="user_options-text"
              style={{ backgroundImage: "url(assets/images/home-banner.jpeg)" }}
            >
              <div className="user_options-unregistered">
                <h2 className="user_unregistered-title">
                  Don't have an account?
                </h2>
                <p className="user_unregistered-text">Create Here</p>
                <button
                  type="button"
                  onClick={handleLoginSignup}
                  className="user_unregistered-signup"
                  id="signup-button"
                >
                  Sign up
                </button>
              </div>
              <div className="user_options-registered">
                <div className="user_options-registered-data">
                  <h2 className="user_registered-title">Have an account?</h2>
                  <p className="user_registered-text">Login Here</p>
                  <button
                    className="user_registered-login"
                    id="login-button"
                    onClick={handleLoginSignup}
                  >
                    Login
                  </button>
                </div>
              </div>
            </div>
            <div
              className={`user_options-forms ${commonVariables.isLoginForm ? "rightbounce" : "leftbounce"
                }`}
              id="user_options-forms"
            >
              <div className="user_forms-login">
                <div className="login-logo">
                  <img src={Logo} alt="logo" />
                </div>
                <form className="contact-form-section" onSubmit={handleSubmit}>
                  <div className="form-field-wrapper">
                    <div className="field-title">Email*</div>
                    <input
                      className={`form-full ${commonVariables.invalid ? "invalid" : ""
                        }`}
                      value={loginForm.Email}
                      type="text"
                      name="Email"
                      placeholder="Enter Email Address"
                      onChange={hanldeLoginFomrfields}
                    />
                    {errors.field == 'Email' ? (
                      <div className="help-block" style={Style}>
                        <div>{errors.message}</div>
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={`form-field-wrapper ${commonVariables.showIcon ? "showicon" : ""
                      }`}
                  >
                    <div className="field-title">Password* </div>
                    <div className="field-password">
                      <input
                        className={`form-full ${commonVariables.invalid ? "invalid" : ""
                          }`}
                        type={
                          !commonVariables.showPassword ? "password" : "text"
                        }
                        name="Password"
                        value={loginForm.Password}
                        placeholder="Enter Password"
                        onChange={hanldeLoginFomrfields}
                      />

                      {commonVariables.showPassword ? (
                        <a
                          onClick={() =>
                            setCommonVariables({
                              ...commonVariables,
                              showPassword: false,
                            })
                          }
                          href="#"
                        >
                          <i className="fa fa-eye" aria-hidden="true"></i>
                        </a>
                      ) : (
                        <a
                          onClick={() =>
                            setCommonVariables({
                              ...commonVariables,
                              showPassword: true,
                            })
                          }
                          href="#"
                        >
                          <i className="fa fa-eye-slash" aria-hidden="true"></i>
                        </a>
                      )}
                    </div>

                    {errors.field == 'Password' ? (
                      <div className="help-block" style={Style}>
                        <div>{errors.message}</div>
                      </div>
                    ) : null
                    }
                  </div>
                  <div className="form-field-wrapper">
                    <div className="checkbox-theme pull-left">
                      <input
                        id="signinkeep"
                        name="remember"
                        className="styled"
                        type="checkbox"
                        checked={commonVariables.rememberme}
                        onChange={(e) =>
                          setCommonVariables({
                            ...commonVariables,
                            rememberme: Boolean(e.target.checked),
                          })
                        }
                      />
                      <label htmlFor="signinkeep" className="arrow-label">
                        Remember me
                      </label>
                    </div>
                  </div>
                  {!commonVariables.loading && (
                    <div className="form-field-wrapper">
                      <button type="submit" className="field-button">
                        Login{" "}
                        {loading ? (
                          <div style={{ left: "255px", marginTop: "-5px", fontSize: "8px" }} className="spinner-border spinner-border-sm text-light" role="status"></div>
                        ) : null}
                      </button>

                    </div>
                  )}
                  <div className="form-field-wrapper">
                    <Link to="/forgot-password" className="bottom-link">
                      Forgot Password?
                    </Link>
                  </div>
                </form>
                <div className="login-footer">
                  &copy; 2022-2023 THE AUTHENTIC CONSORTIUM. All rights reserved{" "}
                  <a href="/privacy-policy">Privacy Policy</a>
                </div>
              </div>
              <div className="user_forms-signup">
                <div className="login-logo">
                  <img src={Logo} alt="logo" />
                </div>
                <form
                  className="contact-form-section"
                  onSubmit={handleSignupSubmit}
                >
                  <div className="form-field-wrapper">
                    <div className="field-title">First Name*</div>
                    <input
                      className="form-full"
                      type="text"
                      value={signupForm.FirstName}
                      name="FirstName"
                      onChange={handleSignupFormFileds}
                      placeholder="Enter First Name"
                    />
                    {errors.field == 'FirstName' ? (
                      <div className="help-block" style={Style}>
                        <div>{errors.message}</div>
                      </div>
                    ) : null}
                  </div>
                  <div className="form-field-wrapper">
                    <div className="field-title"> Last Name*</div>
                    <input
                      className="form-full"
                      type="text"
                      value={signupForm.LastName}
                      name="LastName"
                      placeholder="Enter Last Name"
                      onChange={handleSignupFormFileds}

                    />
                    {errors.field == 'LastName' ? (
                      <div className="help-block" style={Style}>
                        <div>{errors.message}</div>
                      </div>
                    ) : null}
                  </div>

                  <div className="form-field-wrapper">
                    <div className="field-title">Email Address*</div>
                    <input
                      className={`form-full ${commonVariables.invalid ? "invalid" : ""
                        }`}
                      value={signupForm.EmailAddress}
                      type="text"
                      name="EmailAddress"
                      placeholder="Enter Email Address"
                      onChange={handleSignupFormFileds}
                    />
                    {errors.field == 'EmailAddress' ? (
                      <div className="help-block" style={Style}>
                        <div>{errors.message}</div>
                      </div>
                    ) : null}
                  </div>
                  {!commonVariables.loading && (
                    <div className="form-field-wrapper">
                      <button type="submit" className="field-button">
                        Submit
                        {loading ? (
                          <div style={{ left: "260px", marginTop: "-5px", fontSize: "8px" }} className="spinner-border spinner-border-sm text-light" role="status"></div>
                        ) : null}
                      </button>
                    </div>
                  )}
                </form>
                <div className="login-footer">
                  &copy; 2022-2023 THE AUTHENTIC CONSORTIUM. All rights reserved{" "}
                  <a href="/privacy-policy">Privacy Policy</a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {show ? <SignupConformationModal handleCloseModal={handleCloseModal} /> : null}
    </div>
  );
}

export default LoginSignUpPage;