import Layout from "layouts";
import { createBrowserRouter, Navigate } from "react-router-dom";
import ChangePassword from "./ChangePassword";
import ForgotPassword from "./ForgotPassword";
import GuestMeeting from "./GuestMeeting";
import { Help } from "./Help";
import Home from "./Home";
import LoginSignUpPage from "./LoginSignUpPage";
import MyAccountPage from "./MyAccount";
import ResetPassword from "./ResetPassword";
import SetPassword from "./SetPassword";
import PrivacyPolicy from "./Privacypolicy";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LoginSignUpPage />,
  },
  {
    path: "set-password",
    element: <SetPassword />,
  }, {
    path: "forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "reset-password",
    element: <ResetPassword />,
  },
  {
    path: "home",
    element: <RequireAuth component={<Home />} />,
  },
  {
    path: "help",
    element: <RequireAuth component={<Help />} />,
  },
  {
    path: "change-password",
    element: <RequireAuth component={<ChangePassword />} />,
  },
  {
    path: "my-account",
    element: <RequireAuth component={<MyAccountPage />} />,
  },
  {
    path: 'guest-meeting/:roomId',
    element: <Layout><GuestMeeting /></Layout>
  },
  {
    path: "privacy-policy",
    element: <RequireAuth component={<PrivacyPolicy />} />
  },
]);

function RequireAuth({ component }: any) {
  let isAuthenticated = localStorage.getItem("token");
  return isAuthenticated ? <Layout>
    {component}
  </Layout> : <Navigate to="/" />;
  // </Layout> : <Navigate to="home" />;
}

export default router;
