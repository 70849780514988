import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import ChatIcon from '@mui/icons-material/Chat';
import { makeStyles } from '@material-ui/core';

export const ANIMATION_DURATION = 700;

const useStyles = makeStyles({
  iconContainer: {
    position: 'relative',
    display: 'flex',
    color: 'white'
  },
  circle: {
    width: '10px',
    height: '10px',
    backgroundColor: '#5BB75B',
    borderRadius: '50%',
    position: 'absolute',
    top: '-3px',
    left: '13px',
    opacity: 0,
    transition: `opacity ${ANIMATION_DURATION * 0.5}ms ease-in`,
  },
  hasUnreadMessages: {
    opacity: 1,
  },
  ring: {
    border: '3px solid #5BB75B',
    borderRadius: '30px',
    height: '14px',
    width: '14px',
    position: 'absolute',
    left: '11px',
    top: '-5px',
    opacity: 0,
  },
  animateRing: {
    animation: `$expand ${ANIMATION_DURATION}ms ease-out`,
    animationIterationCount: 1,
  },
  '@keyframes expand': {
    '0%': {
      transform: 'scale(0.1, 0.1)',
      opacity: 0,
    },
    '50%': {
      opacity: 1,
    },
    '100%': {
      transform: 'scale(1.4, 1.4)',
      opacity: 0,
    },
  },
});

export default function ToggleChatButton({ toggleChatWindow, toggleVideoChat }: any) {
  const classes = useStyles();

  return (
    <div style={{ marginRight: '30px', textAlign: 'center' }}>
      <button
        data-cy-chat-button
        onClick={toggleChatWindow}
        style={{
          background: toggleVideoChat ? 'red' : 'green',
          border: 'none',
          borderRadius: '5px',
          padding: '0 10px',
          marginLeft: '10px',
          color: 'white'
        }}
      >
        <ChatIcon sx={{ fontSize: 20 }} />
      </button >
      <span style={{ display: 'block' }}>Chat</span>
    </div >
  );
}