export enum SingalREventsEnums {
    VideoChat = 1,
    UserInChat = 2,
    UserInCall = 3,
    EndCallUserBeforeStart = 4,
    UserDeclineCall = 5,
    NewCallGroup = 6,
    UserUnavailableForCall = 7,
    CallAccept = 8,
    OnlineUser = 102,
    CallEnd = 9,
    UpdateUserStatus = 10,
    NewUserRegister=11,
    GuestIdAfterJoin = 12,
    GetOnGoingCalls =13,
    UserInBreakoutCall = 14,
    RequestPrivatecall = 15,
    RequestPrivatecallResponse = 16,
    PrivatePublicCallToastr = 17,
    GuestCallAccepted = 18,
    RequestForGuestCall = 19,
    GuestCallRejected = 20,
    GuestInChat=21,
    EndEntireCall=22,
    RaiseHand = 25,
    DownRaiseHand =26,
    IsBusy=23,
    joinCall=24,
    PublicMeetingUsers=100,
    Reactions = 27,
  }
  
  export enum SignalREventsEnums {
    VideoChat = 1,
    UserInChat = 2,
    UserInCall = 3,
    EndCallUserBeforeStart = 4,
    UserDeclineCall = 5,
    NewCallGroup = 6,
    UserUnavailableForCall = 7,
    CallAccept = 8,
    OnlineUser = 102,
    CallEnd = 9,
    UpdateUserStatus = 10,
    NewUserRegister = 11,
    GuestIdAfterJoin = 12,
    GetOnGoingCalls = 13,
    UserInBreakoutCall = 14,
    RequestPrivatecall = 15,
    RequestPrivatecallResponse = 16,
    PrivatePublicCallToastr = 17,
    GuestCallAccept = 18,
    RequestForGuestCall = 19,
    GuestCallRejected = 20,
    GuestInChat=21,
    GUEST_USER_LIST= 100,
    EndEntireCall=22,
    RaiseHand = 25,
    DownRaiseHand = 26,
    IsBusy=23,
    joinCall=24,
    PublicMeetingUsers=100,
    Reactions = 27,

}